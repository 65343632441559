import React from 'react';
import AppointmentWizard from '@features/appointment/createAppointmentWizard/appointmentWizard';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function CreateAppointment() {
    useSetFAQS(['contactYou', 'contactCompany']);

    return (
        <AppointmentWizard />
    );
}
