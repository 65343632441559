import React from 'react';
import {
    Dialog, DialogTitle, DialogContent, Box,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useResponsive } from '@data/hooks/responsive';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import DocumentPreview from '@features/document/DocumentPreview';

const useStyles = makeStyles<Theme>(theme => createStyles({
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.customBackgrounds.light,
    },
    dialogTitleContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dialogContent: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px 5px',
        },
    },
}));

type MediaDisplayProps = {
  description: string;
  url: string;
  open: boolean;
  onClose: (boolean) => void;
};

// TODO logic inside DialogContent should be separated from this component
export default function MediaDisplay(props: MediaDisplayProps) {
    const {
        description, url, open, onClose,
    } = props;
    const classes = useStyles();
    const responsive = useResponsive();
    const dialogContentWidth = responsive({
        xs: '76vw',
        sm: '70vw',
        md: '55vw',
        lg: '50vw',
    });
    const dialogContentHeight = responsive({
        xs: '60vh',
        sm: '75vh',
        md: '75vh',
        lg: '83vh',
    });
    const shouldRenderDocumentPreview = textUtils.getExtensionFromFileName(url) === 'pdf' || url?.includes('formDocument/view/') || url?.includes('document/view/');     
    const closeIFrame = () => onClose(false);

    return (
        <Dialog open={open} onClose={closeIFrame} maxWidth={false}>
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
                <Box mr={20} className={classes.dialogTitleContent}>
                    {description}
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeIFrame}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {shouldRenderDocumentPreview && (
                    <DocumentPreview
                        scrollable="yes"
                        title={`${description}`}
                        srcUrl={`${url}`}
                        style={{ height: dialogContentHeight, width: dialogContentWidth }}
                    />
                )}
                {!shouldRenderDocumentPreview && (
                    <img
                        alt={description}
                        src={url}
                        style={{ height: 'auto', width: '100%' }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
