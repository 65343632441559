import React, { useState } from 'react';
import { toast } from '@data/state/toast';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import { authState, dataWrapper } from '@data/state/auth';
import Contact from '@powerednow/shared/modules/complexData/contact';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import { useNavigate } from 'react-router-dom';
import PageBlock from '@components/layout/PageBlock';
import { useResponsive } from '@data/hooks/responsive';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import apiRequest from '@app/connection/apiRequest';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '3rem',
    },
    changePasswordContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '25px',
    },
}));

const updatePassword = async (dataWrapperInstance, contactId, password, companyId) => {
    const contact: Contact = await dataWrapperInstance.getComplexDataObject(Contact, contactId);
    const userToContact = await contact.getUserToContact();
    await apiRequest.setPasswordByCompanyData(
        textUtils.hash(password),
        userToContact.data.user_uuid,
        companyId,
    );
};

export default function ChangePassword() {
    const setToast = useSetRecoilState(toast);
    const navigate = useNavigate();
    const responsive = useResponsive();
    const classes = useStyles();
    const dataWrapperInstance = useRecoilValue(dataWrapper);
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });
    const authData = useRecoilValue(authState);
    const { data: { contactId, companyId } } = authData;

    const handleNewPassword = async () => {
        await updatePassword(
            dataWrapperInstance,
            contactId,
            password.password,
            companyId,
        );
        navigate(`/portal/${authData.portalId}/`);
        setToast({ message: 'Password changed successfully.', severity: 'success' });
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <PageBlock
            className={classes.root}
            title="Change password"
            titleAlign="center"
            neutral
        >
            <Box
                className={classes.changePasswordContainer}
                paddingLeft={responsive({ xs: 0, sm: '30%' })}
                paddingRight={responsive({ xs: 0, sm: '30%' })}
            >
                <SetNewPassword
                    onSetNewPasswordButtonClicked={handleNewPassword}
                    onPasswordFieldChange={handleChange}
                    password={password.password}
                    confirmPassword={password.confirmPassword}
                />
            </Box>
        </PageBlock>
    );
}
