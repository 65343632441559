import React from 'react';
import Avatar, { AvatarSizes } from '@components/Avatar/Avatar';
import { AvatarProps as MUIAvatarProps } from '@material-ui/core';

type UserAvatarProps = {
    profilePictureUrl?: string,
    size?: AvatarSizes,
    alt?: MUIAvatarProps['alt'],
    className?: MUIAvatarProps['className'],
}

export default function UserAvatar(props: UserAvatarProps) {
    const {
        profilePictureUrl,
        size,
        alt,
        className,
    } = props;
    return (
        <Avatar
            src={profilePictureUrl}
            size={size || 'medium'}
            alt={alt}
            className={className}
        />
    );
}
