import React from 'react';

import arrayUtils from '@powerednow/shared/modules/utilities/array';
import useComplexData from '@data/hooks/complexDataHook';
import ActionEntity from '@powerednow/shared/modules/complexData/action/entity';
import Action from '@powerednow/shared/modules/complexData/action';
import DocumentEntity from '@powerednow/shared/modules/complexData/document/entity';
import Document from '@powerednow/shared/modules/complexData/document/index';
import MessageEntity from '@powerednow/shared/modules/complexData/message/entity';
import FormDocument from '@powerednow/shared/modules/complexData/formDocument';
import FormDocumentEntity from '@powerednow/shared/modules/complexData/formDocument/entity';
import CustomerMessageEntity from '@powerednow/shared/modules/complexData/customerMessage/entity';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import { Filter, Sorter } from '@powerednow/shared/modules/complexData/connectedData';

type MessageDataType = ModelFields<MessageEntity>;
type CustomerMessageDataType = ModelFields<CustomerMessageEntity>;
type MessageLoader = (messageId: number) => { messages?: MessageDataType[] | CustomerMessageDataType[]};

export const useDocumentMessage: MessageLoader = documentId => {
    const { optionalResult } = useComplexData<DocumentEntity, { messages: CustomerMessageDataType[], loaded: boolean }, Document>(
        Document,
        documentId,
        async (complexDocument, resultSetter) => {
            const complexCustomerMessages = await complexDocument.getAllCustomerMessage();
            const messages = complexCustomerMessages.map(message => message.data.getPureDataValues());
            resultSetter(complexCustomerMessages, { messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useAppointmentMessage: MessageLoader = actionId => {
    const { optionalResult } = useComplexData<ActionEntity, { messages: MessageDataType[], loaded: boolean }, Action>(
        Action,
        actionId,
        async (complexAppointment, resultSetter) => {
            const complexMessages = await complexAppointment.getAllMessage();
            const messages = complexMessages.map(message => message.data.getPureDataValues());
            resultSetter(complexMessages, { messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useFormMessage: MessageLoader = formId => {
    const { optionalResult } = useComplexData<FormDocumentEntity, { messages: CustomerMessageDataType[], loaded: boolean }, FormDocument>(
        FormDocument,
        formId,
        async (complexDocument, resultSetter) => {
            const complexCustomerMessages = await complexDocument.getAllCustomerMessage();
            const messages = complexCustomerMessages.map(message => message.data.getPureDataValues());
            resultSetter(complexCustomerMessages, { messages, loaded: true });
        },
    );
    return {
        messages: optionalResult?.messages,
        loaded: optionalResult?.loaded,
    };
};

export const useMessageLoader = (complexInstanceId, messageLoader) => {
    const { messages, loaded } = messageLoader(complexInstanceId);
    if (loaded) {
        let messageId;
        if (messages?.length) {
            const [firstMessage] = arrayUtils.objectArraySorter(messages, 'dt_created', 'asc');
            messageId = firstMessage.id;
        }
        return {
            loaded,
            messageId,
        };
    }
    return {
        loaded,
    };
};
