import React, {
    useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { omit } from 'lodash';
import {
    isOnSameDay, getFirstDayOfTheWeek, getLastDayOfTheWeek, addMonth, getFirstDayOfTheMonth, getLastDayOfTheMonth,
} from '@powerednow/shared/modules/utilities/date';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CalendarDay from './CalendarDay';
import CalendarHeader from './CalendarHeader';

interface CalendarStyles {
    cellWidth: number,
    border: string,
}

const useStyles = makeStyles<Theme, CalendarStyles>(() => createStyles({
    root: {
        border: ({ border }) => border || 'solid 1px silver',
        borderRadius: 4,
    },
    calendarDaysContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    calendarDayContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: ({ cellWidth }) => cellWidth,
        height: ({ cellWidth }) => cellWidth,
    },
}));
function getDays(inputDate: Date, firstDayOfTheWeek = 'monday'): (Date | null)[] {
    const days: (Date | null)[] = [];
    const firstDayOfTheMonth = getFirstDayOfTheMonth(inputDate);
    const lastDayOfTheMonth = getLastDayOfTheMonth(inputDate);
    const firstDate = getFirstDayOfTheWeek(firstDayOfTheMonth, firstDayOfTheWeek);
    const lastDate = getLastDayOfTheWeek(lastDayOfTheMonth, firstDayOfTheWeek);

    while (firstDate <= lastDate) {
        days.push(new Date(firstDate));
        firstDate.setDate(firstDate.getDate() + 1);
    }

    return days;
}

export declare type ContainsDate = {
    date: Date;
    [x: string]:any;
}

interface CalendarProps<T extends ContainsDate> extends BoxProps {
    date?: Date,
    label?: string | React.ReactElement
    onNextMonth?: () => void
    onSelectDay?: (_day: Date) => void
    onPreviousMonth?: () => void
    sixWeekView?: boolean
    selectedDays?: T[],
    enabledDays?: T[],
    firstDayOfTheWeek?: 'monday' | 'sunday',
    firstDay?: Date,
    lastDay?: Date,
}

const isDayIncludedInDays = <T extends ContainsDate>(day:Date | null, dayArray: T[]):boolean => {
    if (Array.isArray(dayArray)) {
        return dayArray.some(selectedDate => isOnSameDay(selectedDate.date, day));
    }

    return false;
};

export default function Calendar<T extends ContainsDate>(props: CalendarProps<T>) {
    const {
        onSelectDay,
        onNextMonth,
        onPreviousMonth,
        selectedDays,
        enabledDays,
        date,
        firstDay,
        lastDay,
        firstDayOfTheWeek,
        width,
        border,
        style,
        color,
    } = props;
    const [days, setDays] = useState<(Date | null)[]>([]);
    const [initialDate, setInitialDate] = useState<Date>(firstDay ? new Date(firstDay) : new Date());
    const [cellWidth, setCellWidth] = useState(0);
    const classes = useStyles({ cellWidth, border });
    const containerRef = useRef<HTMLInputElement>(null);

    const updateDays = (forDate: Date) => {
        setInitialDate(forDate);
        setDays(getDays(forDate, firstDayOfTheWeek || 'monday'));
    };

    useEffect(() => {
        const displayDate = date || new Date();
        updateDays(new Date(displayDate.getFullYear(), displayDate.getMonth(), 1));
    }, []);

    useEffect(() => {
        const firstDayDate = firstDay || new Date();
        updateDays(new Date(firstDayDate.getFullYear(), firstDayDate.getMonth(), 1));
    }, [firstDay]);

    useLayoutEffect(() => {
        const current = containerRef?.current;
        if (current) {
            if (width) {
                setCellWidth(current ? width / 7 : 0);
            } else {
                setCellWidth(current ? current.getBoundingClientRect().width / 7 : 0);
            }
        }
    }, [width]);

    const calendarDays = (
        days.map(day => {
            const isSelected = selectedDays && isDayIncludedInDays(day, selectedDays);
            const isDisabled = enabledDays && !isDayIncludedInDays(day, enabledDays);
            return (
                <Box
                    key={`day_${day}`}
                    className={classes.calendarDayContainer}
                >
                    <CalendarDay
                        day={day}
                        calendarDate={initialDate}
                        selected={isSelected}
                        disabled={isDisabled}
                        onClick={onSelectDay}
                    />
                </Box>
            );
        })
    );

    const shouldRenderExtraLine = days.length > 0 && days.length < 42;
    const isFirstDayOfTheWeekSet = firstDayOfTheWeek || 'monday';
    const emptyCalendarDay = (<Box className={classes.calendarDayContainer} />);
    const handleNextMonthClick = onNextMonth || (() => updateDays(addMonth(initialDate, 1)));
    const handlePreviousMonthClick = onPreviousMonth || (() => updateDays(addMonth(initialDate, -1)));

    return (
        <Box
            className={classes.root}
            {...omit(
                props,
                'date',
                'label',
                'onNextMonth',
                'onSelectDay',
                'border',
                'onPreviousMonth',
                'sixWeekView',
                'selectedDays',
                'headerComponent',
                'enabledDays',
                'firstDay',
                'lastDay',
            )
            }
        >
            <div
                ref={containerRef}
                style={{ ...style, position: 'relative', width }}
            >
                <CalendarHeader
                    date={initialDate}
                    onNextMonth={handleNextMonthClick}
                    onPreviousMonth={handlePreviousMonthClick}
                    color={color}
                    firstDayOfTheWeek={isFirstDayOfTheWeekSet}
                    firstDay={firstDay}
                    lastDay={lastDay}
                />
                <Box className={classes.calendarDaysContainer}>
                    {calendarDays}
                    {shouldRenderExtraLine && emptyCalendarDay}
                </Box>
            </div>
        </Box>
    );
}
