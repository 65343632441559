import React from 'react';
import FormDetails from '@features/form/formDetails';
import { useParams } from 'react-router-dom';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ViewForm() {
    const { id } = useParams<'id'>();
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <FormDetails documentId={parseInt(id!, 10)} />
    );
}
