import React, { useState } from 'react';
import Button from '@components/button/Button';
import TextField from '@components/fields/TextField';
import { Box } from '@material-ui/core';
import { toast } from '@data/state/toast';
import { useSetRecoilState } from 'recoil';
import apiRequest from '@app/connection/apiRequest';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { NetworkError, ServiceError } from '@powerednow/shared/error';
import UnAuthorizedPage from '@pages/UnauthorizedPage/UnAuthorizedPage';
import { API_RESPONSES } from '@powerednow/shared/constants';
import { useNavigate } from 'react-router-dom';

export default function ResetPassword() {
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const setToast = useSetRecoilState(toast);
    const redirectUrl = encodeURIComponent(window.location.origin);
    const navigate = useNavigate();

    const handleResetPassword = async () => {
        setDisabled(true);
        await apiRequest.resetPassword(emailAddress, redirectUrl).then(async (_result: any) => {
            setToast({ message: API_RESPONSES.PASSWORD_RESET.message.default });
            navigate('/login');
        }).catch(ifInstanceOf(ServiceError, () => {
            setDisabled(false);
            setToast({
                message: 'Sorry we can\'t find that account. Check your email address were entered correctly and try again.',
                severity: 'error',
            });
        })).catch(ifInstanceOf(NetworkError, () => {
            setDisabled(false);
            setToast({
                message: 'Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.',
                severity: 'error',
            });
        }));
    };

    return (
        <UnAuthorizedPage title="Reset Password">
            <TextField
                placeholder="Email address:"
                size="medium"
                value={emailAddress}
                fullWidth
                onChange={event => setEmailAddress(event.target.value)}
            />
            <Box display="flex" justifyContent="center" flexDirection="row">
                <Box display="flex" mt={8}>
                    <Button
                        emphasis="high"
                        buttonType="primary"
                        minWidth={80}
                        onClick={handleResetPassword}
                        disabled={disabled}
                    >
                        Reset password
                    </Button>
                </Box>
            </Box>
        </UnAuthorizedPage>
    );
}
