import DataWrapper from '@app/connection/dataWrapper';
import {
    EntityWithId,
    ModelFields,
} from '@powerednow/shared/modules/complexData/entity';
import {
    useState,
} from 'react';
import ComplexData from '@powerednow/shared/modules/complexData/complexData';
import { Newable, PropType } from '@powerednow/type-definitions';
import BaseData from '@powerednow/shared/modules/complexData/baseData';
import useAsyncEffect from 'use-async-effect';
import { dataWrapper } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import { useErrorHandler } from 'react-error-boundary';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const cleanupListeners = removers => {
    removers?.list.forEach(cleaner => cleaner());
    removers?.list.splice(0, removers.length);
};

const setMonitoredComplexDataInstance = (listenerRemovers, complexData, monitoredInstances, eventMap: Record<string, (any?) => void>) => {
    if (complexData && !monitoredInstances.has(complexData)) {
        Object.entries(eventMap).forEach(([event, changeAction]) => {
            complexData.on(event, changeAction);
            listenerRemovers.list.push(() => {
                complexData.removeListener(event, changeAction);
            });
        });
        monitoredInstances.add(complexData);
    }
};

export default function useComplexData <E extends EntityWithId, O = Record<string, any>, T extends ComplexData<E> = ComplexData<E>>(
    complexConstructor: Newable<T>,
    deps: number | Array<any> | null,
    callback: (
        _complexInstance: T,
        _resultSetter: (_resultEntity: ComplexData<any> | ComplexData<any>[], _newResult: Partial<O>) => void,
        _watcher: <WC extends ComplexData<any> | ComplexData<any>[]>(_itemsToWatch: Promise<WC>) => Promise<WC>
    ) => Promise<void> = async () => {
    },
    dataWrapperInstance: DataWrapper = useRecoilValue(dataWrapper),
    handleError: (e:unknown)=>void = useErrorHandler(),
): {
    details: ModelFields<E> | undefined,
    optionalResult: O | undefined,
    error: Error | undefined
} {
    if (!dataWrapperInstance) {
        throw new Error('You must use `useComplexData` in DataWrapperContext, or pass a dataWrapper instance');
    }
    const processedDeps = Array.isArray(deps) ? deps : [deps];
    const [details, setDetails] = useState<ModelFields<E>>();
    const [optionalResult, setResult] = useState<O>();
    const [error] = useState<Error>();
    const [processedEventMap] = useState<Map<string, boolean>>(new Map());

    const debouncedCallback = AwesomeDebouncePromise(
        callback,
        500,
    );
    const listenerRemovers: { list: Array<() => void>, deferred?: Promise<any> } = { list: [] };
    const getData = async isMounted => {
        const monitoredComplexInstances: WeakSet<ComplexData<any>> = new Set();

        const cleanupIfUnmounted = () => {
            if (!isMounted()) {
                cleanupListeners(listenerRemovers);
                return true;
            }
            return false;
        };

        try {
            const callbackResultSetter = (resultEntity: ComplexData<any> | ComplexData<any>[], newResult) => {
                if (cleanupIfUnmounted()) { return; }
                setResult({ ...optionalResult, ...newResult });
            };

            const complexData = await dataWrapperInstance.getComplexDataObject(complexConstructor, processedDeps[0]);

            const watcher = async <WC extends ComplexData<any> | ComplexData<any>[]>(itemsToWatch: Promise<WC>): Promise<WC> => {
                const getterResult = await itemsToWatch;
                (Array.isArray(getterResult) ? getterResult : [getterResult]).forEach(complexDataItem => {
                    setMonitoredComplexDataInstance(listenerRemovers, complexDataItem, monitoredComplexInstances, {
                        [BaseData.EVENTS.FIELD_CHANGED]: ({ item, eventId }) => {
                            if (cleanupIfUnmounted() || processedEventMap.has(eventId)) { return; }
                            processedEventMap.set(eventId, true);
                            if (item === complexDataItem) {
                                setDetails(complexDataItem.data.getPureDataValues());
                            }
                        },
                        [BaseData.EVENTS.OWN_ASSOCIATION_ADDED]: ({ eventId, item }) => {
                            if (cleanupIfUnmounted() || processedEventMap.has(eventId)) { return; }
                            processedEventMap.set(eventId, true);
                            if (item === complexDataItem) {
                                debouncedCallback(complexData, callbackResultSetter, watcher).catch(handleError);
                            }
                        },
                        [BaseData.EVENTS.OWN_ASSOCIATION_SAVED]: ({ eventId, item }) => {
                            if (cleanupIfUnmounted() || processedEventMap.has(eventId)) { return; }
                            processedEventMap.set(eventId, true);
                            debouncedCallback(complexData, callbackResultSetter, watcher).catch(handleError);
                        },
                    });
                });
                return getterResult;
            };

            if (cleanupIfUnmounted()) { return; }
            await watcher(Promise.resolve(complexData));
            if (cleanupIfUnmounted()) { return; }
            setDetails(complexData.data.getPureDataValues());

            if (cleanupIfUnmounted()) { return; }
            await debouncedCallback(complexData, callbackResultSetter, watcher).catch(handleError);
            if (cleanupIfUnmounted()) { return; }
        } catch (err) {
            if (cleanupIfUnmounted()) { return; }
            handleError(err);
        }
    };

    useAsyncEffect(async isMounted => {
        if (processedDeps[0]) {
            return getData(isMounted);
        }
        return null;
    }, () => cleanupListeners(listenerRemovers), processedDeps);

    return {
        details,
        optionalResult,
        error,
    };
}

export function useSimpleComplexData<T extends ComplexData<any>>(complexConstructor: Newable<T>, deps: number | null) {
    const { details } = useComplexData <PropType<T, '_entity'>, ModelFields<PropType<T, '_entity'>>, T>(complexConstructor, deps);
    return details;
}

export function useComplexDataWithErrorHandling <E extends EntityWithId, O = Record<string, any>, T extends ComplexData<E> = ComplexData<E>>(
    complexConstructor: Newable<T>,
    deps: number | Array<any> | null,
    callback: (_complexInstance: T,
               _resultSetter: (_resultEntity: ComplexData<any> | ComplexData<any>[], _newResult: Partial<O>) => void) => Promise<void> = async () => {
    },
    handleError: (e:unknown)=>void = useErrorHandler(),
): {
    details: ModelFields<E> | undefined,
    optionalResult: O | undefined,
    error: Error | undefined
} {
    return useComplexData(complexConstructor, deps, callback, useRecoilValue(dataWrapper), handleError);
}
