import React from 'react';
import PageBlock from '@components/layout/PageBlock';
import MessageView from '@features/message/view/messageView';
import { useParams } from 'react-router-dom';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ViewMessage() {
    const { id } = useParams<'id'>();
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <PageBlock title="Messages">
            <MessageView messageId={parseInt(id!, 10)} />
        </PageBlock>
    );
}
