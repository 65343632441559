import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RemoteLogin from '@pages/login/remoteLogin';
import Login from '@features/auth/login/login';
import AccountSelect from '@pages/login/accountSelect';

export const baseRoute = 'login';

export default function LoginRoutes() {
    return (
        <Routes>
            <Route path="accountSelect" element={<AccountSelect />} />
            <Route path=":portalId" element={<RemoteLogin />} />
            <Route path="/" element={<Login />} />
        </Routes>
    );
}
