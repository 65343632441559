import React, { useState } from 'react';
import TextField from '@components/fields/TextField';
import Button from '@components/button/Button';
import { Box, Typography } from '@material-ui/core';
import ACTION from '@powerednow/shared/constants/action';
import { hasRole } from '@data/state/permission';
import { ROLES, STATUS } from '@powerednow/shared/constants';
import { useRecoilValue } from 'recoil';
import { AppointmentTypeField } from '@features/appointment/createAppointmentWizard/AppointmentTypeField';
import {
    DayPartKey, format, getPartOfDayNameByKey, patterns,
} from '@powerednow/shared/modules/utilities/date';
import { authState, dataWrapper } from '@data/state/auth';
import useAppointmentBookingSettings, {
    AppointmentBookingSettings,
} from '@data/hooks/useAppointmentBookingSettings';
import Customer from '@powerednow/shared/modules/complexData/customer';
import AppointmentType from '@powerednow/shared/modules/complexData/appointmentType';
import useLocalDateFormat from '@data/hooks/localDateFormat';
import { CircularProgress } from '@components/progress/Progress';
import LabelledBox from '@components/LabelledBox/LabelledBox';
import Mask from '@components/mask/Mask';
import { useResponsive } from '@data/hooks/responsive';
import Action from '@powerednow/shared/modules/complexData/action';
import ErrorBoundaryWithTryAgainModal from '@components/errorBoundary/errorBoundary';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import type ActionEntity from '@powerednow/shared/modules/complexData/action/entity';
import type { PartialModelFields } from '@powerednow/shared/modules/complexData/entity';
import type DataWrapper from '@app/connection/dataWrapper';

const useStyles = makeStyles<Theme>(createStyles({
    appointmentDetails: {
        wordBreak: 'break-word',
    },
}));

const saveAppointment = async ({
    dataWrapperInstance,
    companyId,
    appointmentTypeId,
    day,
    dayPart,
    comment,
    customerId,
    contactId,
    companySettings,
    formattedDate,
    selectedOpenProjectId,
    appointment,
}: {
    dataWrapperInstance: DataWrapper,
    companyId: number,
    appointmentTypeId: number,
    day: Date,
    dayPart: string,
    comment: string,
    customerId: number,
    contactId: number,
    companySettings: AppointmentBookingSettings,
    formattedDate: string,
    selectedOpenProjectId: number,
    appointment: Action,
}) => {
    const customer = await dataWrapperInstance.getComplexDataObject(Customer, customerId);
    const company = await customer.getCompany();
    await company.getCustomerById(customerId);

    const appointmentType = await dataWrapperInstance.getComplexDataObject(AppointmentType, appointmentTypeId);

    const { end, start, dayPartId } = Action.calculateDurationForBooking({
        day,
        dayPart,
        workday_start: companySettings.workday_start,
        workday_end: companySettings.workday_end,
        lunchTime: companySettings.lunchTime,
        eveningHours: companySettings.eveningHours,
        workdayHalfTime: companySettings.workdayHalfTime,
    });

    const appointmentDetails: PartialModelFields<ActionEntity> = {
        appointmenttype_id: appointmentTypeId,
        company_id: companyId,
        dt_requested: format(start, patterns.ISO8601Long),
        dt_start: format(start, patterns.ISO8601Long),
        dt_end: format(end, patterns.ISO8601Long),
        duration_id: dayPartId,
        requested_duration_id: dayPartId,
        action: `${appointmentType.data.name} - ${dayPart.toUpperCase()}`,
        description: comment,
        type: ACTION.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
        plan_type: companySettings.planType,
        status: STATUS.TYPE.APPOINTMENT,
        is_customer_booked: true,
        value: appointmentType.data.price,
    };

    let job;
    if (selectedOpenProjectId > 0) {
        job = await customer.getJobById(selectedOpenProjectId);
    } else {
        const jobData = {
            appointmenttype_id: appointmentTypeId,
            description: `${appointmentType.data.name} "OB" ${formattedDate}`,
            company_id: companyId,
            customer_id: customerId,
            contact_id: contactId,
            status: STATUS.ID.JOB_OPEN,
            is_autocreated: false,
        };

        job = await customer.addJob(jobData);
    }
    appointment.data.set(appointmentDetails);

    await job.addAppointment(appointment);
    await company.addAppointment(appointment);
    await customer.addJob(job);

    await dataWrapperInstance.saveComplexDataObject(customer);
    return appointment;
};

type ConfirmBookingProps = {
    appointmentTypeId: number,
    projectId: number,
    partOfDay: DayPartKey | null,
    day: Date | null,
    onCancel: () => void,
    onConfirmed: (_id:number)=>void,
    comment: string,
    onSetComment: (_comment: string) => void,
}

export default function ConfirmBookingAppointment({
    appointmentTypeId, partOfDay, day, onCancel, projectId, onConfirmed, comment, onSetComment,
}: ConfirmBookingProps) {
    const dataWrapperInstance = useRecoilValue(dataWrapper);

    const authData = useRecoilValue(authState);
    const { data: { companyId, customerId, contactId } } = authData;
    const hasAccountRole = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_BOOK_APPOINTMENT_ON_PORTAL));

    const [bookingInProgress, setBookingInProgress] = useState<boolean>(false);

    const [appointment, setAppointment] = useState<Action>(new Action({}));

    const companySettings = useAppointmentBookingSettings(companyId);
    const dateFormat = useLocalDateFormat(companyId);
    const responsive = useResponsive();
    const classes = useStyles();

    if (!companyId || !customerId || !contactId) {
        return null;
    }

    if (!day || !partOfDay) {
        return null;
    }

    if (!companySettings.loaded || !companySettings.data) {
        return (<CircularProgress />);
    }

    const onConfirmBooking = async () => {
        const formattedDate = format(new Date(), dateFormat?.localDateFormat.toUpperCase());
        setBookingInProgress(true);
        try {
            await saveAppointment({
                dataWrapperInstance,
                companyId,
                appointmentTypeId,
                day,
                dayPart: partOfDay,
                comment,
                customerId,
                contactId,
                companySettings: companySettings.data!,
                formattedDate,
                selectedOpenProjectId: projectId,
                appointment,
            });
            if (appointment.data.id) {
                onConfirmed(appointment.data.id);
                setAppointment(new Action({}));
            }
        } finally {
            setBookingInProgress(false);
        }
    };

    const confirmBookingText = (
        <>
            You are booking the
            {' '}
            <strong>{getPartOfDayNameByKey(partOfDay)}</strong>
            {' '}
            of
            {' '}
            <strong>{day.toDateString()}</strong>
            ,
            {' '}
            {companySettings?.data?.companyName}
        </>
    );

    return (
        <LabelledBox
            labelPosition={responsive({ xs: 'top', md: 'left' })}
            title="Confirm appointment:"
        >
            <Mask
                show={bookingInProgress}
                content={<CircularProgress color="primary" size="large" />}
                overflow={5}
                blur={3}
                alignItems="center"
                flexDirection="column"
            >
                <Box
                    border={1}
                    borderColor="silver"
                    mt={2}
                    p={2}
                    flexDirection="column"
                    flex={1}
                    display="flex"
                >
                    <Typography className={classes.appointmentDetails} paragraph={false} component="div">
                        {confirmBookingText}
                        
                        <AppointmentTypeField appointmentTypeId={appointmentTypeId} field="priceText" prefix=", " />
                        <br />
                        <AppointmentTypeField appointmentTypeId={appointmentTypeId} field="description" suffix={<br />} prefix={<br />} />
                        <br />
                        Your booking is not confirmed until you press the confirm
                        booking button below.
                    </Typography>
                    <br />
                    <TextField
                        noLabelWrap
                        fullWidth
                        multiline
                        label={`Comment (max. ${ACTION.APPOINTMENT_DESCRIPTION_LENGTH} characters):`}
                        placeholder="If you have any information what can be useful, or would like to leave a comment, please add it here."
                        rows={5}
                        value={comment}
                        onChange={e => {
                            onSetComment(e.target.value);
                        }}
                        inputProps={{ maxLength: ACTION.APPOINTMENT_DESCRIPTION_LENGTH }}
                    />
                    <Box display="flex" flex={1} justifyContent="space-between" mt={4}>
                        <Button buttonType="warning" onClick={onCancel}>Back</Button>
                        <ErrorBoundaryWithTryAgainModal>
                            <Button buttonType="secondary" onClick={onConfirmBooking} disabled={!hasAccountRole}>Confirm booking</Button>
                        </ErrorBoundaryWithTryAgainModal>
                    </Box>
                </Box>
            </Mask>
        </LabelledBox>
    );
}
