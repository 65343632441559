import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ErrorRoutes, { baseRoute as errorBase } from '@pages/error/ErrorRoutes';
import LoginRoutes, { baseRoute as loginBase } from '@pages/login/loginRoutes';
import CustomerPortalRoutes, { baseRoute as customerBase } from '@pages/customerPortal/customerPortalRoutes';
import PasswordRoutes, { baseRoute as ResetPasswordBase } from '@pages/password/PasswordRoutes';
import Home from '@pages/home/home';
import RequireAuth from '@features/auth/requireAuth/requireAuth';

const useDebugLocations = () => {
    if (process.env.NODE_ENV === 'development') {
        const location = useLocation();
        console.log(
            `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
        );
    }
};

export default function BaseRoutes() {
    /**
     * If you wish to see location debug info then the line belowuncomment this. I decided to left it uncommented as it adds
     * a lot of unnecessary state reconciliation, which is not necessary equals to renders, but makes debugging
     * a bit more troublesome.
     *
     */
    // useDebugLocations();
    return (
        <Routes>
            <Route path={`${errorBase}/*`} element={<ErrorRoutes />} />
            <Route path={`${loginBase}/*`} element={<LoginRoutes />} />
            <Route path={`${ResetPasswordBase}/*`} element={<PasswordRoutes />} />
            <Route path={`${customerBase}/*`} element={<RequireAuth><CustomerPortalRoutes /></RequireAuth>} />
            <Route path="/" element={<Home />} />
        </Routes>

    );
}
