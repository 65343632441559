import React from 'react';
import { DayPartKey, getPartsOfDay } from '@powerednow/shared/modules/utilities/date';
import ButtonGroup from '@components/button/ButtonGroup';
import { BookingAvailability } from '@powerednow/shared/modules/bookingSlotManager';

type PartOfTheDaySelectorProps = {
    availability: BookingAvailability,
    onChange: (_value: DayPartKey | null, _oldValue:DayPartKey | null, _automatic?: boolean) => void,
    value: DayPartKey | null,
    autoSelect?: boolean,
}

export default function PartOfTheDaySelector(props: PartOfTheDaySelectorProps) {
    const {
        availability,
        onChange,
        value,
        autoSelect,
    } = props;

    if (!availability) {
        return null;
    }

    const enabledParts = getPartsOfDay('keys').filter(partKey => availability[partKey]);

    if (autoSelect && enabledParts.length === 1) {
        onChange(enabledParts[0], value, true);
    }

    if (availability) {
        if (value && !availability[value]) {
            const autoSelectValue = autoSelect && enabledParts[0];
            onChange(autoSelectValue || null, value);
        } 
    }

    const handleClick = newValue => {
        if (value !== newValue) {
            onChange(newValue, value);
        }
    };

    const buttons = getPartsOfDay().map(({ key, name }) => ({
        key,
        label: name,
        disabled: !availability[key],
        selected: key === value,
    }));

    return (
        <ButtonGroup<DayPartKey>
            title=""
            subtitle={availability.date.toDateString()}
            onClick={handleClick}
            buttons={buttons}
        />
    );
}
