import { selector, selectorFamily } from 'recoil';
import { authState, dataWrapper } from '@data/state/auth';
import { ValueOf } from '@powerednow/type-definitions';
import Company from '@powerednow/shared/modules/complexData/company';
import Customer from '@powerednow/shared/modules/complexData/customer';
import { FEATURES } from '@powerednow/shared/constants/subscription';
import PurchaseSetup from '@powerednow/shared/modules/complexData/purchaseSetup';
import { canSiteContactsAccessFullPortal } from '@data/state/companySettings';
import CONTACT_TO_ADDRESS from '@powerednow/shared/constants/contactToAddress';

export const canUseFeature = selectorFamily<boolean, ValueOf<typeof FEATURES>>({
    key: 'canUseFeature',
    get: featureId => async ({ get }) => {
        const authData = get(authState);
        const { data: { companyId } } = authData;
        if (!companyId) {
            return false;
        }
        const dataWrapperInstance = get(dataWrapper);
        const complexCompany = await dataWrapperInstance.getComplexDataObject(Company, companyId!);
        //
        // Make sure all PurchaseSetups are loaded to avoid individual requests
        //
        await PurchaseSetup.getAll(complexCompany);
        const subInfo = await complexCompany.getSubscriptionInfo();
        return subInfo.isSubscriptionAllows(featureId);
    },
});

export const isSiteContact = selector<boolean>({
    key: 'isSiteContact',
    get: async ({ get }) => {
        const authData = get(authState);
        const { data: { contactId, customerId } } = authData;
  
        if (!contactId) {
            return false;
        }

        const dataWrapperInstance = get(dataWrapper);
        const complexCustomer = await dataWrapperInstance.getComplexDataObject(Customer, customerId!);
        const contactToAddress = await complexCustomer.getSiteByContactId(contactId);
        
        return contactToAddress?.data.type === CONTACT_TO_ADDRESS.TYPE.SITE;
    },
});

export const isPortalAvailable = selector<boolean>({
    key: 'isPortalAvailable',
    get: async ({ get }) => {
        const authData = get(authState);
        const { data: { used } } = authData;
        const isPortalAllowed = get(canUseFeature(FEATURES.CAN_USE_PORTAL));
        const isSiteContactBool = get(isSiteContact);
        const canSiteContactsAccessFullPortalAtom = isSiteContactBool ? get(canSiteContactsAccessFullPortal) : true;
        return isPortalAllowed && !used && canSiteContactsAccessFullPortalAtom;
    },
});

export const isSiteContactAndHasFullPortalPermission = selector<boolean>({
    key: 'isSiteContactAndHasFullPortalPermission',
    get: async ({ get }) => {
        const isSiteContactBool = get(isSiteContact);
        return isSiteContactBool ? get(canSiteContactsAccessFullPortal) : true;
    },
});

export const canUsePortalFeature = selector<boolean>({
    key: 'canUsePortalFeature',
    get: async ({ get }) => {
        return get(canUseFeature(FEATURES.CAN_USE_PORTAL));
    },
});
