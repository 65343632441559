import React from 'react';
import {
    DataGrid as MUIDataGrid, DataGridProps as MUIDataGridProps, GridColumns as MUIColumns, GridRowData as MUIRowsProps,
    GridOverlay, useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { Box, BoxProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { omit, camelCase } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { useResponsive } from '@components/hooks/responsive';
import Pagination from '../pagination/Pagination';

interface DataGridProps extends MUIDataGridProps {
    noHeaderSeparator?: boolean
    noRowBorder?: boolean
    striped?: boolean
    enableCellFocus?: boolean
    rowCursor?: 'default' | 'pointer'
    noRowsText?: string
    minHeight?: number | 'string'
    maxHeight?: number | 'string'
    height?: number | 'string'
    boxProps?: BoxProps
    rowCount: number
    paginationStyle?: 'standard' | 'list'
}

export interface Columns extends MUIColumns { }
export interface Rows extends MUIRowsProps { }

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        border: 'none',
        '& .MuiDataGrid-colCell': {
            outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
            outline: 'none !important',
        },
        '& .MuiDataGrid-row.Mui-odd': {
            backgroundColor: theme.palette.dataGridRowColor.main,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.dataGridRowColor.light,
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: theme.palette.dataGridRowColor.selected,
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: theme.palette.dataGridRowColor.selectedhover,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
        },
    },

    noHeaderSeparator: {
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
    },

    noRowBorder: {
        '& .MuiDataGrid-cell': {
            borderBottom: 'none !important',
        },
    },

    striped: {
        '& .MuiDataGrid-row:nth-child(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
    },

    cursorPointer: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    },

    cursorDefault: {
        '& .MuiDataGrid-row': {
            cursor: 'default',
        },
    },

    cellFocus: {
        '& .MuiDataGrid-cell:focus': {
            border: 'dotted 1px rgba(0,0,0,0.2)',
            borderTop: 'none',
        },
    },

    hideOriginalPagination: {
        '& .MuiToolbar-root': {
            display: 'none',
        },
    },
}));

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

function CustomNoRowsOverlay(props: {noRowsText: string}) {
    const { noRowsText } = props;
    return (
        <GridOverlay>
            <Box display="flex" position="absolute">
                <div>{noRowsText || 'Sorry, there is nothing to display'}</div>
            </Box>
        </GridOverlay>
    );
}

function CustomErrorOverlay(props) {
    const { error } = props;
    return (
        <GridOverlay>
            <Box display="flex" position="absolute">
                <div>{error?.message || 'An Error Occurred'}</div>
            </Box>
        </GridOverlay>
    );
}

function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();
    const responsive = useResponsive();

    return state.pagination.pageCount <= 1 ? null : (
        <Pagination
            className={classes.root}
            color="primary"
            count={state.pagination.pageCount}
            page={state.pagination.page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            siblingCount={responsive({ xs: 0, sm: undefined })}
            size={responsive({ xs: 'small', sm: undefined })}
        />
    );
}

export default function DataGrid(props: DataGridProps) {
    const classes = useStyles();
    const {
        page, rows, className, paginationStyle,
        boxProps,
        noHeaderSeparator, noRowBorder,
        striped, rowCursor, enableCellFocus,
        height, maxHeight, minHeight,
    } = props;
    if (!rows) return null;

    let mergedClasses = clsx(className, classes.root);

    if (noHeaderSeparator) {
        mergedClasses = clsx(mergedClasses, classes.noHeaderSeparator);
    }

    if (noRowBorder) {
        mergedClasses = clsx(mergedClasses, classes.noRowBorder);
    }

    if (striped) {
        mergedClasses = clsx(mergedClasses, classes.striped);
    }

    if (rowCursor) {
        mergedClasses = clsx(mergedClasses, classes[camelCase(['cursor', rowCursor].join('_'))]);
    }

    if (enableCellFocus) {
        mergedClasses = clsx(mergedClasses, classes.cellFocus);
    }

    const paginationStyleFinal = paginationStyle || 'list';

    if (paginationStyleFinal === 'list') {
        mergedClasses = clsx(mergedClasses, classes.hideOriginalPagination);
    }

    return (
        <Box
            style={{
                height: height || 'initial',
                maxHeight: maxHeight || 'initial',
                minHeight: minHeight || 300,
            }}
            position="relative"
            {...boxProps}
        >
            <MUIDataGrid
                components={{
                    LoadingOverlay: CustomLoadingOverlay,
                    NoRowsOverlay: CustomNoRowsOverlay,
                    ErrorOverlay: CustomErrorOverlay,
                    Pagination: CustomPagination,
                }}
                className={mergedClasses}
                page={page}
                {...omit(props, ['paginationStyle', 'className', 'noRowsText', 'height', 'minHeight', 'maxHeight'])}
            />
        </Box>
    );
}
