import React from 'react';
import Action from '@powerednow/shared/modules/complexData/action';
import ActionEntity from '@powerednow/shared/modules/complexData/action/entity';
import Bluebird from 'bluebird';
import useComplexData from '@data/hooks/complexDataHook';
import User from '@powerednow/shared/modules/complexData/user';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AppointmentUsersAvatars from '@features/appointment/assignedUsers/AppointmentUsersAvatars';

export type EngineerDetails = {
    assigneeDetails: string,
    profilePictureUrl: string,
}

type AssignedUsersProps = {
    appointmentId: number,
    mode?: 'onlyFirst' | 'all',
};

const useActionUsers = (actionId: number | null) => {
    const { optionalResult } = useComplexData<ActionEntity, { users: EngineerDetails[], loaded: boolean}, Action>(
        Action,
        actionId,
        async (complexAppointment, resultSetter) => {
            const complexUsers: User[] = await complexAppointment.getAssignedUsers();
            const users = await Bluebird.map(complexUsers, async user => {
                const userMedia = await user?.getUserProfilePicture();
                return {
                    assigneeDetails: await user?.getAssigneeDetails(),
                    profilePictureUrl: userMedia?.data?.getPureDataValues().url,
                };
            });
            resultSetter(complexUsers, {
                users,
                loaded: true,
            });
        },
    );

    return {
        users: optionalResult?.users,
        loaded: optionalResult?.loaded || false,
    };
};

export default function AssignedUsers(props: AssignedUsersProps) {
    const { appointmentId, mode } = props;
    const { users, loaded } = useActionUsers(appointmentId);

    if (!loaded) {
        return (
            <Skeleton
                variant="circle"
                width={40}
                height={40}
            />
        );
    }

    if (!Array.isArray(users) || users.length === 0) {
        return <Box>No one assigned</Box>;
    }

    return (
        <AppointmentUsersAvatars users={users} mode={mode} />
    );
}
