import React, { useState, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toast } from '@data/state/toast';
import { Box } from '@material-ui/core';
import Button from '@components/button/Button';
import TextField from '@components/fields/TextField';

import { useNavigate } from 'react-router-dom';

import apiRequest from '@app/connection/apiRequest';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import TokenStorage from '@data/tokenStorage';
import Bluebird from 'bluebird';
import { availableAccounts, AccountDetailsFromServer } from '@data/state/auth';
import Mask from '@components/mask/Mask';
import { LinearProgress } from '@components/progress/Progress';
import { NetworkError, ServiceError } from '@powerednow/shared/error';
import UnAuthorizedPage from '@pages/UnauthorizedPage/UnAuthorizedPage';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import type { TokenDataValues } from '@powerednow/shared/modules/complexData/tokenData/entity';
import { USER_PROFILE } from '@powerednow/shared/constants';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledTextField from '@components/ControlledTextField/ControlledTextField';
import EmailValidator from '@powerednow/shared/modules/validation/emailValidator';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { cloneDeepSafe } from '@powerednow/shared/modules/utilities/object';

const useStyles = makeStyles<Theme>(() => createStyles({
    emailField: {
        height: '40px',
    },
    loginButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
}));

export default function Login() {
    const setToast = useSetRecoilState(toast);
    const [storedAccounts, setStoredAccounts] = useRecoilState(availableAccounts);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loginInProgress, setLoginInProgress] = useState(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const methods = useForm();
    const classes = useStyles();

    const navigate = useNavigate();
    const canProcessWithLogin = email !== '' && password !== '';

    const saveLoginResult = async (result: { authToken: string }[]) => {
        //
        // We collect all tokens first in an object and update the auth state
        // only when all collected to avoid intermediate state update and page re-render
        //
        const newTokens = {};
        const newPortalIds : AccountDetailsFromServer[] = [];
        const updatedStoredAccounts: Record<string, TokenDataValues> = cloneDeepSafe(storedAccounts);

        await Bluebird.each(result, async tokenObj => {
            const authTokenResponse = await apiRequest.getAuthToken(tokenObj.authToken);
            //
            // Update all stored token's used flag to false if they are matching the companyId and contactId
            //
            Object.entries(updatedStoredAccounts).forEach(([key, value]) => {
                if (authTokenResponse.companyId === value.companyId && authTokenResponse.contactId === value.contactId) {
                    updatedStoredAccounts[key].used = false;
                    updatedStoredAccounts[key].userProfiles = updatedStoredAccounts[key].userProfiles && updatedStoredAccounts[key].userProfiles?.length === 0 ? [USER_PROFILE.PORTAL_USER] : updatedStoredAccounts[key].userProfiles;
                }
            });
            const gotThis = Object.values(storedAccounts).some(item => item.contactId === authTokenResponse.contactId && item.companyId === authTokenResponse.companyId);
            if (!authTokenResponse.used) {
                if (!gotThis) {
                    TokenStorage.setToken(tokenObj.authToken, authTokenResponse as any);
                    newTokens[tokenObj.authToken] = authTokenResponse as any;
                }
                newPortalIds.push({
                    companyId: authTokenResponse.companyId,
                    contactId: authTokenResponse.contactId,
                });
            }
        });

        TokenStorage.setPortalIds(newPortalIds);
        TokenStorage.setAllTokens({
            ...updatedStoredAccounts,
            ...newTokens,
        });
        setStoredAccounts({
            ...updatedStoredAccounts,
            ...newTokens,
        });
    };

    const handleLogin = async () => {
        setLoginInProgress(true);
        const hashedPassword = textUtils.hash(password);
        await apiRequest.login(email, hashedPassword).then(async (result: any) => {
            setToast({ message: 'Logged in' });
            await saveLoginResult(result);
            navigate('/login/accountSelect');
        }).catch(ifInstanceOf(ServiceError, () => {
            setLoginInProgress(false);
            setToast({
                message: 'Sorry we can\'t find that account. Check your email address and password were entered correctly and try again.', severity: 'error',
            });
        })).catch(ifInstanceOf(NetworkError, () => {
            setLoginInProgress(false);
            setToast({
                message: 'Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.',
                severity: 'error',
            });
        }));
    };
    const handleLoginWithValidation = methods.handleSubmit(handleLogin);

    const handlePasswordKeyDown = event => {
        if (event.key === 'Enter' && canProcessWithLogin) {
            handleLoginWithValidation();
        }
    };

    const handleEmailChange = event => setEmail(event.target.value);

    const handleEmailKeyDown = event => {
        if (event.key === 'Enter') {
            passwordRef?.current?.focus();
        }
    };

    const emailValidator = new EmailValidator();

    const emailRules = {
        required: 'Please provide an email address.',
        validate: value => emailValidator.isValidEmail(value) || 'Invalid email address.',
    };

    return (
        <UnAuthorizedPage title="Please log in" showCompanyLogo>
            <Mask
                show={loginInProgress}
                content={<LinearProgress />}
                borderRadius={4}
                overflow={10}
            >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <FormProvider {...methods}>
                    <ControlledTextField
                        autoFocus
                        id="email"
                        name="email"
                        placeholder="Email"
                        size="medium"
                        value={email}
                        fullWidth
                        onKeyUp={handleEmailChange}
                        onKeyDown={handleEmailKeyDown}
                        rules={emailRules}
                        className={classes.emailField}
                    />
                    <br />
                    <br />
                    <TextField
                        inputRef={passwordRef}
                        fullWidth
                        placeholder="Password"
                        type="password"
                        size="medium"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        onKeyDown={handlePasswordKeyDown}
                    />
                    <Box className={classes.loginButtonsContainer}>
                        <Box mt={8}>
                            <Button
                                emphasis="low"
                                buttonType="primary"
                                onClick={() => navigate('/resetpassword')}
                                endIcon={null}
                                ml={-2}
                            >
                                Forgot your password?
                            </Button>
                        </Box>
                        <Box mt={8}>
                            <Button
                                emphasis="high"
                                buttonType="secondary"
                                onClick={handleLoginWithValidation}
                                minWidth={80}
                                disabled={!canProcessWithLogin}
                            >
                                Log in
                            </Button>
                        </Box>
                    </Box>
                </FormProvider>
            </Mask>
        </UnAuthorizedPage>
    );
}
