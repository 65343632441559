import { isLoggedIn, isValidLogin, loggedInUser } from '@data/state/auth';
import { CircularProgress } from '@components/progress/Progress';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, { useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ErrorContext } from '@components/errorBoundary/errorBoundary';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const errorContext = useContext(ErrorContext);
    const setLoggedInUser = useSetRecoilState(loggedInUser);
    const { portalId } = useParams<'portalId'>();
    const isValidPortalId = useRecoilValue(isValidLogin(portalId!));
    const isAuthenticated = useRecoilValue(isLoggedIn);
    useEffect(() => {
        if (portalId && portalId !== '') {
            setLoggedInUser(portalId);
        }
    }, [portalId]);

    if (!portalId) {
        return <Navigate to="/login/accountSelect" />;
    }
    //
    // Token storage indicates there was no remote login for this portal id in this browser.
    // This could happen by copying the link from other browser. Pass it on to the remote auth
    // that will do further processing.
    //
    if (!isValidPortalId) {
        return <Navigate to={`/login/${portalId}`} />;
    }

    if (!isAuthenticated) {
        return <CircularProgress />;
    }

    errorContext.redirectPath = `/portal/${portalId}`;
    return children;
}
