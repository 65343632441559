import htmlDecode from '@components/helper/htmlDecode';
import React, { useState, useContext } from 'react';
import Button from '@components/button/Button';
import PersonIcon from '@material-ui/icons/Person';
import MenuButton from '@components/menu/MenuButton';
import MenuItem from '@components/menu/MenuItem';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Lock from '@material-ui/icons/Lock';
import { useRecoilValue } from 'recoil';
import { authState, isLoggedIn } from '@data/state/auth';
import { useNavigate } from 'react-router-dom';
import { useOnlineBookable, useContactDetails } from '@data/hooks/header';
import { CircularProgress } from '@components/progress/Progress';
import { Divider, Hidden } from '@material-ui/core';
import { ThemeContext } from '@components/theme/stylesheet';
import { companySettings } from '@data/state/companySettings';
import { SETTINGS_KEYS } from '@powerednow/shared/constants';
import MenuToggle from '@components/menu/MenuToggle';
import MessageIcon from '@material-ui/icons/Message';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WbSunnyOutlined from '@material-ui/icons/WbSunnyOutlined';
import NightsStayOutlined from '@material-ui/icons/NightsStayOutlined';
import MenuIcon from '@material-ui/icons/Menu';

export interface AccountButtonProps {
    onLogin: () => void,
    onLogout: () => void,
    onCreateAccount: () => void,
    toggleButtonState: boolean,
    onChange?: (value:boolean) => void,
}

export default function AccountButton({
    onLogin, onLogout, onCreateAccount, toggleButtonState, onChange,
}: AccountButtonProps) {
    const themeContext = useContext(ThemeContext);
    const { switchTheme } = themeContext;
    const [activeTheme, setActiveTheme] = useState(toggleButtonState); // Light theme is true, Dark theme is false

    const switchThemeFunction = () => {
        if (activeTheme) {
            setActiveTheme(false);
            switchTheme('dark');
        } else {
            setActiveTheme(true);
            switchTheme('light');
        }
    };

    const authData = useRecoilValue(authState);
    const loginStatus = useRecoilValue(isLoggedIn);
    const navigate = useNavigate();
    const messageRoute = `/portal/${authData.portalId}/message/`;
    const appointmentRoute = `/portal/${authData.portalId}/appointment/`;
    const { contactDetails, hasAccount } = useContactDetails(authData?.data.contactId);
    const setPasswordMenuItemText = hasAccount ? 'Change password' : 'Create Account';
    const bookAppointmentDefaultText = useRecoilValue(companySettings(SETTINGS_KEYS.BOOK_APPOINTMENT_DEFAULT_TEXT));
    const bookableOptions = useOnlineBookable(authData?.data.companyId);
    const loadedAndOnlineBookable = bookableOptions?.loaded && bookableOptions?.isOnlineBookable;

    const navigateToAccountDetails = () => navigate(`/portal/${authData.portalId}/account/viewDetails`);
    const navigateToSendMessage = () => navigate(`${messageRoute}compose`);
    const navigateToBookAppointment = () => navigate(`${appointmentRoute}create`);
    const navigateToSetPassword = () => navigate(`/portal/${authData.portalId}/account/setPassword`);
    const handleLogout = () => onLogout();
    const shouldRenderDivider = (hasAccount && <Divider />);

    const shouldShowLogout = (
        hasAccount && (
            <MenuItem icon={<Lock />} onClick={handleLogout}>
                Logout
            </MenuItem>
        )
    );

    const defaultMenuItems = (
        <div>
            <MenuToggle
                enabledTitle="Dark mode"
                disabledTitle="Light mode"
                enabledIcon={<NightsStayOutlined />}
                disabledIcon={<WbSunnyOutlined />}
                defaultToggleButtonState={activeTheme}
                onToggled={switchThemeFunction}
            />
            <MenuItem
                icon={<PersonIcon />}
                onClick={navigateToAccountDetails}
            >
                Account Details
            </MenuItem>
            <MenuItem
                icon={<LockOpenIcon />}
                onClick={navigateToSetPassword}
            >
                {setPasswordMenuItemText}
            </MenuItem>
            {shouldRenderDivider}
            {shouldShowLogout}
        </div>
    );

    if (!contactDetails) {
        return <CircularProgress />;
    }

    if (loginStatus) {
        return (
            <div>
                <Hidden only={['lg', 'xl', 'md']}>
                    <MenuButton
                        size="large"
                        text=" "
                        startIcon={<MenuIcon />}
                        noWrap
                        mr={2}
                        mb={1}
                        minWidth="60px"
                        pb={4}
                        pt={4}
                    >
                        {loadedAndOnlineBookable
                            && (
                                <MenuItem
                                    icon={<EventNoteIcon />}
                                    onClick={navigateToBookAppointment}
                                >
                                    {htmlDecode(bookAppointmentDefaultText)}
                                </MenuItem>
                            )}
                        <MenuItem
                            icon={<MessageIcon />}
                            onClick={navigateToSendMessage}
                        >
                            Send a message
                        </MenuItem>
                        {defaultMenuItems}
                    </MenuButton>
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                    <MenuButton
                        size="large"
                        text="Menu"
                        noWrap
                        mr={2}
                        mb={1}
                        minWidth="60px"
                    >
                        {defaultMenuItems}
                    </MenuButton>
                </Hidden>
            </div>
        );
    }

    return (
        <div>
            <Button
                onClick={() => onLogin()}
                size="large"
                startIcon={<PersonIcon />}
                noWrap
                mr={1}
                emphasis="medium"
            >
                {' '}
                Log in
            </Button>
            <Button
                onClick={() => onCreateAccount()}
                size="large"
                noWrap
                m={0}
                emphasis="medium"
            >
                {' '}
                Sign up
            </Button>
        </div>
    );
}
