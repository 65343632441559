import React, { useMemo } from 'react';
import { Columns } from '@components/dataGrid/DataGrid';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    parse,
    patterns,
    format,
    convertToLocal,
} from '@powerednow/shared/modules/utilities/date';
import { useProjectDescription } from '@powerednow/portal/src/helper/tabList';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flex: 1,
        lineHeight: '35px',
    },
    spanFont: {
        fontSize: 'x-small',
    },
}));

function DateCell({ row }) {
    const date = parse(row.dt_created, patterns.messageDateTime);
    const finalDate = convertToLocal(date);

    return (
        <span>
            {format(finalDate, patterns.shortDateOnly)}
        </span>
    );
}

function projectCell({ row }) {
    return (
        <Typography variant="inherit" noWrap>
            {useProjectDescription(row.job_id)}
        </Typography>
    );
}

function DetailsCell({ row }) {
    const classes = useStyles();
    const detailsCellText = `Payment for ${row.displaynumber}`;

    return (
        <Box className={classes.root}>
            {detailsCellText}
        </Box>
    );
}

export default function paymentListColumnConfig(displayProjects: boolean): Columns {
    return useMemo(() => {
        const columns: Columns = [{
            field: 'dt_created',
            headerName: 'Date',
            minWidth: 60,
            renderCell: DateCell,

        }, {
            field: 'displaynumber',
            headerName: 'Details',
            headerAlign: 'left',
            minWidth: 210,
            renderCell: DetailsCell,
        }];
        if (displayProjects) {
            columns.push({
                field: 'project',
                headerName: 'Project',
                flex: 1,
                minWidth: 400,
                sortable: false,
                renderCell: projectCell,
            });
        }
        return columns;
    }, [displayProjects]);
}
