import React, { ReactElement, useState, ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    IconButton, InputAdornment, TextField as MUITextField, TextFieldProps as MUITextFieldProps,
} from '@material-ui/core';

import htmlEncode from '@components/helper/htmlEncode';
import htmlDecode from '@components/helper/htmlDecode';

import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import { omit } from 'lodash';
import { getInputFontSizes } from '../theme/stylesheet';

declare type LimitedMUITextFieldProps = Omit<MUITextFieldProps, 'hiddenLabel' | 'color' | 'variant' | 'size'>; // TODO: remove other unnecessary props

export interface TextFieldProps extends LimitedMUITextFieldProps {
    size?: 'small' | 'medium' | 'large',
    clearable?: boolean,
    startAdornment?: ReactElement | string | null,
    endAdornment?: ReactElement | string | null,
    align?: 'left' | 'right' | 'center',
    label?: any,
    noLabelWrap?: boolean,
    noBorder?:boolean,
    variant?: string
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    small: getInputFontSizes(theme, 3.5),
    medium: getInputFontSizes(theme, 4),
    large: getInputFontSizes(theme, 5.5),
    noLabelWrap: {
        '& p': {
            whiteSpace: 'nowrap',
        },
        '& label': {
            whiteSpace: 'nowrap',
        },
    },
    noBorder: {
        '& .MuiInput-underline:before': {
        },
        '& .MuiInput-underline.Mui-focused:after': {
            content: 'none',
        },
    },
}));

export default function TextField(props: TextFieldProps) {
    const {
        value, size, noLabelWrap, startAdornment, endAdornment,
        InputProps, inputProps, InputLabelProps, className, noBorder, align,
        disabled, clearable,
        onChange,
    } = props;
    const classes = useStyles();
    const sizeClass = classes[size || 'medium'];
    const noLabelWrapClass = classes[noLabelWrap ? 'noLabelWrap' : ''];
    const [currentValue, setCurrentValue] = useState(value || '');

    const handleClear = () => {
        setCurrentValue('');
        if (onChange) {
            onChange(
                {
                    target: { value: '' } as (EventTarget & HTMLInputElement) | (EventTarget & HTMLTextAreaElement),
                } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            );
        }
    };

    const renderStartAdornments = () => {
        const adornment = startAdornment || inputProps?.startAdornment;
        if (adornment) {
            return (
                <InputAdornment position="start">
                    <p className="MuiFormLabel-root">{adornment}</p>
                </InputAdornment>
            );
        }
        return null;
    };

    const renderEndAdornments = () => {
        const returnElement: Array<ReactElement> = [];
        const adornment = endAdornment || inputProps?.endAdornment;
        if (adornment) {
            returnElement.push(
                <InputAdornment key="customAdornment" position="end">
                    <p className="MuiFormLabel-root">{adornment}</p>
                </InputAdornment>,
            );
        }

        if (clearable) {
            returnElement.push(
                <InputAdornment key="clearAdornment" position="end">
                    <IconButton
                        size="small"
                        aria-label="clear"
                        onClick={() => handleClear()}
                        style={{ visibility: (currentValue === '' || disabled) ? 'hidden' : 'initial' }}
                    >
                        <Close />
                    </IconButton>
                </InputAdornment>,
            );
        }

        if (clearable || adornment) {
            return returnElement;
        }

        return null;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = htmlEncode(event.target.value);
        if (onChange) {
            onChange(event);
        } else {
            setCurrentValue(event.target.value);
        }
    };

    const startAdornmentComponent: null | ReactElement | Array<ReactElement> = renderStartAdornments();
    const endAdornmentComponent: null | ReactElement | Array<ReactElement> = renderEndAdornments();

    const newProps: MUITextFieldProps = {
        ...omit(props, 'onchange', 'clearable', 'variant', 'noLabelWrap', 'startAdornment', 'endAdornment', 'align', 'numberFormat', 'noBorder'),
        size: undefined,
        InputLabelProps: {
            ...InputLabelProps,
            shrink: true,
        },
        color: 'primary',
        className: clsx(className, sizeClass, noLabelWrapClass, noBorder ? classes.noBorder : null),
        InputProps: {
            ...InputProps,
            startAdornment: startAdornmentComponent,
            endAdornment: endAdornmentComponent,
            inputProps: {
                ...inputProps,
                style: { textAlign: align || 'left' },
            },
        },
        onChange: handleChange,
    };

    return (
        <MUITextField {...newProps} value={htmlDecode(value)} />
    );
}
