import React, { createContext, useState } from 'react';
import {
    unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes,
    ThemeProvider,
    ThemeOptions,
} from '@material-ui/core/styles';

import { CssBaseline } from '@material-ui/core';
import * as _ from 'lodash';
import chroma from 'chroma-js';
import { GridClasses } from '@material-ui/data-grid';

const getShadedColors = (color, light:string | null = null) => ({
    main: color,
    light: light || chroma.scale(['white', color])(0.1).hex(),
    dark: chroma(color).darken(1).hex(),
    contrastText: chroma.scale(['black', color])(0.25).hex(),
});

const getDarkShadedColors = (color, dark:string | null = null) => ({
    main: color,
    dark: dark || chroma.scale(['white', color])(0.8).hex(),
    light: chroma(color).darken(-2).hex(),
    contrastText: chroma.scale(['white', color])(0.25).hex(),
});

export const getInputFontSizes = (theme, size) => ({
    '& input': {
        fontSize: theme.spacing(size),
    },
    '& label': {
        fontSize: theme.spacing(size + 1),
    },
    '& p': {
        fontSize: theme.spacing(size - 0.5),
    },
});

declare module '@material-ui/core/styles/createPalette' {

    interface PaletteColor {
        selected?: string;
        selectedhover?: string;
    }

    interface SimplePaletteColorOptions {
        selected?: string,
        selectedhover?: string;
    }

    interface Palette {
        customBackgrounds: Palette['primary'];
        senderColors: Palette['primary'];
        receiverColors: Palette['primary'];
        neutral: Palette['primary'];
        dataGridRowColor: Palette['primary'];
        backgroundColor: string;
    }
    interface PaletteOptions {
        customBackgrounds: PaletteOptions['primary'];
        senderColors: PaletteOptions['primary'];
        receiverColors: PaletteOptions['primary'];
        neutral: PaletteOptions['primary'];
        dataGridRowColor: PaletteOptions['primary'];
        backgroundColor: string;
    }
}

declare module '@material-ui/core/styles/overrides' {
    interface ComponentNameToClassKey {
        MuiDataGrid: keyof GridClasses
    }
}

const commonThemeProperties: Partial<ThemeOptions> = {
    typography: {
        h6: {
            minWidth: '100px',
        },
        fontFamily: 'Inter, sans-serif',
        button: {
            textTransform: 'none',
        },
        h2: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
    },
    spacing: factor => `${0.25 * factor}rem`,
    shape: {
        borderRadius: 5,
    },
    overrides: {
        MuiTab: {
            root: {
                minWidth: 'auto',
                '@media (min-width: 600px)': {
                    minWidth: 'auto',
                },
            },
        },
        MuiDataGrid: {
            root: {
                '& .MuiDataGrid-footerContainer': {
                    justifyContent: 'center',
                    '@media screen and (min-width: 600px)': {
                        justifyContent: 'space-between',
                    },
                },
            },
        },
    },
};

const lightThemeSource = createMuiTheme({
    ...commonThemeProperties,
    palette: {
        type: 'light',
        primary: getShadedColors('#377dff', '#E5F1FF'),
        secondary: getShadedColors('#2dca8c', '#EAFAF3'),
        warning: getShadedColors('#ffba00', '#FFF2D8'),
        error: getShadedColors('#ff715b', '#FFE3DE'),
        neutral: getShadedColors('#F5F6FA'),
        backgroundColor: '#5e5e5e',

        customBackgrounds: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#F5F6FA',
        },

        senderColors: {
            main: 'white',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },

        receiverColors: {
            main: '#adb8d7',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },

        dataGridRowColor: {
            main: '#f0f0f0',
            light: 'rgba(0, 0, 0, 0.16)',
            dark: 'initial',
            selected: 'rgba(55, 125, 255, 0.12)',
            selectedhover: 'rgba(55, 125, 255, 0.23)',
        },
    },
});

const darkThemeSource = createMuiTheme({
    ...commonThemeProperties,
    palette: {
        type: 'dark',
        primary: getDarkShadedColors('#377dff', '#E5F1FF'),
        secondary: getDarkShadedColors('#2dca8c', '#EAFAF3'),
        warning: getDarkShadedColors('#ffba00', '#FFF2D8'),
        error: getDarkShadedColors('#ff715b', '#FFE3DE'),
        neutral: getDarkShadedColors('#45464A'),
        backgroundColor: '#5e5e5e',

        customBackgrounds: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#45464A',
        },

        background: {
            paper: '#333333',
            default: '#222222',
        },

        senderColors: {
            main: 'rgba(255, 255, 255, 0.77)',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },

        receiverColors: {
            main: '#8d9fd4',
            light: 'rgba(0, 0, 0, 0.87)',
            dark: 'initial',
        },

        dataGridRowColor: {
            main: '#666666',
            light: 'rgba(0, 0, 0, 0.16)',
            dark: 'initial',
            selected: 'rgba(55, 125, 255, 0.12)',
            selectedhover: 'rgba(55, 125, 255, 0.23)',
        },

        text: {
            primary: '#fff',
        },
    },
});

const muiThemeLight = responsiveFontSizes(lightThemeSource);
const muiThemeDark = responsiveFontSizes(darkThemeSource);

export const ThemeContext = createContext<any>({
    themeName: 'light',
    switchTheme: () => 'light',
});

export function ThemeContextProvider(props) {
    const { children } = props;
    const currentTheme = localStorage.getItem('appTheme') || 'light';
    const [theme, setTheme] = useState(currentTheme === 'dark' ? muiThemeDark : muiThemeLight);
    const [themeName, setThemeName] = useState(currentTheme);

    const onSwitchTheme = (name: 'dark' | 'light') => {
        if (name === 'dark') {
            setTheme(muiThemeDark);
            setThemeName(name);
            localStorage.setItem('appTheme', name);
        } else {
            setTheme(muiThemeLight);
            setThemeName(name);
            localStorage.setItem('appTheme', name);
        }
    };

    const contextValue = React.useMemo(() => ({
        themeName,
        switchTheme: onSwitchTheme,
    }), [themeName]);

    return (
        <ThemeContext.Provider value={contextValue}>

            <ThemeProvider theme={_.cloneDeep(theme)}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}
