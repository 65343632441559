import React, { useState } from 'react';
import useComplexData from '@data/hooks/complexDataHook';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CustomerMessageEntity from '@powerednow/shared/modules/complexData/customerMessage/entity';
import CustomerMessage from '@powerednow/shared/modules/complexData/customerMessage';
import { ModelCreationFields } from '@powerednow/shared/modules/complexData/entity';
import SendMessage from '@features/message/view/sendMessage';
import type JobEntity from '@powerednow/shared/modules/complexData/job/entity';
import Mask from '@components/mask/Mask';
import Typography from '@material-ui/core/Typography';
import useDependantState from '@components/hooks/useDependantState';
import MessageHistory from './messageHistory';

type MessageHistoryResults = {
    loaded: boolean;
    complexFirstMessage: CustomerMessage | null;
    parentJob: ModelCreationFields<JobEntity> | null;
    messages: ModelCreationFields<CustomerMessageEntity>[];
    error?: Error | undefined
}

const useMessageHistory = (messageId: number): MessageHistoryResults => {
    const {
        optionalResult,
        error,
    } = useComplexData<CustomerMessageEntity, MessageHistoryResults, CustomerMessage>(
        CustomerMessage,
        messageId,
        async (complexMessage, resultSetter, watch) => {
            const allChildrenMessages = await watch(complexMessage.getHistory());
            const childrenMessages = allChildrenMessages.filter(message => !message.isNewItem());
            const parentJob = await complexMessage.getJob();
            resultSetter(
                childrenMessages,
                {
                    loaded: true,
                    parentJob: parentJob?.data.getPureDataValues(),
                    complexFirstMessage: childrenMessages[0],
                    messages: childrenMessages.map(message => message.data.getPureDataValues()),
                },
            );
        },
    );

    return {
        complexFirstMessage: optionalResult?.complexFirstMessage || null,
        messages: optionalResult?.messages || [],
        parentJob: optionalResult?.parentJob || null,
        loaded: optionalResult?.loaded || false,
        error,
    };
};

type Props = {
    messageId: number
}

function MessageView(props: Props) {
    const theme = useTheme();
    const { messageId } = props;
    const {
        loaded,
        messages,
        parentJob,
        complexFirstMessage,
        error,
    } = useMessageHistory(messageId);

    const [sending, setSending] = useState(false);
    const [hasBlockingError, setHasBlockingError] = useDependantState(false, [messageId]);

    const onSent = () => {
        setSending(false);
    };
    const onSend = () => {
        setSending(true);
    };
    const onError = _error => {
        setHasBlockingError(true);
        setSending(false);
    };

    const containerBackgroundColor = theme.palette.neutral.main;

    if (error) {
        return <div>{error.message}</div>;
    }

    return (
        <Paper>
            <Mask
                show={(!parentJob && loaded) || hasBlockingError}
                content={<Typography variant="body1">This message belongs to a project which has already been deleted. Therefore it can not be displayed and replied.</Typography>}
                overflow={0}
                blur={3}
                alignItems="center"
                flexDirection="column"
                style={{
                    borderRadius: 3,
                }}
            >
                <MessageHistory
                    loaded={loaded && !sending}
                    containerBackgroundColor={containerBackgroundColor}
                    messages={messages}
                />
                <SendMessage
                    containerBackgroundColor={containerBackgroundColor}
                    parentCustomerMessage={complexFirstMessage}
                    onSent={onSent}
                    onSend={onSend}
                    onError={onError}
                />
            </Mask>
        </Paper>

    );
}

export default MessageView;
