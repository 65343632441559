import usePrompt from '@data/hooks/promptHook';
import useComplexData from '@data/hooks/complexDataHook';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import Company from '@powerednow/shared/modules/complexData/company';
import AppointmentType from '@powerednow/shared/modules/complexData/appointmentType';
import Bluebird from 'bluebird';

import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import AppointmentTypeEntity from '@powerednow/shared/modules/complexData/appointmentType/entity';

export type AppointmentTypeResult = {
    loaded: boolean,
    data?: {
        appointmentType: ModelFields<AppointmentTypeEntity>
        formattedPrice: string,
    }
}

export const useAppointmentType = (appointmentTypeId: number | null): AppointmentTypeResult => {
    const { optionalResult } = useComplexData<AppointmentTypeEntity, AppointmentTypeResult, AppointmentType>(
        AppointmentType,
        appointmentTypeId,
        async (complexAppointmentType, resultSetter) => {
            const formattedPrice = await complexAppointmentType.getSymbolizedPriceText();
            resultSetter(complexAppointmentType, {
                loaded: true,
                data: {
                    appointmentType: complexAppointmentType.data.getPureDataValues(),
                    formattedPrice,
                },
            });
        },
    );
    return {
        data: optionalResult?.data,
        loaded: optionalResult?.loaded || false,
    };
};

export type AppointmentTypeSettingsResult = {
    loaded: boolean,
    data: {
        vatRegistered: boolean,
        VATPostFix: string,
        displayPrice: boolean,
    } | undefined
}

export const useAppointmentTypeSettings = (companyId: number | null): AppointmentTypeSettingsResult => {
    const L = usePrompt();
    const { optionalResult } = useComplexData<CompanyEntity, AppointmentTypeSettingsResult, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const {
                vatRegistered, displayPrice, promptValues,
            } = await complexCompany.getAppointmentTypeRelatedSettings();
            const VATPostFix = L(promptValues.id, promptValues.value);

            resultSetter(complexCompany, {
                loaded: true,
                data: {
                    vatRegistered,
                    VATPostFix,
                    displayPrice,
                },
            });
        },
    );

    return {
        data: optionalResult?.data,
        loaded: optionalResult?.loaded || false,
    };
};

type AppointmentTypesResult = {
    loaded: boolean,
    data: {
        appointmentType: ModelFields<AppointmentTypeEntity>,
        formattedPrice: string,
    }[],
}

export default (companyId: number | null): AppointmentTypesResult => {
    const { optionalResult } = useComplexData<CompanyEntity, AppointmentTypesResult, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const ExternalEditableAppointmentTypes = await complexCompany.getAllExternalEditableAppointmentType();
            const appointmentTypes = await Bluebird.map(ExternalEditableAppointmentTypes, async appointmentType => {
                const formattedPrice = await appointmentType.getSymbolizedPriceText();
                return {
                    appointmentType: appointmentType.data.getPureDataValues(),
                    formattedPrice,
                };
            });

            resultSetter(complexCompany, {
                loaded: true,
                data: appointmentTypes,
            });
        },
    );

    return {
        data: optionalResult?.data || [],
        loaded: optionalResult?.loaded || false,
    };
};
