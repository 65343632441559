import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { omit } from 'lodash';

import { Box, BoxProps, Typography } from '@material-ui/core';
import htmlDecode from '@components/helper/htmlDecode';
import { useResponsive } from '../hooks/responsive';

const useStyles = makeStyles<Theme>(theme => createStyles({
    neutralBlock: {
        backgroundColor: theme.palette.neutral.main,
    },
    pageBlockTitle: {
        marginLeft: '-0.25rem',
        marginBottom: '1rem',
        fontWeight: 'bold',
    },
}));

export interface PageBlockProps extends Omit<BoxProps, 'title'> {
    neutral?: boolean
    title?: React.ReactElement | string
    titleAlign?: 'left' | 'right' | 'center'
}

export default function PageBlock({
    neutral, title, pt, pr, pb, pl, titleAlign, children, ...props
}: PageBlockProps) {
    const classes = useStyles();
    const responsive = useResponsive();

    const classNames = clsx(classes.className, neutral ? classes.neutralBlock : null);

    const renderTitle = () => {
        if (title === undefined) {
            return null;
        }

        if (typeof title === 'string') {
            return (
                <Typography
                    className={classes.pageBlockTitle}
                    variant={responsive({ xs: 'h5', md: 'h4' })}
                    align={titleAlign}
                >
                    {htmlDecode(title)}
                </Typography>
            );
        }

        return <Box ml={-1} mb={8}>{title}</Box>;
    };

    return (
        <Box
            display="flex"
            position="relative"
            flexDirection="column"
            pt={pt !== undefined ? pt : responsive({ xs: 1, sm: 8, md: 12 })}
            pr={pr !== undefined ? pr : responsive({ xs: 4, sm: 8, md: 12 })}
            pb={pb !== undefined ? pb : responsive({ xs: 1, sm: 8, md: 12 })}
            pl={pl !== undefined ? pl : responsive({ xs: 4, sm: 8, md: 12 })}
            className={classNames}
            {...omit(props, ['neutral', 'className', 'title', 'titleAlign'])}
        >
            {renderTitle()}
            {children}
        </Box>
    );
}
