import { atom, selector } from 'recoil';

import type { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import CurrencyEntity from '@powerednow/shared/modules/complexData/currency/entity';
import { authState } from '@data/state/auth';
import Company from '@powerednow/shared/modules/complexData/company';
import { DataWrapperInstance } from '@app/connection/dataWrapper';

export const companyCurrency = atom<Partial<ModelFields<CurrencyEntity>>>({
    key: 'CompanyCurrency',
    default: selector({
        key: 'CompanyCurrency/Default',
        get: async ({ get }) => {
            const authData = get(authState);
            const { data: { companyId } } = authData;
            const complexCompany = await DataWrapperInstance.getComplexDataObject(Company, companyId!);
            const complexCurrency = await complexCompany.getCurrency();

            const data = complexCurrency?.data?.getPureDataValues();
            return {
                decimal: data?.decimal || 0,
                decimalsep: data?.decimalsep || '.',
                thousandsep: data?.thousandsep || ',',
                symbol: data?.symbol || '£',
                symbol_before: data?.symbol_before || true,
                iso: data?.iso || 'GBP',
            };
        },
    }),
});

export const currency = atom<Partial<ModelFields<CurrencyEntity>>>({
    key: 'Currency',
    default: selector({
        key: 'Currency/Default',
        get: async ({ get }) => get(companyCurrency),
    }),
});
