import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import LabelledBox from '../LabelledBox/LabelledBox';

export type DetailSectionData = {
    title: string,
    text?: string | React.ReactElement,
    display?: React.CSSProperties['display'],
    hidden?: boolean,
    style?: React.CSSProperties,
}

const useStyles = makeStyles<Theme>(createStyles({
    itemDetailSection: {
        maxHeight: '45vh',
        overflowX: 'auto',
        wordBreak: 'break-word',
        '& > p:first-child, .details-title': {
            marginTop: '0',
            marginBottom: '0',
            overflowWrap: 'break-word',
            hyphens: 'auto',
        },
    },
}));

export default function ItemDetailSection(detailSectionData: DetailSectionData) {
    const {
        title, text, display = 'flex', hidden = false, style = { alignItems: 'center' },
    } = detailSectionData;
    const classes = useStyles();

    if (hidden) return null;

    const shouldParseText = typeof text === 'string' ? ReactHtmlParser(text) : text;

    return (
        <LabelledBox
            title={title}
            component="div"
            className={classes.itemDetailSection}
            display={display}
            style={style}
            labelPosition="left"
            fontWeight="bold"
        >
            {shouldParseText}
        </LabelledBox>

    );
}
