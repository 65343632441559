import React, { useContext } from 'react';
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PageBlock from '@components/layout/PageBlock';
import Button from '@components/button/Button';
import { ErrorContext } from '@components/errorBoundary/errorBoundary';

const useStyles = makeStyles<Theme>(createStyles({
    root: {
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    helperTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '10px',
    },
}));
export default function DefaultFallBack() {
    const classes = useStyles();
    const placeholderText = 'Something went horribly wrong';
    const errorContext = useContext(ErrorContext);
    const { redirectPath } = errorContext;
    const handleOnClick = () => {
        window.location.href = redirectPath;
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={4}>
                <PageBlock
                    display="flex"
                    flexDirection="column"
                    neutral
                    title={placeholderText}
                    titleAlign="center"
                    width={400}
                    position="relative"
                    p={12}
                >
                    <p className={classes.helperTextContainer}>
                        Now we will redirect you to the main page.
                    </p>
                    <Button onClick={handleOnClick}>
                        Ok
                    </Button>
                </PageBlock>
            </Paper>
        </Box>
    );
}
