import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';
import { useRecoilState } from 'recoil';
import { toast } from '@data/state/toast';

type AlertProps = Pick<MuiAlertProps, 'onClose' | 'severity' | 'children'>

const Alert = React.forwardRef(({ onClose, severity, children }: AlertProps, ref) => (
    <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={severity}
        ref={ref}
    >
        {children}
    </MuiAlert>
));

export default function Toast() {
    const [toastDetails, setToastDetails] = useRecoilState(toast);

    const handleClose: any = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastDetails({ ...toastDetails, message: '' });
    };

    return (
        <Snackbar open={toastDetails.message !== ''} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={toastDetails?.severity || 'success'}>
                {toastDetails.message}
            </Alert>
        </Snackbar>
    );
}
