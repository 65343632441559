import React from 'react';
import WelcomeBlock from '@features/welcomeBlock/welcomeBlock';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function CustomerHome() {
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <WelcomeBlock />
    );
}
