import { ColorTypes } from '@components/button/ColoredButton';
import { DOCUMENT } from '@powerednow/shared/constants';

export function setBadgeColor(documentStatus: number): ColorTypes {
    if (!documentStatus) {
        return 'none';
    }
    if (documentStatus === DOCUMENT.STATUS.RAISED || documentStatus === DOCUMENT.STATUS.SENT) {
        return 'primary';
    }
    if (documentStatus === DOCUMENT.STATUS.ACCEPTED
        || documentStatus === DOCUMENT.STATUS.PAID
        || documentStatus === DOCUMENT.STATUS.OVER_PAID
    ) {
        return 'secondary';
    }
    if (documentStatus === DOCUMENT.STATUS.REJECTED) {
        return 'error';
    }
    if (documentStatus === DOCUMENT.STATUS.PART_PAID) {
        return 'warning';
    }
    return 'none';
}

function isInvoice(row) {
    return row.type === DOCUMENT.TYPES_VALUES.INVOICE;
}

export function isInvoicePaid(row, documentStatus) {
    if (!isInvoice(row)) {
        return false;
    }
    return documentStatus === DOCUMENT.STATUS.PAID || documentStatus === DOCUMENT.STATUS.OVER_PAID;
}
