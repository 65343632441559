import React from 'react';
import ErrorPage from '@pages/error/ErrorPage';
import type { ErrorStateType } from '@pages/error/ErrorRoutes';

const renderErrorContent = (props: ErrorStateType) => {
    const { error, portalId, errorCode } = props;
    return 'Invalid credentials.';
};

export default function AuthError(props: ErrorStateType) {
    const { errorCode } = props;
    return (
        <ErrorPage errorCode={errorCode}>
            {renderErrorContent(props) }
        </ErrorPage>
    );
}
