import React from 'react';
import { ResponsiveLabelledBox } from '../LabelledBox/LabelledBox';

export default function ItemActionSection({ children }) {
    return (
        <ResponsiveLabelledBox
            title=""
            labelAlign="left"
            styles={{ marginTop: '0.5rem' }}
            responsiveProps={{ xs: 'top', sm: 'left' }}
        >
            {children}
        </ResponsiveLabelledBox>
    );
}
