import React from 'react';
import AccountPassword from '@features/contact/accountPassword/accountPassword';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function SetAccountPassword() {
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <AccountPassword />
    );
}
