import React, { useEffect, useRef } from 'react';
import { useBoundingclientrectRef } from 'rooks';

type ScrollToBottomProps = {
    children: React.ReactNode,
}

export default function ScrollToBottom(props: ScrollToBottomProps) {
    const { children } = props;
    const containerEndRef = useRef<null | HTMLDivElement>(null);
    const [containerRef, boundingClientRect] = useBoundingclientrectRef();

    const scrollToBottom = () => {
        containerEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        });
    };

    useEffect(() => {
        scrollToBottom();
    }, [boundingClientRect?.height]);

    return (
        <>
            <div ref={containerRef}>
                {children}
            </div>
            <div ref={containerEndRef} />
        </>
    );
}
