import { DependencyResolver } from './types';

export default class DependencyOrderMap<T> {
    private targetPropertyName: string;

    private sourceEntryFilter: DependencyResolver.EntryFilter;

    constructor(private items, {
        targetPropertyName = 'id',
        sourceEntryFilter = ([key, value]) => (key !== targetPropertyName && typeof value === 'symbol'),
    }: DependencyResolver.DependencyResolverConfig = {}) {
        this.targetPropertyName = targetPropertyName;
        this.sourceEntryFilter = sourceEntryFilter;
    }

    public generate(): DependencyResolver.Item<T>[][] {
        const graph: DependencyResolver.Node<T>[] = this.generateNodesAndEdges(this.items);
        const overallOrder = this.resolve(graph);
        const orderGroups = this.convertOverallOrderIntoGroups(overallOrder);

        return this.replaceIdsWithItems(orderGroups);
    }

    private resolve(graph: DependencyResolver.Node<T>[]): DependencyResolver.Node<T>[] {
        const sorted: DependencyResolver.Node<T>[] = [];
        const visited = {};

        graph.forEach((node: DependencyResolver.Node<T>) => {
            this.visit(node, [], sorted, visited, graph);
        });

        return sorted;
    }

    private visit(node: DependencyResolver.Node<T>, ancestors: DependencyResolver.NodeID[], sorted: DependencyResolver.Node<T>[], visited, graph: DependencyResolver.Node<T>[]) {
        ancestors.push(node.nodeID);
        visited[node.nodeID] = true;

        node.ancestors.forEach((ancestor: DependencyResolver.NodeID) => {
            if (ancestors.indexOf(ancestor) >= 0) {
                throw new Error('Circular dependency in graph');
            }

            if (visited[ancestor]) {
                return;
            }

            const ancestorNode = graph.find(item => item.nodeID === ancestor);

            this.visit(ancestorNode, ancestors.slice(0), sorted, visited, graph); // recursive call
        });

        if (sorted.indexOf(node) < 0) {
            sorted.push(node);
        }
    }

    private getPossibleDependencyFields(item): DependencyResolver.NodeID[] {
        return Object.entries(item)
            .filter(this.sourceEntryFilter)
            .map(([, value]: [any, DependencyResolver.NodeID]): DependencyResolver.NodeID => value);
    }

    private generateNodeId(item): DependencyResolver.NodeID {
        return typeof item[this.targetPropertyName] === 'symbol' ? item[this.targetPropertyName] : Symbol(`temp_${String(item[this.targetPropertyName])}`);
    }

    private generateNodesAndEdges(items): DependencyResolver.Node<T>[] {
        return items.reduce((graph: DependencyResolver.Node<T>[], item: DependencyResolver.Item<T>) => {
            const nodeID: DependencyResolver.NodeID = this.generateNodeId(item);
            const ancestors: DependencyResolver.NodeID[] = this.getPossibleDependencyFields(item);

            const node = { item, nodeID, ancestors };

            graph.push(node);

            return graph;
        }, []);
    }

    private replaceIdsWithItems(groups: DependencyResolver.Node<T>[][]): DependencyResolver.Item<T>[][] {
        return groups.map(groupItem => groupItem.map(listItem => listItem.item));
    }

    private convertOverallOrderIntoGroups(overAllOrder: DependencyResolver.Node<T>[]) {
        const overallOrderGroups = [[]];

        overAllOrder.forEach(node => {
            const firstGroup = this.findFirstIndependentGroup(overallOrderGroups, node);
            if (firstGroup) {
                firstGroup.push(node);
            } else {
                overallOrderGroups.push([node]);
            }
        });

        return overallOrderGroups;
    }

    private findFirstIndependentGroup(overallOrderGroups, node) {
        const dependentGroups = overallOrderGroups.filter(group => group.find(groupNode => !!node.ancestors.find(ancestor => groupNode.nodeID === ancestor)));
        if (dependentGroups.length > 0) {
            return overallOrderGroups[overallOrderGroups.indexOf(dependentGroups.pop()) + 1];
        }
        return overallOrderGroups[0];
    }
}
