import React from 'react';
import { ChannelTypes } from '@powerednow/shared/constants/customerEmailTemplateValues';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => createStyles({
    icon: {
        '&:hover, &:focus': {
            color: theme.palette.primary.main,
        },
        margin: theme.spacing(2),
        marginLeft: 0,
    },
}));

type ChannelIndicatorPropTypes = {
    channels: ChannelTypes[]
    onChannelIconClick?: (_c: ChannelTypes) => void
}

export default function ChannelIndicator({ channels, onChannelIconClick = _c => {} }: ChannelIndicatorPropTypes) {
    const classes = useStyles();
    const getChannelIcon = (channel: ChannelTypes, key) => ({
        EMAIL: <EmailIcon fontSize="small" key={key} onClick={() => onChannelIconClick('EMAIL')} className={clsx(classes.icon)} />,
        WHATSAPP: <WhatsAppIcon fontSize="small" key={key} onClick={() => onChannelIconClick('WHATSAPP')} className={clsx(classes.icon)} />,
        SMS: <TextsmsIcon fontSize="small" key={key} onClick={() => onChannelIconClick('SMS')} className={clsx(classes.icon)} />,
    }[channel]);

    return (
        <>
            {channels.map((channel: ChannelTypes, index) => getChannelIcon(channel, index)) }
        </>
    );
}
