import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    MenuItem,
    Select as MUISelect,
    SelectProps as MUISelectProps,
    FormControl,
    InputLabel,
    FormHelperText,
    Box,
} from '@material-ui/core';

import clsx from 'clsx';
import { omit } from 'lodash';
import htmlDecode from '@components/helper/htmlDecode';

declare type LimitedMUISelectProps = Omit<MUISelectProps, 'hiddenLabel' | 'color' | 'size'>; // TODO: remove other unnecessary props

type LabelPositions = 'top' | 'bottom' | 'left' | 'right';

export interface SelectProps extends LimitedMUISelectProps {
    size?: 'small' | 'medium',
    label?: string,
    focused?: boolean,
    labelClassName?: Array<string> | string,
    required?: boolean,
    helperText?: string,
    noLabelWrap?: boolean,
    items?: Record<string, any>[],
    valueProperty?: string,
    displayProperty?: string,
    labelPosition?: LabelPositions,
}

const getFontSizes = (theme, size) => ({
    '&> label': {
        fontSize: theme.spacing(size * 1.25),
    },
    '&> div > .MuiInput-input': {
        fontSize: theme.spacing(size),
    },
});

interface LabelPositionStyle {
    labelPosition: string,
}

const labelPositions = {
    top: {
        alignItems: 'start',
        flexDirection: 'column',
    },
    bottom: {
        alignItems: 'start',
        flexDirection: 'column-reverse',
    },
    left: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '5px',
    },
    right: {
        flexDirection: 'row-reverse',
        justifyContent: 'start',
        alignItems: 'center',
    },
};

const useStyles = makeStyles<Theme, LabelPositionStyle>(theme => createStyles({
    small: getFontSizes(theme, 3.5),
    medium: getFontSizes(theme, 4),
    formControl: {
        minWidth: 120,
        [theme.breakpoints.between('xs', 'sm')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: 900,
        },
    },
    noLabelWrap: {
        whiteSpace: 'nowrap',
        display: 'flex',
    },
    menuPaper: {
        maxHeight: 300,
    },
    labelPosition: ({ labelPosition }) => ({
        ...(labelPosition in labelPositions ? {
            ...labelPositions[labelPosition],
        } : {
            ...labelPositions.top,
        }),
        display: 'flex',
    }),
}));

export default function Select(props: SelectProps) {
    const {
        label,
        size,
        focused,
        items,
        error,
        helperText,
        className,
        valueProperty = 'id',
        displayProperty = 'text',
        labelPosition = 'top',
    } = props;
    const classes = useStyles({ labelPosition });
    const sizeClass = classes[size || 'medium'];

    const controlProps = {
        className: clsx(classes.formControl, className, sizeClass),
        style: {
            minWidth: 120,
            maxHeight: 200,
        },
        size,
        focused,
    };

    const inputProps = {
        ...omit(
            props,
            'className',
            'label',
            'helperText',
            'displayProperty',
            'valueProperty',
            'labelPosition',
        ),
    };

    const containerProps = {
        className: clsx(classes.labelPosition, className),
        size,
        focused,
    };

    const renderItems = () => {
        if (typeof items === 'undefined') return null;

        if (Array.isArray(items)) {
            return items.map((item, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem value={item?.[valueProperty]} key={`option_${key}`}>
                    {htmlDecode(item?.[displayProperty])}
                </MenuItem>
            ));
        }

        if (typeof items === 'object') {
            return Object.keys(items).map(key => <MenuItem value={key}>{items?.[key]}</MenuItem>);
        }

        return null;
    };

    return (
        <Box {...containerProps}>
            {label && (
                <InputLabel
                    className={clsx(classes.noLabelWrap)}
                    shrink
                    error={error}
                >
                    {label}
                </InputLabel>
            )}
            <FormControl {...controlProps}>
                
                <MUISelect {...inputProps} MenuProps={{ classes: { paper: classes.menuPaper } }}>
                    {renderItems()}
                </MUISelect>
                {helperText && <FormHelperText className={clsx(classes.noLabelWrap)} error={error}>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    );
}
