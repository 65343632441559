import React from 'react';
import AppointmentNotification from '@features/appointment/notification/appointmentNotification';
import { useParams } from 'react-router-dom';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ViewAppointment() {
    const { id } = useParams<'id'>();
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <AppointmentNotification actionId={parseInt(id!, 10)} />
    );
}
