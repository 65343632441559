import React from 'react';
import {
    Box, BoxProps, Typography, Paper,
} from '@material-ui/core';
import {
    createStyles, makeStyles, Theme, useTheme,
} from '@material-ui/core/styles';
import { omit } from 'lodash';
import chroma from 'chroma-js';
import clsx from 'clsx';
import { ColorTypes } from '../button/ColoredButton';

const useStyles = makeStyles<Theme>(theme => createStyles({
    tipIcon: {
        // backgroundColor: theme.palette.secondary.light,
        borderRadius: '50%',
        padding: '0.3rem',
        fontSize: '3.5rem',
        marginRight: '1rem',
    },
    tipHeader: {
        lineHeight: '1.5rem',
    },
    tipContent: {
        marginLeft: '4.5rem',
        color: theme.palette.grey[700],
    },
    noIcon: {
        marginLeft: 'initial',
    },
}));

interface TipBoxProps extends Omit<BoxProps, 'title'> {
    icon?: React.ReactElement,
    title: React.ReactElement | string,
    content: React.ReactElement | string,
    elevation?: number,
    color?: ColorTypes
}

export default function TipBox({
    icon,
    title,
    content,
    elevation,
    color,
    ...boxProps
}: TipBoxProps) {
    const classes = useStyles();
    const theme = useTheme();
    const definedColor = color || 'secondary';
    const iconComponent = icon
        ? React.cloneElement(icon, {
            color: definedColor,
            className: classes.tipIcon,
            style: {
                color: definedColor === 'neutral'
                    ? chroma(theme.palette[definedColor].main).darken(1).hex()
                    : theme.palette[definedColor].main,
                backgroundColor: definedColor === 'neutral'
                    ? chroma(theme.palette[definedColor].main).darken(0.1).hex()
                    : chroma(theme.palette[definedColor].main).alpha(0.2).hex(),
            },
        })
        : null;

    return (
        <Box {...boxProps}>
            <Paper elevation={elevation !== undefined ? elevation : 2}>
                <Box flexDirection="column" p={6} pl={icon ? 3 : 6} pt={icon ? 3 : 6}>
                    <Box display="flex" alignItems="center" flexDirection="row" pb={2}>
                        {iconComponent}
                        <Typography variant="h5" className={classes.tipHeader}>
                            {title}
                        </Typography>
                    </Box>
                    <Box pt={2}>
                        <Typography className={clsx(classes.tipContent, icon ? null : classes.noIcon)}>
                            {content}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
