import React, { 
    HTMLProps, useEffect, useRef, useState,
} from 'react';
import ApiRequest from '@app/connection/apiRequest';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import useAsyncEffect from 'use-async-effect';
import { Button as MuiButton, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useInViewRef } from 'rooks';

type DocumentPreviewProps = {
    title: string,
    srcUrl: string,
    style?: object,
    clickHandler?: React.MouseEventHandler,
    clickable?: boolean,
    scrollable: HTMLProps<HTMLIFrameElement>['scrolling'] 
};

const useStyles = makeStyles<Theme>(() => createStyles({
    root: {
        position: 'relative',
    },
    clickableIframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'inlineBlock',
        width: '100%',
        height: '100%',
    },
}));

export default function DocumentPreview(props: DocumentPreviewProps) {
    const {
        title,
        srcUrl,
        style,
        clickHandler,
        clickable,
        scrollable,
    } = props;

    const authData = useRecoilValue(authState);
    const classes = useStyles();
    const [viewRef, inView] = useInViewRef();
    const [height, setHeight] = useState('0px');
    const [htmlContent, setHtmlContent] = useState('');
    const ref = useRef<HTMLIFrameElement>(null);
    const apiRequest = new ApiRequest(authData.data);

    useAsyncEffect(async isMounted => {
        const originalSource = await (apiRequest.getDocumentPreview(`${srcUrl}#toolbar=0`));

        if (!isMounted()) return;

        setHtmlContent(originalSource);
    }, [srcUrl]);

    useEffect(() => {
        if (inView) {
            ref.current!.srcdoc = htmlContent;
            ref.current!.addEventListener('load', () => {
                const adjustedHeight = `${ref.current!.clientWidth * 1.38} px`;
                setHeight(adjustedHeight);
            }, { once: true });
        }
    }, [inView, htmlContent]);

    // TODO viewRef should be passed to Box. Possible after MUI v5
    return (
        <div ref={viewRef}>
            <Box className={classes.root} onClick={clickHandler}>
                <iframe
                    title={title}
                    ref={ref}
                    height={height}
                    width="100%"
                    id="iframe"
                    style={style}
                    scrolling={scrollable}
                />
                {clickable && <MuiButton disableRipple className={classes.clickableIframe} />}
            </Box>
        </div>

    );
}
