import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import { omit, pick, camelCase } from 'lodash';
import clsx from 'clsx';
import {
    createStyles, makeStyles, Theme, useTheme,
} from '@material-ui/core/styles';

import ColoredButton, { ColoredButtonProps, ColorTypes } from './ColoredButton';

declare type LimitedMUIButtonProps = Omit<ColoredButtonProps, 'color' | 'variant'>

export type ButtonProps = LimitedMUIButtonProps & {
    buttonType?: ColorTypes,
    emphasis?: 'high' | 'medium' | 'low',
    noWrap?: boolean,
    minWidth?: number | string,
    maxWidth?: number | string,
    borderRadius?: number | string,
    m?: number | string,
    mt?: number | string,
    mr?: number | string,
    mb?: number | string,
    ml?: number | string,
    p?: number | string,
    pt?: number | string,
    pr?: number | string,
    pb?: number | string,
    pl?: number | string,
    hidden?: boolean,
    target?: string,
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    iconButton: {
        minWidth: 'auto',
        borderRadius: theme.shape.borderRadius,
    },

    smallIcon: {
        padding: theme.spacing(1),
    },

    mediumIcon: {
        padding: theme.spacing(1.5),
    },

    largeIcon: {
        padding: theme.spacing(2),
    },

    smallIconOutlined: {
        padding: theme.spacing(0.75),
    },

    mediumIconOutlined: {
        padding: theme.spacing(1.25),
    },

    largeIconOutlined: {
        padding: theme.spacing(1.75),
    },

    noWrap: {
        '& .MuiButton-label': {
            whiteSpace: 'nowrap',
        },
    },
    hideButton: {
        display: 'none',
    },
}));

export default function Button(props: ButtonProps) {
    const theme = useTheme();
    const classes = useStyles();
    const {
        emphasis, size, minWidth, maxWidth, borderRadius, style, buttonType, noWrap, hidden = false,
    } = props;
    let { className, startIcon, endIcon } = props;
    const variant = { high: 'contained', medium: 'outlined', low: 'text' }[emphasis || 'high'] as 'text' | 'outlined' | 'contained' | undefined;
    const mappedSize = { small: 'small', medium: 'medium', large: 'large' }[size || 'medium'];
    const color = {
        primary: 'primary',
        secondary: 'secondary',
        warning: 'warning',
        error: 'error',
        neutral: 'neutral',
    }[buttonType || 'primary'];

    let { children }: any = props;

    if ((!children || children === ' ') && (startIcon || endIcon)) {
        const iconClass = classes[camelCase([mappedSize, 'Icon', variant === 'outlined' ? 'Outlined' : ''].join('_'))];

        children = startIcon || endIcon;
        className = clsx([className, classes.iconButton, iconClass]);
        startIcon = null;
        endIcon = null;
    }

    if (noWrap) {
        className = clsx([className, classes.noWrap]);
    }

    if (hidden) {
        className = clsx([className, classes.hideButton]);
    }
    let marginStyles = {};

    const spacingProps = pick(props, ['m', 'mt', 'mr', 'mb', 'ml', 'p', 'pt', 'pr', 'pb', 'pl']);
    const applySpacingProps = (key, styleName) => {
        if (spacingProps[key]) {
            if (typeof spacingProps[key] === 'number') {
                spacingProps[key] = theme.spacing(spacingProps[key]);
            }
            marginStyles = { ...marginStyles, [styleName]: spacingProps[key] };
        }
    };
    applySpacingProps('m', 'margin');
    applySpacingProps('mt', 'marginTop');
    applySpacingProps('mr', 'marginRight');
    applySpacingProps('mb', 'marginBottom');
    applySpacingProps('ml', 'marginLeft');
    applySpacingProps('p', 'padding');
    applySpacingProps('pt', 'paddingTop');
    applySpacingProps('pr', 'paddingRight');
    applySpacingProps('pb', 'paddingBottom');
    applySpacingProps('pl', 'paddingLeft');

    return (
        <ColoredButton
            {...{
                color,
                variant,
                className,
                startIcon,
                ...spacingProps,
                ...omit(props, ['className', 'startIcon', 'buttonType', 'color', 'emphasis', 'style', 'noWrap', 'minWidth', 'm', 'mt', 'mr', 'mb', 'ml']),
                endIcon: (typeof endIcon === 'undefined' && variant === 'text') ? <ArrowForward /> : endIcon,
            }}
            style={{
                minWidth: minWidth || 'initial',
                maxWidth: maxWidth || 'initial',
                borderRadius: borderRadius || theme.shape.borderRadius,
                ...marginStyles,
                ...style,
            }}
        >
            {children}
        </ColoredButton>
    );
}
