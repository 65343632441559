import React from 'react';
import ActionButton from '@components/button/ActionButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type DownloadButtonProps = {
    url: string,
    fileName: string,
    children?: React.ReactNode,
};

const useStyles = makeStyles<Theme>(() => createStyles({
    root: {
        textDecoration: 'inherit',
        color: 'inherit',
        width: '100%',
    },
}));

export default function DownloadButton(props: DownloadButtonProps) {
    const { url, fileName, children } = props;
    const classes = useStyles();

    return (
        <a
            className={classes.root}
            href={url}
            download={fileName}
            target="_blank"
            rel="noreferrer"
            aria-label="download"
        >
            <ActionButton fullWidth color="primary">
                {children || 'Download'}
            </ActionButton>
        </a>
    );
}
