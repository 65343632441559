import { selectedTab, TABS } from '@data/state/selectedTab';
import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

export default function useSetSelectedTab(tabIndex: TABS) {
    const setSelectedTab = useSetRecoilState(selectedTab);

    useEffect(() => {
        setSelectedTab(tabIndex);
    }, []);
}
