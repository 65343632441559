import useComplexData from '@data/hooks/complexDataHook';
import { ACTION, SETTINGS_KEYS } from '@powerednow/shared/constants';
import Company from '@powerednow/shared/modules/complexData/company';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';

export type AppointmentBookingSettings = {
    companyName?: CompanyEntity['name'],
    planType: number,
    eveningHours,
    workdayHalfTime: string,
    lunchTime,
    workday_start: CompanyEntity['workday_start'],
    workday_end: CompanyEntity['workday_end'],
};

export type AppointmentBookingSettingsResult = {
    loaded: boolean,
    data: AppointmentBookingSettings
}
export default (companyId: number | null) => {
    const { optionalResult } = useComplexData<CompanyEntity, AppointmentBookingSettingsResult, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            //
            // Due to PN-10765 it is temporarily make hard coded to unplanned for day.
            //
            // const planType = parseInt(await complexCompany.getSettingValue(SETTINGS_KEYS.BOOK_APPOINTMENT_PLAN_TYPE) as string, 10);
            const planType = ACTION.PLAN_TYPES.UNPLANNED_FOR_DAY.ID;
            const lunchTime = JSON.parse(await complexCompany.getSettingValue(SETTINGS_KEYS.LUNCH_TIME) as string);
            const eveningHours = JSON.parse(await complexCompany.getSettingValue(SETTINGS_KEYS.EVENING_HOURS) as string);
            let workdayHalfTime = complexCompany.workdayHalfTime();

            if (lunchTime.enabled) {
                workdayHalfTime = '';
            }

            resultSetter(complexCompany, {
                loaded: true,
                data: {
                    companyName: complexCompany.data.name,
                    planType,
                    eveningHours,
                    workdayHalfTime,
                    lunchTime,
                    workday_start: complexCompany.data.workday_start,
                    workday_end: complexCompany.data.workday_end,
                },
            });
        },
    );

    return {
        data: optionalResult?.data,
        loaded: optionalResult?.loaded || false,
    };
};
