import React, { useState } from 'react';
import { useResponsive } from '@data/hooks/responsive';
import PageBlock from '@components/layout/PageBlock';
import { useParams } from 'react-router-dom';
import apiRequest from '@app/connection/apiRequest';
import { toast } from '@data/state/toast';
import { useSetRecoilState } from 'recoil';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { NetworkError, ServiceError } from '@powerednow/shared/error';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import UnAuthorizedPage from '@pages/UnauthorizedPage/UnAuthorizedPage';

const pageBlockTitle = 'This link has already been used for password reset';
const successfulPasswordChangeMessage = 'New password is successfully changed';
const serviceErrorMessage = 'User not found. The link is invalid or broken.';
const networkErrorMessage = 'Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.';

export default function ForgottenPassword() {
    const responsive = useResponsive();
    const { passwordReminderHash } = useParams<'passwordReminderHash'>();
    const { redirectUrl } = useParams<'redirectUrl'>();
    const setToast = useSetRecoilState(toast);
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });

    if (!passwordReminderHash) {
        return (
            <PageBlock
                display="flex"
                flexDirection="column"
                neutral
                title={pageBlockTitle}
                titleAlign="center"
                minWidth={responsive({ xs: 300, sm: 400 })}
                position="relative"
                p={12}
            />
        );
    }

    const handleNewPassword = async () => {
        const decodedUrl = decodeURIComponent(redirectUrl!);
        const hashedPassword = textUtils.hash(password.password);
        await apiRequest.setPasswordByReminderHash(hashedPassword, passwordReminderHash)
            .then(async (_result: any) => {
                window.location.assign(decodedUrl);
                setToast({
                    message: successfulPasswordChangeMessage,
                });
            }).catch(ifInstanceOf(ServiceError, () => {
                setToast({
                    message: serviceErrorMessage,
                    severity: 'error',
                });
            })).catch(ifInstanceOf(NetworkError, () => {
                setToast({
                    message: networkErrorMessage,
                    severity: 'error',
                });
            }));
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <UnAuthorizedPage title="Set new password">
            <SetNewPassword
                onSetNewPasswordButtonClicked={handleNewPassword}
                onPasswordFieldChange={handleChange}
                password={password.password}
                confirmPassword={password.confirmPassword}
                showCompanyLogo
            />
        </UnAuthorizedPage>
    );
}
