import React from 'react';
import PaymentDetails from '@features/payment/paymentDetails';
import { useParams } from 'react-router-dom';

export default function ViewPayment() {
    const { id } = useParams<'id'>();
    return (
        <PaymentDetails paymentId={parseInt(id!, 10)} />
    );
}
