import config from 'src/config';
import { memoizeRunningPromise } from '@powerednow/shared/decorators';
import packageConfig from '@powerednow/portal/package.json'; 
import { HTTP, LOGIN } from '@powerednow/shared/constants';
import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { GetAssociatedOptions } from '@powerednow/shared/modules/complexData/connectedData';
import {
    NetworkError, ServiceError,
} from '@powerednow/shared/error';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import UserEntity from '@powerednow/shared/modules/complexData/user/entity';
import { AuthData } from '@powerednow/interfaces/api/AuthData';
import ModelNotFoundError from '@app/error/types/ModelNotFoundError';
import AuthenticationError from '@app/error/types/AuthenticationError';
import ApiError from '@app/error/types/ApiError';
import API_RESPONSES from '@powerednow/shared/constants/apiResponses';
import ParentDeletedError from '@app/error/types/ParentDeletedError';

axiosRetry(axios, config.retryOptions);

type Body = {
    token?: string
    portalId?: string,
    authData?: AuthData,
    userData?: Partial<ModelFields<UserEntity>>,
    email?: string,
    password?: string,
    redirectUrl?: string,
    passwordReminderHash?: string,
    userId?: number,
    userUuid?: string,
    companyId?: number,
}

export default class ApiRequest {
    declare ['constructor']: typeof ApiRequest & Function;

    private authData: AuthData;

    constructor(authData: AuthData) {
        this.authData = authData;
    }

    private static getHeaders(): Record<string, any> {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            [HTTP.AUTHENTICATION_HEADER_DOMAIN]: LOGIN.SESSION_DOMAINS.PORTAL,
            [HTTP.HEADER_APP_VERSION]: packageConfig.interfaceVersion,
        };
    }

    private getAuthHeaders(): Record<string, any> {
        return {
            [HTTP.AUTHENTICATION_HEADER_COMPANY]: this.authData?.companyId,
            [HTTP.AUTHENTICATION_HEADER_CUSTOMER]: this.authData?.customerId,
            [HTTP.AUTHENTICATION_HEADER_TOKEN]: this.authData?.authToken,
            [HTTP.AUTHENTICATION_HEADER_DEVICE]: -1,
        };
    }

    private getRequestURL(
        model: string,
        id: number | null = null,
        remoteOptions: GetAssociatedOptions<any> = {},
    ): string {
        const {
            filters = [], limit = 0, skip = 0, sorters = [],
        } = remoteOptions;
        const baseUrl = `${config.API_URL}/api/data/bycompany/${this.authData?.companyId}/${model}`;
        const withId = id ? `${baseUrl}/${id}` : baseUrl;
        const fixedSorters = sorters.map(sorter => ({
            property: sorter.field,
            direction: sorter.sort,
        }));
        const query = [
            filters.length > 0 ? `filter=${encodeURIComponent(JSON.stringify(filters))}` : '',
            fixedSorters.length > 0 ? `sort=${encodeURIComponent(JSON.stringify(fixedSorters))}` : '',
            limit > 0 ? `limit=${limit}&start=${skip}` : '',
            encodeURI('requiredFields[]=company_id&requiredFields[]=is_archived'),
        ].join('&').replace(/&*$/, '').replace(/^&*/, '');
        return query ? `${withId}?${query}` : withId;
    }

    private static getGlobalRequestURL(model: string, id: number | null = null, filter: Record<string, any>[] = []): string {
        const baseUrl = `${config.API_URL}/api/data/global/${model}`;
        const withId = id ? `${baseUrl}/${id}` : baseUrl;
        return filter.length > 0 ? `${withId}?filter=${encodeURIComponent(JSON.stringify(filter))}` : withId;
    }

    private async axiosCall({
        model, id, remoteOptions, options, requestUrl,
    }: { model?: any, id?: any, remoteOptions?: GetAssociatedOptions<any>, options: any, requestUrl?: string, }): Promise<any> {
        let response;
        const url = requestUrl || this.getRequestURL(model, id, remoteOptions || {});

        try {
            response = await axios(url, options);
        } catch (err) {
            throw new NetworkError(err);
        }

        if (response.status !== 200) {
            throw new Error(`${response.statusText} ${response.status}`);
        }
        const jsonResponse = response.data;

        if (jsonResponse.error_code) {
            throw this.generateError(jsonResponse);
        }

        return jsonResponse;
    }

    generateError(jsonResponse): Error {
        if (jsonResponse.error_code === API_RESPONSES.AUTH_FAILED.code) {
            return new AuthenticationError();
        }
        if (jsonResponse.error_code === API_RESPONSES.CAN_NOT_CREATE_RECORD_FOR_DELETED_PARENT.code) {
            return new ParentDeletedError();
        }
        return new ApiError(`${jsonResponse.error_msg} ${jsonResponse.error_code}`);
    }

    public async directApi(model: string, id: number, headers): Promise<any> {
        const options = {
            headers: { ...this.constructor.getHeaders(), ...this.getAuthHeaders(), ...headers },
        };

        return this.axiosCall({
            model, id, options,
        });
    }

    public async getDocumentPreview(
        requestUrl: string,
    ): Promise<any> {
        const options = {
            headers: { ...this.constructor.getHeaders(), ...this.getAuthHeaders() },
        };

        return this.axiosCall({
            options, requestUrl,
        });
    }

    public async request(model: string, id: number | null = null, remoteOptions: GetAssociatedOptions<any> = {}) {
        return this.syncData('GET', model, id, null, remoteOptions);
    }

    public async syncData(
        method: 'POST' | 'PUT' | 'GET' | 'DELETE',
        model: string, 
        id: number | null,
        data: Record<string, any> | null,
        remoteOptions: GetAssociatedOptions<any> = {},
    ): Promise<Array<any>> {
        const options: AxiosRequestConfig = {
            method,
            headers: { ...this.constructor.getHeaders(), ...this.getAuthHeaders() },
        };
        if (data) {
            options.data = data;
        }

        const jsonResponse = await this.axiosCall({
            model, id, remoteOptions, options,
        });

        if ('total' in jsonResponse && 'data' in jsonResponse) {
            const resData = jsonResponse.data;
            resData.total = jsonResponse.total;
            return resData;
        }
        if (id && Array.isArray(jsonResponse)) {
            if (typeof jsonResponse[0] === 'undefined') {
                // TODO
                throw new ModelNotFoundError({ model, id });
            }
            return jsonResponse[0];
        }
        return jsonResponse;
    }

    @memoizeRunningPromise
    public static async getAuthToken(portalId: string): Promise<Record<string, any>> {
        return this.postUnAuthRequest('interfaces/autoLogin', { token: portalId });
    }

    public static async resendAuthToken(portalId: string): Promise<void> {
        return this.postUnAuthRequest('interfaces/autoLogin', { portalId });
    }

    public static async requestGlobalTable(tableName: string, id: number | null = null, filter: Record<string, any>[] = []): Promise<any> {
        // eslint-disable-next-line no-undef
        const options: RequestInit = {
            method: 'GET',
            headers: this.getHeaders(),
        };

        const response = await fetch(this.getGlobalRequestURL(tableName, id, filter), options);
        if (!response.ok) {
            return [{}];
        }
        const jsonResponse = await response.json();
        return id ? jsonResponse[0] : jsonResponse;
    }

    public static async register(authData: AuthData, userData: {
        password: string,
        email: string,
        firstname: string,
        lastname: string,
    }): Promise<any> {
        return this.postUnAuthRequest('interfaces/register', { authData, userData });
    }

    public static async login(email: string, password: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/login', { email, password });
    }

    public static async resetPassword(email: string, redirectUrl: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/resetpassword', { email, redirectUrl });
    }

    public static async setPasswordByReminderHash(password: string, passwordReminderHash: string): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/setnewpassword', { password, passwordReminderHash });
    }

    public static async setPasswordByCompanyData(
        password: string,
        userUuid: string | undefined,
        companyId,
    ): Promise<any> {
        return this.postUnAuthRequest('interfaces/portal/setnewpassword', {
            password,
            userUuid,
            companyId,
        });
    }

    public static async handleResponse(response) {
        if (!response.ok) {
            return Promise.reject(new ServiceError(response.statusText));
        }
        const decodedResponse = await response.json();
        if (decodedResponse.error_msg) {
            return Promise.reject(new ServiceError(decodedResponse));
        }
        return decodedResponse;
    }

    private static async postUnAuthRequest(path: string, body: Body) {
        try {
            const response = await fetch(`${config.API_URL}/api/${path}`, {
                method: 'POST',
                headers: this.getHeaders(),
                body: JSON.stringify(body),
            });
            return ApiRequest.handleResponse(response);
        } catch (e) {
            return Promise.reject(new NetworkError('NetworkError'));
        }
    }

    public async postAuthRequest(path: string, body: Record<string, any>) {
        try {
            const response = await fetch(`${config.API_URL}/api/${path}`, {
                method: 'POST',
                headers: { ...this.constructor.getHeaders(), ...this.getAuthHeaders() },
                body: JSON.stringify(body),
            });
            return ApiRequest.handleResponse(response);
        } catch (e) {
            return Promise.reject(new NetworkError('NetworkError'));
        }
    }
}
