module.exports = {
    /**
     * Returns a webpack require context for template content
     *
     * @private
     */
    _getTemplateContext() {
        return require.context('./', true, /tpl\.html$/);
    },
    /**
     * Returns a webpack require context for inline css include
     *
     * @private
     */
    _getCssContentContext() {
        return require.context('!cache-loader!css-loader!sass-loader!./', true, /^((?!(scss\/)).)*\.useable\.scss$/);
    },

    /**
     * Returns a webpack require context to load css into DOM
     *
     * @private
     */
    _getCssLoadContext() {
        return require.context('./', true, /^((?!(scss\/)).)*\.useable\.scss$/);
    },

    /**
     * Returns a webpack require context to load form template
     *
     * @private
     */
    _getFormTemplateContext() {
        return require.context('@powerednow/certificates/dist', true, /formTemplate-.*bundle\.js$/);
    },

    /**
     * Returns a webpack require context to load form template
     *
     * @private
     */
    _getFormOutputTemplateContext() {
        return require.context('@powerednow/certificates/dist', true, /formOutputTemplate-.*bundle\.js$/);
    },

    /**
     * Returns a CSS(SCSS) file's content, relative to /shared/
     *
     * @param {string} css CSS file name, relative to /shared
     * @returns {string}
     */
    getCssContent(css) {
        const requireCss = this._getCssContentContext();
        const module = requireCss(`${css.replace('.css', '.scss')}`);
        return module.default.toString();
    },

    /**
     * Returns a CSS(SCSS) file's content, relative to /shared/
     *
     * @param {string} css CSS file name, relative to /shared
     * @returns {string}
     */
    requireCss(css) {
        const requireCss = this._getCssLoadContext();
        return requireCss(`${css.replace('.css', '.scss')}`);
    },

    /**
     * Returns a template file's content, relative to /shared/
     * @param {string} template path to template file.
     * @return {string}
     */
    getTemplateContent(template) {
        const requireTemplate = this._getTemplateContext();
        let path = template;
        while (path.indexOf('/') >= 0 && !requireTemplate.keys().includes(path)) {
            path = path.slice(path.indexOf('/') + 1);
        }
        return requireTemplate(path);
    },

    /**
     * Returns a form template
     *
     * @param {string} formName - name of the form
     * @param {string} version - version of the form without the preceding 'v'
     * @returns {object}
     */
    requireFormTemplate(formName, version) {
        const requireFormTemplate = this._getFormTemplateContext();
        return requireFormTemplate(`./formTemplate-${formName}-v${version}.bundle.js`);
    },

    /**
     * Returns a form template
     *
     * @param {string} formName - name of the form
     * @param {string} version - version of the form without the preceding 'v'
     * @returns {object}
     */
    requireFormOutputTemplate(formName, version) {
        const requireFormOutputTemplate = this._getFormOutputTemplateContext();
        return requireFormOutputTemplate(`./formOutputTemplate-${formName}-v${version}.bundle.js`);
    },

};
