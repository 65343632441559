import { atom, selector } from 'recoil';
import ApiRequest from '@app/connection/apiRequest';

const companyQuoteNameId = atom({
    key: 'quote_name_id',
    default: 1,
});

const currentLanguageCode = atom({
    key: 'languageCode',
    default: 'EN-GB',
});

const promptQuery = selector({
    key: 'prompt',
    get: async ({ get }) => {
        try {
            const modelNameWithLanguageCOde = `prompt/${get(currentLanguageCode)}`;
            return await ApiRequest.requestGlobalTable(modelNameWithLanguageCOde);
        } catch {
            return [];
        }
    },
});

export { promptQuery, currentLanguageCode, companyQuoteNameId };
