import {
    useState, useRef, Dispatch, SetStateAction, 
} from 'react';
import { isEqual } from 'lodash';
import type { GridSortModel } from '@material-ui/data-grid';

type SortModelComparatorFunction = (modelA: GridSortModel, modelB: GridSortModel) => boolean;

// The return type should be ReturnType<typeof useState<GridSortModel>> but ReturnType doesn't really like generic function types.
export default function useSorter<T extends GridSortModel>(sortModel: T, comparator?: SortModelComparatorFunction): [T, Dispatch<SetStateAction<T>>] {
    const [localSortModel, setLocalSortModel] = useState(sortModel);
    const sortRef = useRef(localSortModel);
    const compareFn = comparator || isEqual;
    if (!compareFn(sortRef.current, localSortModel)) {
        sortRef.current = localSortModel;
    }
    return [sortRef.current, setLocalSortModel];
}
