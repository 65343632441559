import React from 'react';
import {
    List as MUIList,
    ListItem as MUIListItem,
    ListItemIcon as MUIListItemIcon, ListItemIconProps as MUIListItemIconProps,
    ListItemText as MUIListItemText, ListItemTextProps as MUIListItemTextProps,
    ListItemAvatar as MUIListItemAvatar, ListItemAvatarProps as MUIListItemAvatarProps,
    ListSubheader as MUIListSubheader,
    ListProps as MUIListProps,
    ListItemProps as MUIListItemProps,
    Avatar,
    Box as MuiBox,
} from '@material-ui/core';
import { omit } from 'lodash';

export interface ListProps extends MUIListProps {
    listStyle?: Object,
    listClassName?: string,
}

export interface ListItemProps extends MUIListItemProps {
    avatar?: React.ReactNode,
    startIcon?: React.ReactNode,
    secondary?: React.ReactNode,
    endIcon?: React.ReactNode,
    avatarProps?: Omit<MUIListItemAvatarProps, 'children'>,
    startIconProps?: Omit<MUIListItemIconProps, 'children'>,
    textProps?: Omit<MUIListItemTextProps, 'children'>,
    endIconProps?: Omit<MUIListItemIconProps, 'children'>,
    children?: JSX.Element | string
}

function ListItemIconSlug({ element, isStart, elementProps }) {
    if (element) {
        return <MUIListItemIcon style={{ justifyContent: isStart ? 'flex-start' : 'flex-end' }} {...elementProps}>{element}</MUIListItemIcon>;
    }

    return null;
}

function ListItemAvatarSlug({ element, elementProps }) {
    if (element) {
        return (
            <MUIListItemAvatar {...elementProps}>
                <Avatar>
                    {element}
                </Avatar>
            </MUIListItemAvatar>
        );
    }

    return null;
}

function SubheaderSlug({ element }) {
    if (element) {
        return <MUIListSubheader>{element}</MUIListSubheader>;
    }
    
    return null;
}

function ListItemTextSlug(props) {
    const { primary, secondary, textProps } = props;
    if (primary || secondary) {
        return <MUIListItemText primary={primary} secondary={secondary} {...textProps} />;
    }

    return null;
}

function ListItem(props:ListItemProps) {
    const {
        avatar,
        startIcon,
        secondary,
        endIcon,
        avatarProps,
        startIconProps,
        textProps,
        endIconProps,
        children,
    } = props;
    return (
        <MUIListItem {...omit(props, ['button', 'avatar', 'before', 'children', 'after']) as Omit<ListItemProps, 'button'|'avatar'| 'before'|'children'| 'after'>}>
            <ListItemAvatarSlug element={avatar} elementProps={avatarProps} />
            <ListItemIconSlug element={startIcon} elementProps={startIconProps} isStart />
            <ListItemTextSlug primary={children} secondary={secondary} textProps={textProps} />
            <ListItemIconSlug element={endIcon} elementProps={endIconProps} isStart={false} />
        </MUIListItem>
    );
}

function List(props:ListProps) {
    const {
        listStyle, listClassName, dense, subheader, disablePadding, children, 
    } = props;
    return (
        <MuiBox {...omit(props, ['css', 'dense', 'subheader', 'disablePadding'])}>
            <MUIList style={listStyle} className={listClassName} dense={dense} subheader={<SubheaderSlug element={subheader} />} disablePadding={disablePadding}>
                {children}
            </MUIList>
        </MuiBox>
    );
}

export { List, ListItem };
