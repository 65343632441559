import React, { lazy, useMemo } from 'react';

import DOCUMENT, { TYPES_VALUES_Type } from '@powerednow/shared/constants/document';

export type DocumentActionsProps = {
    status: number,
    documentTypeId: TYPES_VALUES_Type,
    documentStorageUrl: string,
    documentDisplayNumber: string,
    documentTotal: number,
    documentId: number,
}

const documentTypeActionMap = {
    [DOCUMENT.TYPES_VALUES.QUOTE]: 'quote',
    [DOCUMENT.TYPES_VALUES.INVOICE]: 'invoice',
    [DOCUMENT.TYPES_VALUES.WORKSHEET]: 'worksheet',
    [DOCUMENT.TYPES_VALUES.CREDITNOTE]: 'creditNote',
    [DOCUMENT.TYPES_VALUES.SIGNATURE]: 'signature',
    [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE]: 'supplierInvoice',
    [DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE_CREDIT]: 'supplierInvoiceCreditNote',
    [DOCUMENT.TYPES_VALUES.EXPENSE]: 'expense',
    [DOCUMENT.TYPES_VALUES.EXPENSE_CREDITNOTE]: 'expenseCreditNote',
    [DOCUMENT.TYPES_VALUES.PURCHASE_ORDER]: 'purchaseOrder',
};

function DocumentActions({
    status,
    documentTypeId,
    documentStorageUrl,
    documentDisplayNumber,
    documentTotal,
    documentId,
}: DocumentActionsProps) {
    const ActionButtons = useMemo(() => lazy(() => import(`./document/${documentTypeActionMap[documentTypeId]}`)), [documentTypeId]);

    if (ActionButtons) {
        return (
            <ActionButtons
                status={status}
                documentStorageUrl={documentStorageUrl}
                documentDisplayNumber={documentDisplayNumber}
                documentTotal={documentTotal}
                documentId={documentId}
                documentTypeId={documentTypeId}
            />
        );
    }
    return null;
}
export default DocumentActions;
