import React, { useState } from 'react';
import Button from '@components/button/Button';
import UserMediaEntity from '@powerednow/shared/modules/complexData/userMedia/entity';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import FILE_UPLOAD_ACCEPT from '@powerednow/shared/constants/fileUploadAccept';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useResponsive } from '@data/hooks/responsive';
import useComplexData from '@data/hooks/complexDataHook';
import User from '@powerednow/shared/modules/complexData/user';
import UserEntity from '@powerednow/shared/modules/complexData/user/entity';

export type Qualification = Partial <Pick<ModelFields<UserMediaEntity>, 'description' | 'url'>>

type UserQualificationsResults = {
    qualifications: Array<Qualification>,
}

type QualificationsProps = {
    userId: number,
}

const useStyles = makeStyles<Theme>(_theme => createStyles({
    userDocumentButtons: {
        margin: '5px',
    },
    userDocumentButtonContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
}));

const useUserQualifications = userId => {
    const { details, optionalResult } = useComplexData<
        UserEntity,
        UserQualificationsResults,
        User
        >(
            User,
            userId,
            async (complexUser, resultSetter) => {
                const qualificationsProxyData = await complexUser?.getAllUserMediaQualifications();
                const qualificationsArray = qualificationsProxyData?.map(qualification => {
                    const { description, url } = qualification.data.getPureDataValues();
                    return { description, url };
                });
                resultSetter(qualificationsProxyData, {
                    qualifications: qualificationsArray,
                });
            },
        );
    return {
        details,
        qualifications: optionalResult?.qualifications,
    };
};

export default function Qualifications({ userId }: QualificationsProps) {
    const [dialogMedia, setDialogMedia] = useState<Qualification>({});
    const [dialogDisplayFlag, setDialogDisplayFlag] = useState<boolean>(false);
    const classes = useStyles();
    const responsive = useResponsive();
    const { qualifications } = useUserQualifications(userId);

    const openInNewTab = url => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const getFileExtension = filename => filename?.split('.').pop().toLowerCase();
    const mediaQualificationClickHandler = (qualificationClicked : Qualification) => {
        const documentFormat = getFileExtension(qualificationClicked?.url);
        if (FILE_UPLOAD_ACCEPT.SPECIFIC_IMAGES.includes(documentFormat)) {
            setDialogMedia(qualificationClicked);
            setDialogDisplayFlag(true);
        } else {
            openInNewTab(qualificationClicked?.url);
        }
    };
    return (
        <>
            {
                qualifications?.map((qualification, qualificationIndex) => {
                    const description = qualification.description ? qualification.description : `Qualification ${qualificationIndex + 1}`;
                    const { url } = qualification;

                    if (typeof url === 'undefined') {
                        console.error(`Qualification url is undefined for ${description}`);
                        // not rendering the button as the url is undefined
                        return null;
                    }

                    const qualificationClicked = { description, url };

                    return (
                        <Button
                            onClick={() => mediaQualificationClickHandler(qualificationClicked)}
                            className={classes.userDocumentButtons}
                            key={description}
                        >
                            <Box
                                width={responsive({
                                    xs: 200, sm: 300, md: 200, lg: 200,
                                })}
                                className={classes.userDocumentButtonContent}
                            >
                                {description}
                            </Box>
                        </Button>
                    );
                })
            }
        </>
    );
}
