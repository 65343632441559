import React from 'react';
import {
    GridContentAlignment, GridJustification,
} from '@material-ui/core';
import { useResponsive } from '../hooks/responsive';
import ComponentMatrix from './ComponentMatrix';

interface ColumnComponentProps {
    spacing?: number;
    children: React.ReactNode;
}

export default function ColumnComponent({
    children, spacing = 4,
}: ColumnComponentProps) {
    const responsive = useResponsive();
    return (
        <ComponentMatrix minWidth={responsive({ sm: 400, md: 400 })} spacing={spacing} columns={responsive({ xs: 1, md: 2 })}>
            {children}
        </ComponentMatrix>
    );
}
