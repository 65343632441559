import React from 'react';
import {
    Box, Typography,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import LabelledBox from '../LabelledBox/LabelledBox';
import Button from './Button';
import { useResponsive } from '../hooks/responsive';

export type GroupButton<TButtonKeyType extends string = string> = {
    label:string,
    disabled:boolean,
    selected: boolean,
    key: TButtonKeyType,
}

type ButtonGroupProps<TButtonKeyType extends string> = {
    title: string,
    subtitle: string,
    buttons: GroupButton<TButtonKeyType>[],
    onClick:(_key:TButtonKeyType)=>void,
    children?: React.ReactNode[],
}

const useStyles = makeStyles<Theme>(createStyles({
    buttonGroupBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function ButtonGroup<TButtonKeyType extends string>({
    title, subtitle, buttons, onClick, children = [],
}: ButtonGroupProps<TButtonKeyType>) {
    const classes = useStyles();

    const responsive = useResponsive();

    return (
        <LabelledBox
            className={classes.buttonGroupBox}
            title={title}
            p={responsive({ xs: '2rem 0 2rem 0', sm: '1rem 0 1rem 0rem', md: 4 })}
        >
            <Box maxWidth="75%">
                <Typography style={{ textAlign: 'center' }}>{subtitle}</Typography>
                {buttons.map(button => {
                    const {
                        label, key, selected, disabled,
                    } = button;

                    return (
                        <Button
                            key={key}
                            onClick={() => onClick(button.key)}
                            fullWidth
                            buttonType={selected ? 'primary' : 'secondary'}
                            mt={4}
                            p={3}
                            pl={6}
                            pr={6}
                            disabled={disabled}
                        >
                            {label}
                        </Button>
                    );
                })}
                {children}
            </Box>
        </LabelledBox>
    );
}

ButtonGroup.defaultProps = { children: [] };
