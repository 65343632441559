import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import useSetSelectedTab from '@data/hooks/useSetSelectedTab';
import { TABS } from '@data/state/selectedTab';
import { isPortalAvailable } from '@data/state/subscription';
import RequireFeature from '@features/permission/requireFeature';
import NotFound from '@components/NotFound/NotFound';
import CreateAppointment from './createAppointment/createAppointment';
import ViewAppointment from './viewAppointment/viewAppointment';

export const baseRoute = 'appointment';

export default function AppointmentRoutes() {
    useSetSelectedTab(TABS.Appointment);

    return (
        <Routes>
            <Route path=":id/view" element={<ViewAppointment />} />
            <Route element={(
                <RequireFeature feature={isPortalAvailable} fallbackComponent={<NotFound />}>
                    <Outlet />
                </RequireFeature>
            )}
            >
                <Route path="create" element={<CreateAppointment />} />
            </Route>
        </Routes>
    );
}
