import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Company from '@powerednow/shared/modules/complexData/company';
import useComplexData from '@data/hooks/complexDataHook';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import { AuthData } from '@data/state/auth';
import MenuItem from '@components/menu/MenuItem';
import { InstanceForAuth } from '@app/connection/dataWrapper';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import Contact from '@powerednow/shared/modules/complexData/contact';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import ContactMethodEntity from '@powerednow/shared/modules/complexData/contactMethod/entity';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@data/hooks/responsive';
import { CircularProgress } from '@components/progress/Progress';

type AvailableAccountItemProps = {
    auth: AuthData,
    selected?: boolean
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    companyLogo: {
        '& img': {
            maxHeight: '60px',
            maxWidth: '200px',
            borderRadius: '4px',
            marginRight: theme.spacing(4),
        },
    },
    companyDetail: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

const useCompanyDetailsForAuth = (auth: AuthData) => {
    const { companyId } = auth.data;
    const { details, optionalResult } = useComplexData<CompanyEntity, { logo: string }, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const complexLogo = await complexCompany.getCompanyLogo();
            resultSetter(complexLogo, { logo: complexLogo?.data?.getPureDataValues().url });
        },
        InstanceForAuth(auth.data),
    );
    return {
        details,
        companyLogo: optionalResult?.logo,
    };
};

const useContactDetailsForAuth = (auth: AuthData) => {
    const { contactId } = auth.data;

    const { details, optionalResult } = useComplexData<ContactEntity, {
        email: ModelFields<ContactMethodEntity>,
        fullName: string,
    }, Contact>(
        Contact,
        contactId,
        async (complexContact, resultSetter) => {
            const fullName = await complexContact.getFullName();
            const email = await complexContact.getEmail();
            resultSetter(complexContact, {
                fullName,
                email: email?.data.getPureDataValues(),
            });
        },
        InstanceForAuth(auth.data),
    );
    return {
        details,
        optionalResult,
    };
};

function CompanyLogo({ companyLogo }) {
    return !companyLogo ? null : (
        <img alt="logo" src={companyLogo} />
    );
}

const AvailableAccountItem = React.forwardRef<HTMLLIElement, AvailableAccountItemProps>(({ auth, selected }, ref) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const responsive = useResponsive();

    const { details: companyDetails, companyLogo } = useCompanyDetailsForAuth(auth);
    const { optionalResult } = useContactDetailsForAuth(auth);
    if (!companyDetails || !optionalResult) {
        return <CircularProgress />;
    }

    const { email, fullName } = optionalResult;

    const handleSelection = () => {
        navigate(`/login/${auth.portalId}`);
    };

    return (
        <MenuItem
            ref={ref}
            className={classes.companyLogo}
            icon={responsive({ xs: null, md: <CompanyLogo companyLogo={companyLogo} /> })}
            onClick={handleSelection}
            selected={selected}
        >
            <Box className={classes.companyDetail}>
                <Typography variant="h4" noWrap>
                    {companyDetails?.name}
                </Typography>
            </Box>
            <Box className={classes.companyDetail}>
                <Typography variant="h4">
                    {fullName}
                </Typography>
                &nbsp;
                <Typography variant="h4">
                    {email?.value}
                </Typography>
            </Box>
        </MenuItem>
    );
});

export default AvailableAccountItem;
