import React, { useEffect } from 'react';
import { ErrorBoundaryRedirect } from '@components/errorBoundary/errorBoundary';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import Toast from '@features/toast/toast';
import BaseRoutes from '@app/router/baseRoutes';
import { CircularProgress } from '@components/progress/Progress';
import { ThemeContextProvider } from '@components/theme/stylesheet';
import DefaultFallBack from '@components/errorBoundary/DefaultFallBack';
import RecoverableErrorBoundary from '@app/error/RecoverableErrorHandler';
import ReusedLinkSnackBar from '@features/reusedLinkSnackbar/reusedLink';

function DebugObserver() {
    const snapshot = useRecoilSnapshot();
    useEffect(() => {
        console.debug('The following atoms were modified:');
        // eslint-disable-next-line no-restricted-syntax
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            console.debug(node.key, snapshot.getLoadable(node));
        }
    }, [snapshot]);

    return null;
}

function Root() {
    return (
        <ThemeContextProvider>
            <ErrorBoundaryRedirect fallback={<DefaultFallBack />}>
                <RecoilRoot>
                    <DebugObserver />
                    <React.Suspense fallback={<CircularProgress />}>
                        <Router>
                            <RecoverableErrorBoundary>
                                <BaseRoutes />
                            </RecoverableErrorBoundary>
                        </Router>
                        <ReusedLinkSnackBar />
                    </React.Suspense>
                    <Toast />
                </RecoilRoot>
            </ErrorBoundaryRedirect>
        </ThemeContextProvider>
    );
}

export default Root;
