import useComplexData from '@data/hooks/complexDataHook';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import Company from '@powerednow/shared/modules/complexData/company';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import CompanyAddressEntity from '@powerednow/shared/modules/complexData/companyAddress/entity';
import htmlDecode from '@components/helper/htmlDecode';

type CompanyAddressDataType = ModelFields<CompanyAddressEntity>;
const useCompanyDetails = (companyId: number | null) => {
    const { details, optionalResult } = useComplexData<CompanyEntity, { companyAddress: CompanyAddressDataType }, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const complexAddress = await complexCompany.getCompanyAddress();
            resultSetter(complexAddress, { companyAddress: complexAddress?.data?.getPureDataValues() });
        },
    );
    if (details) {
        // TODO: consider decoding all fields what may be affected by htmlDecode
        details.name = htmlDecode(details?.name);
    }
    return {
        details,
        companyAddress: optionalResult?.companyAddress,
    };
};

export default useCompanyDetails;
