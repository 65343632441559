import React from 'react';
import {
    Box, Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Calendar from '@components/calendar/Calendar';
import { useResponsive } from '@data/hooks/responsive';
import {
    isOnSameDay,
} from '@powerednow/shared/modules/utilities/date';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import useComplexData from '@data/hooks/complexDataHook';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import { BookingAvailability } from '@powerednow/shared/modules/bookingSlotManager';
import Company from '@powerednow/shared/modules/complexData/company';
import LabelledBox from '@components/LabelledBox/LabelledBox';

interface AppointmentTypeCalendarProps {
    appointmentTypeId: number | null
    lastBookedAppointmentId?: number | null,
    selectedDay: Date | null
    onSelectDay: Function
}

type AppointmentSlotsResult = {
    loaded: boolean;
    firstBookableDay: Date,
    lastBookableDay: Date,
    availableSlots: BookingAvailability[],
}

const useBookableAppointmentSlots = (companyId, appointmentTypeId, lastBookedAppointmentId) => {
    const { optionalResult } = useComplexData<CompanyEntity, AppointmentSlotsResult, Company>(
        Company,
        [companyId, appointmentTypeId, lastBookedAppointmentId],
        async (complexCompany, resultSetter) => {
            const data = await complexCompany.getBookableAppointmentSlotsByAppointment(appointmentTypeId);
            const { firstBookableDay, lastBookableDay, availableSlots } = data;

            resultSetter(complexCompany, {
                loaded: true,
                firstBookableDay,
                lastBookableDay,
                availableSlots,
            });
        },
    );

    return optionalResult;
};

const useStyles = makeStyles<Theme>(createStyles({
    box: {
        border: 1,
        borderColor: 'silver',
        borderRadius: 4,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
    },
}));

function NoSlotsBox() {
    const responsive = useResponsive();
    const classes = useStyles();
    return (
        <LabelledBox labelPosition={responsive({ xs: 'top', md: 'left' })} title=""> 
            {/* @todo: refactor? why labelledBox? */}
            <Box
                className={classes.box}
                mt={2}
                p={2}
                flex={1}
            >
                <Typography>
                    Sorry, there are no bookable slots.
                </Typography>
            </Box>

        </LabelledBox>
    ); 
}

export default function AppointmentTypeCalendar(props: AppointmentTypeCalendarProps) {
    const authData = useRecoilValue(authState);
    const { data: { companyId } } = authData;

    const {
        appointmentTypeId,
        selectedDay,
        onSelectDay,
        lastBookedAppointmentId,
    } = props;
    const responsive = useResponsive();

    const bookableAppointmentSlots = useBookableAppointmentSlots(companyId, appointmentTypeId, lastBookedAppointmentId);

    if (!bookableAppointmentSlots) {
        return <CircularProgress />; // todo: Loading component maybe...?
    }

    const {
        availableSlots, loaded, firstBookableDay, lastBookableDay, 
    } = bookableAppointmentSlots;

    const selectableDays = availableSlots.filter(slot => ['am', 'pm', 'ev'].some(dayPartKey => slot[dayPartKey]));

    const calendar = (
        <Calendar<{ date: Date }>
            color="primary"
            date={firstBookableDay}
            width={responsive({ xs: 300, md: 400 })}
            firstDay={firstBookableDay}
            lastDay={lastBookableDay}
            enabledDays={selectableDays}
            selectedDays={selectedDay ? [{ date: selectedDay }] : []}
            onSelectDay={date => {
                const daySlots = availableSlots?.find(bookableSlot => isOnSameDay(bookableSlot.date, date));
                onSelectDay(date, daySlots);
            }}
        />
    );

    return (
        <Mask
            display="flex"
            show={!loaded}
            content={<CircularProgress color="primary" size="large" />}
            overflow={5}
            blur={3}
            alignItems="center"
            flexDirection="column"
        >
            {selectableDays.length > 0 ? calendar : <NoSlotsBox />}
        </Mask>
    );
}

export { useBookableAppointmentSlots };
