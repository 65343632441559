import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import AuthenticationErrorFallback from '@app/error/fallbacks/AuthenticationErrorFallback';
import ModelNotFoundFallback from '@app/error/fallbacks/ModelNotFoundFallback';
import ParentDeletedError from '@app/error/types/ParentDeletedError';
import ParentDeletedFallback from '@app/error/fallbacks/ParentDeletedFallback';
import AuthenticationError from './types/AuthenticationError';
import ModelNotFoundError from './types/ModelNotFoundError';

const rethrowError = ({ error }): JSX.Element => { throw error; };

const errorFallbacks = [{
    error: AuthenticationError,
    fallback: AuthenticationErrorFallback,
}, {
    error: ModelNotFoundError,
    fallback: ModelNotFoundFallback,
}, {
    error: ParentDeletedError,
    fallback: ParentDeletedFallback,
}];

/**
 * This is a common error handler, which finds the actual fallback component from `errorFallbacks` array. If we didn't
 * specify error handler for the thrown error, simply rethrow it, let the global error handler handle it.
 * @param error
 * @constructor
 */
function ErrorHandler({ error }) {
    const FallbackComponent = errorFallbacks.find(errorFallback => error instanceof errorFallback.error)?.fallback || rethrowError;

    return (<FallbackComponent error={error} />);
}

export default function RecoverableErrorBoundary({ children }) {
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            { children }
        </ErrorBoundary>
    );
}
