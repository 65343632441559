import React from 'react';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';

type RequireFeatureProps = {
    feature: RecoilValueReadOnly<boolean>,
    children: JSX.Element,
    fallbackComponent?: JSX.Element,
}

export default function RequireFeature({ children, feature, fallbackComponent }: RequireFeatureProps) {
    const isFeatureAvailable = useRecoilValue(feature);
    return isFeatureAvailable ? children : fallbackComponent || null;
}
