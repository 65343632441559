import React from 'react';
import { useRecoilValue } from 'recoil';
import { isPortalUser } from '@data/state/permission';

type RequireFeatureProps = {
    children: JSX.Element,
    fallbackComponent?: JSX.Element,
}

export default function RequirePortalUser({ children, fallbackComponent }: RequireFeatureProps) {
    const isFeatureAvailable = useRecoilValue(isPortalUser);
    return isFeatureAvailable ? children : fallbackComponent || null;
}
