import { useRecoilValue } from 'recoil';
import TokenStorage from 'src/app/data/tokenStorage';
import { availableAccounts } from '@data/state/auth';

export default function useUniqueAccounts() {
    const allAccounts = useRecoilValue(availableAccounts);
    const loginPortalIds = TokenStorage.getPortalIds();
    const filteredAccountsArray = Object.entries(allAccounts).filter(([key, value]) => loginPortalIds.length === 0 || loginPortalIds.some(item => item.companyId === value.companyId && item.contactId === value.contactId));
    return Object.fromEntries(filteredAccountsArray);
}
