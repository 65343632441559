// eslint-disable-next-line max-classes-per-file
import Bluebird from 'bluebird';
import ApiRequest from '@app/connection/apiRequest';
import { AuthData } from '@powerednow/interfaces/api/AuthData';
import _ from 'lodash';
import ComplexData from '@powerednow/shared/modules/complexData/complexData';
import { DependencyResolver as DependencyResolverTypes } from '@powerednow/shared/modules/dependencyResolver/types';
/**
 * Base operation class for CRUD classes
 */
class Operation {
    declare ['constructor']: typeof Operation;

    protected apiRequest: ApiRequest;

    protected items: Array<{ item: ComplexData<any>, postProcess: Function }>;

    constructor(apiRequest: ApiRequest, { items = [] }) {
        this.apiRequest = apiRequest;
        this.items = items.filter(({ item: record }) => this.constructor.condition(record));
    }

    async handle(): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
        return [];
    }

    static condition(_record) {
        return false;
    }

    async process() {
        return Bluebird.map(this.handle(), this.postProcess.bind(this));
    }

    postProcess(result, key) {
        this.items[key].postProcess(result);
        return result;
    }

    async processItems(processor: (_modelName: string, _item: ComplexData<any>) => Record<string, any>): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
        const result: any[] = [];
        await Bluebird.map(this.items, async ({ item }) => {
            const { modelName } = item.constructor.modelDefinition;
            const response = await processor(modelName, item);
            result.push(response || {});
        });
        return result;
    }
}

class Update extends Operation {
    static condition(item) {
        return item.isUpdated;
    }

    async handle(): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
        return this.processItems((modelName, item) => this.apiRequest.syncData('PUT', modelName, item.data.id, item.data.getChangedFields()));
    }
}

class Create extends Operation {
    static condition(item) {
        return item.isNew;
    }

    async handle(): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
        return this.processItems((modelName, item) => this.apiRequest.syncData('POST', modelName, null, item.data.getPureDataValues()));
    }
}

class Delete extends Operation {
    static condition(item) {
        return item.isDeleted;
    }

    async handle(): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
        return this.processItems((modelName, item) => this.apiRequest.syncData('DELETE', modelName, item.data.id, null));
    }
}

/**
 * Exporting the Adapter object what can be passed in to dependency resolver
 * to do the save operation
 */
export default function Adapter(authData: AuthData): DependencyResolverTypes.ORMAdapter<ComplexData<any>> & { doOperation: Function } {
    const apiRequest = new ApiRequest(authData);
    return {
        async doOperation({
            OperationConstructor,
            items,
        }): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
            const operation = new OperationConstructor(apiRequest, { items });
            return operation.process();
        },

        async save(...items): Promise<Array<DependencyResolverTypes.Item<ComplexData<any>>>> {
            const groups = _.groupBy(items, item => item.item.constructor.modelDefinition.modelName);

            const result: Array<DependencyResolverTypes.Item<ComplexData<any>>> = [];
            const groupEntries = Object.entries(groups);
            await Bluebird.map(groupEntries, async ([, itemsInGroup]) => Bluebird.map(
                [Create, Delete, Update],
                async OperationConstructor => {
                    const operationResults = await this.doOperation({
                        OperationConstructor,
                        items: itemsInGroup,
                    });
                    result.push(...operationResults);
                },
            ));
            return result;
        },
    };
}
