import React from 'react';

type OptionalWrapperProps = {
    renderIf: boolean,
    children?: React.ReactNode,
};

export default function Optional<T>(Component: React.ComponentType<T>) {
    return function Wrapper(props: T & OptionalWrapperProps) {
        const { renderIf, children, ...rest } = props;

        if (!renderIf) {
            return null;
        }
        return <Component {...rest as T}>{children}</Component>;
    };
}
