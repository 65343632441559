import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        marginTop: '3vh', 
        [theme.breakpoints.down('md')]: {
            height: '40vh',
        },
        [theme.breakpoints.up('md')]: {
            height: '50vh',
        },
    },
}));

export default function NotFound() {
    const classes = useStyles();

    return (
        <Typography 
            variant="h4" 
            align="center" 
            className={classes.root}
        >
            Sorry, this page is no longer available
        </Typography>
    );
}
