import { useSimpleComplexData } from '@data/hooks/complexDataHook';
import Job from '@powerednow/shared/modules/complexData/job';
import htmlDecode from '@components/helper/htmlDecode';

// eslint-disable-next-line import/prefer-default-export
export function useProjectDescription(projectId) {
    const projectData = useSimpleComplexData(Job, projectId);
    const { description } = projectData || {};
    return htmlDecode(description);
}
