import React from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from '@data/state/auth';
import { Box } from '@material-ui/core';
import useComplexData from '@data/hooks/complexDataHook';
import Contact from '@powerednow/shared/modules/complexData/contact';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import ContactMethodEntity from '@powerednow/shared/modules/complexData/contactMethod/entity';
import { CircularProgress } from '@components/progress/Progress';
import { useResponsive } from '@data/hooks/responsive';
import { SimpleTab } from '@components/layout/Tabs';
import LabelledText from '@components/fields/LabelledText';

// TO DO: Refactor due to storybook. In the current version it is impossible to pass data to it from Storybook and the storybook displays it empty.

const useContactDetails = (contactId: number | null) => {
    if (!contactId) {
        return {};
    }
    const { details, optionalResult } = useComplexData<ContactEntity, {
        phone: ModelFields<ContactMethodEntity>,
        email: ModelFields<ContactMethodEntity>,
    }, Contact>(
        Contact,
        contactId,
        async (complexContact, resultSetter) => {
            const phone = await complexContact.getPhone();
            const email = await complexContact.getEmail();
            resultSetter(complexContact, {
                phone: phone?.data.getPureDataValues(),
                email: email?.data.getPureDataValues(),
            });
        },
    );
    return {
        contactDetails: details,
        contactMethods: optionalResult,
    };
};

export default function AccountDetails() {
    const authData = useRecoilValue(authState);
    const responsive = useResponsive();

    const { contactDetails, contactMethods } = useContactDetails(authData?.data.contactId);

    if (!contactDetails) {
        return <CircularProgress />;
    }

    return (
        <Box
            marginLeft={responsive({ xs: 4, sm: 8, md: 12 })}
            marginY={5}
            marginRight={responsive({ xs: 9, sm: 13, md: 17 })}
            borderRadius={4}
            boxShadow="0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        >
            <SimpleTab
                color="primary"
                value={0}
                position="top"
                panels={[
                    {
                        label: 'Account details',
                        panel: (
                            <>
                                <LabelledText label="First name" text={contactDetails?.firstname} />
                                <LabelledText label="Last name" text={contactDetails?.lastname} />
                                <LabelledText label="Email" text={contactMethods?.email?.value} />
                                <LabelledText label="Phone" text={contactMethods?.phone?.value} />
                            </>
                        ),
                    },
                ]}
            />
        </Box>
    );
}
