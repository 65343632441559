import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import { useRecoilValue } from 'recoil';
import { currency } from '@data/state/currency';

export default function useFormatMoney(amount) {
    const currencyInstance = useRecoilValue(currency);
    return textUtils.formatMoney(amount || 0, {
        decimal: currencyInstance?.decimal || 0,
        decimalsep: currencyInstance?.decimalsep || '.',
        thousandsep: currencyInstance?.thousandsep || ',',
        symbol: currencyInstance?.symbol || '£',
        symbol_before: currencyInstance?.symbol_before || true,
    });
}
