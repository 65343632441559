import React from 'react';
import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps } from '@material-ui/core';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { omit } from 'lodash';
import { ColorTypes } from '../button/ColoredButton';

const getColor = (theme, name) => ({
    '&.MuiAvatar-root': {
        backgroundColor: theme.palette[name].main,
        color: (name === 'warning' || name === 'neutral') ? theme.palette[name].contrastText : theme.palette[name].light,
    },
});

const useStyles = makeStyles<Theme>(theme => createStyles({
    primary: getColor(theme, 'primary'),
    secondary: getColor(theme, 'secondary'),
    warning: getColor(theme, 'warning'),
    error: getColor(theme, 'error'),
    neutral: getColor(theme, 'neutral'),
    small: {
        height: theme.spacing(3 * 2),
        width: theme.spacing(3 * 2),
        fontSize: theme.spacing(0.8 * 4),
    },
    medium: {
        height: theme.spacing(3 * 3),
        width: theme.spacing(3 * 3),
        fontSize: theme.spacing(4),
    },
    large: {
        height: theme.spacing(3 * 4),
        width: theme.spacing(3 * 4),
        fontSize: theme.spacing(1.2 * 4),
    },
    xlarge: {
        height: theme.spacing(10 * 2),
        width: theme.spacing(10 * 2),
        fontSize: theme.spacing(2 * 4),
    },
    xxlarge: {
        height: theme.spacing(10 * 3),
        width: theme.spacing(10 * 3),
        fontSize: theme.spacing(2.5 * 4),
    },
    xxxlarge: {
        height: theme.spacing(10 * 4),
        width: theme.spacing(10 * 4),
        fontSize: theme.spacing(3 * 4),
    },

    squared: {
        borderRadius: '5px',
    },
}));

declare type limitedMUIAvatarProps = Omit<MUIAvatarProps, 'variant' | 'color'>

export type AvatarSizes = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';

export interface AvatarProps extends limitedMUIAvatarProps {
    color?: ColorTypes;
    size?: AvatarSizes;
    squared?: boolean,
}

export default function Avatar(props: AvatarProps) {
    const classes = useStyles();
    const {
        color, size, alt, className, squared, 
    } = props;
    const colorClasses: null | string = clsx(classes?.[color || 'primary']);
    const sizeClasses: null | string = clsx(classes?.[size || 'medium']);

    return (
        <MUIAvatar
            alt={alt || 'avatar'}
            className={clsx(className, colorClasses, sizeClasses, classes.root, squared ? classes.squared : '')}
            {...omit(props, 'className', 'size', 'children', 'alt')}
        />
    );
}
