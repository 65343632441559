import Company from '@powerednow/shared/modules/complexData/company';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import Contact from '@powerednow/shared/modules/complexData/contact';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import useComplexData from '@data/hooks/complexDataHook';

export const useOnlineBookable = (companyId: number | null) => {
    const { optionalResult } = useComplexData<CompanyEntity>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const isOnlineBookable = await (complexCompany as any)?.hasBookableAppointmentSlots();
            resultSetter(complexCompany, {
                loaded: true,
                isOnlineBookable,
            });
        },
    );

    return {
        loaded: optionalResult?.loaded || false,
        isOnlineBookable: optionalResult?.isOnlineBookable || false,
    };
};

export const useContactDetails = (contactId: number | null) => {
    const { details, optionalResult } = useComplexData<ContactEntity, { hasAccount: boolean }, Contact>(
        Contact,
        contactId,
        async (complexContact, resultSetter) => {
            const hasAccount = await complexContact.hasRegisteredUser();
            resultSetter(complexContact, {
                hasAccount,
            });
        },
    );
    return {
        contactDetails: details,
        hasAccount: optionalResult?.hasAccount,
    };
};
