import React from 'react';

import { Box, Card, Typography } from '@material-ui/core/';

export default function AuthenticationErrorFallback({ error }) {
    return (
        <Box width={600} p={15} mx="auto">
            <Card>
                <Box p={10}>
                    <Typography variant="h5" align="center">
                        This page can not be displayed
                    </Typography>
                </Box>
                <Box p={10}>
                    <Typography align="center">
                        We are sorry but the access you are using has been deleted by the company.
                        To solve this, please contact your company via the e-mail you received your messages from.
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
}
