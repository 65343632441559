import ComplexData, { AssociationDefinition } from '@powerednow/shared/modules/complexData/complexData';
import { GetAssociatedOptions } from '@powerednow/shared/modules/complexData/connectedData';
import Entity from '@powerednow/shared/modules/complexData/entity';

import Cache from '@powerednow/shared/modules/complexData/cache';
import { Newable } from '@powerednow/type-definitions';
import ApiRequest from 'src/app/connection/apiRequest';
import { AuthData } from '@powerednow/interfaces/api/AuthData';
import Bluebird from 'bluebird';
import DependencyResolver from '@powerednow/shared/modules/dependencyResolver';
import Adapter from '@app/connection/complexDataAdapter';
import ArrayUtils from '@powerednow/shared/modules/utilities/array';

declare type ArrayWithTotal<T> = Array<T> & { total?: number };

export default class DataWrapper {
    private static instance: DataWrapper;

    private static instanceMap: Record<string, DataWrapper> = {};

    declare ['constructor']: typeof DataWrapper;

    // eslint-disable-next-line no-useless-constructor,no-empty-function
    protected constructor() {

    }

    private static getKeyForMap(auth: AuthData) {
        return `${auth.companyId}_${auth.customerId}_${auth.contactId}`;
    }

    public static get Instance(): DataWrapper {
        if (!this.instance) {
            this.instance = new this();
        }
        return this.instance;
    }

    public static InstanceForAuth(auth: AuthData): DataWrapper {
        const mapKey = this.getKeyForMap(auth);
        if (!this.instanceMap[mapKey]) {
            if (this.getKeyForMap(this.instance.authData) === mapKey) {
                this.instanceMap[mapKey] = this.instance;
            } else {
                this.instanceMap[mapKey] = new this();
                this.instanceMap[mapKey].setAuthData(auth);
            }
        }
        return this.instanceMap[mapKey];
    }

    private cache = new Cache();

    public getCache() {
        return this.cache;
    }

    public reload() {
        this.cache = new Cache();
    }

    private authData: AuthData = {
        companyId: null,
        customerId: null,
        contactId: null,
        authToken: null,
        userProfiles: null,
    };

    public setAuthData(authData: AuthData): void {
        const mapKey = this.constructor.getKeyForMap(authData);
        if (this.constructor.instanceMap[mapKey]) {
            this.constructor.instance = this.constructor.instanceMap[mapKey];
        }
        this.authData = authData;
    }

    public async getModelData<T extends ComplexData<any>, B extends typeof ComplexData>(complexData: Newable<T>, id: number) {
        const castedComplex = complexData as unknown as Newable<T> & B;
        const modelName = castedComplex.getModelName();
        const cachedInstance = this.getCachedInstance(modelName, id);
        if (cachedInstance) {
            return cachedInstance as T;
        }
        return this.getDataFromAPI(castedComplex, id);
    }

    private getCachedInstance<T extends ComplexData<any>>(modelName: string, id: number): T | null {
        const cachedInstance: T = this.cache.get(modelName, id);
        if (cachedInstance) {
            return cachedInstance;
        }
        return null;
    }

    public async getComplexDataObject<T extends ComplexData<any>, B extends typeof ComplexData>(complexData: Newable<T>, id: number): Promise<T> {
        const castedComplex = complexData as unknown as Newable<T> & B;
        const modelName = castedComplex.getModelName();
        const cachedInstance = this.getCachedInstance(modelName, id);
        if (cachedInstance) {
            return cachedInstance as T;
        }
        const modelData = await this.getModelData(complexData, id);

        // @ts-ignore
        return castedComplex.build(modelData, {}, {
            listeners: {
                [castedComplex.EVENTS.MODEL_DATA_REQUEST]: this.onDataRequest.bind(this),
            },
            cache: this.cache,
        }) as Promise<T>;
    }

    public async createComplexDataObject<T extends ComplexData<any>, B extends typeof ComplexData>(complexData: Newable<T>, modelData): Promise<T> {
        const castedComplex = complexData as unknown as Newable<T> & B;
        // @ts-ignore
        return castedComplex.build(modelData, {}, {
            listeners: {
                [castedComplex.EVENTS.MODEL_DATA_REQUEST]: this.onDataRequest.bind(this),
            },
            cache: this.cache,
        }) as unknown as Promise<T>;
    }

    private async onDataRequest(params) {
        try {
            const storeData = await this.collectAssociatedData(params);
            DataWrapper.sendRequestedData(storeData, params);
        } catch (err) {
            params.instance.emit(params.responseEventName, err);
        }
    }

    private async collectAssociatedData(params) {
        const {
            associatedValuesKey,
            Source,
        } = params;
        let storeData;
        if (associatedValuesKey) {
            storeData = await this.getAssociatedData(params);
            const { total } = storeData;
            if (total !== null) {
                storeData.total = total;
            }
        } else {
            if (!Source) throw new Error('Either associationName or sourceName must be specified!!!');
            storeData = await this.getDataFromAPI(Source);
        }
        return storeData;
    }

    private static sendRequestedData(storeData, params) {
        const {
            associatedValuesKey,
            sourceName,
            requestIdentifier,
        } = params;
        console.log('MODEL_DATA_REQUEST event', associatedValuesKey || sourceName, storeData, ComplexData.EVENTS.DATA_LOADED);
        params.instance.emit(params.responseEventName, {
            responseData: storeData,
            responseIdentifier: requestIdentifier,
        });
    }

    private async getAssociatedData(params): Promise<ArrayWithTotal<Record<string, any>>> {
        const {
            instance,
            associatedValuesKey,
            remoteOptions,
        } = params;
        const modelAssociations = instance.constructor.modelDefinition.associations;
        if (!modelAssociations) {
            console.error(`No associations available for ${associatedValuesKey}`);
            return [];
        }
        const associatedData: ArrayWithTotal<Record<string, any>> = [];
        let total: number | null = null;
        await Bluebird.each(this.filterModelAssociations(modelAssociations, associatedValuesKey), async association => {
            const {
                sourceKeyName,
                targetKeyName,
            } = DataWrapper.getSourceAndTargetKeys(association);
            const sourceValue = instance.data[sourceKeyName];

            const allowedAssociationValues: AssociationDefinition<any, any>[] = Object.values(instance.constructor.allowedAssociations);
            const associatedModel = allowedAssociationValues.find((associationItem: AssociationDefinition<any, any>) => {
                if (typeof associationItem.instance !== 'undefined') {
                    return (<typeof ComplexData>(associationItem.instance)).getModelName() === association.model;
                }
                return false;
            });
            if (typeof associatedModel === 'undefined') {
                console.warn(`Missing complex association for model ${association.model} from ${instance.constructor.getTableName()}`);
                return;
            }

            const { instance: ComplexConstructor } = associatedModel;

            const mergedFilter = [...(remoteOptions?.filters || []), {
                operator: '=',
                property: targetKeyName,
                value: sourceValue,
            }];

            const mergedOptions = { ...remoteOptions, filters: mergedFilter };
            const storeData = await this.getRecordsDataByFilter(<typeof ComplexData>ComplexConstructor, mergedOptions);

            storeData.forEach(data => {
                ArrayUtils.deepUniquePush(associatedData, data);
            });

            if (storeData.total) {
                total = (total || 0) + storeData.total;
            }
        });

        if (total !== null) {
            associatedData.total = total;
        }
        return associatedData;
    }

    private async getRecordsDataByFilter(
        ComplexConstructor: typeof ComplexData,
        remoteOptions: GetAssociatedOptions<Entity>,
    ): Promise<ArrayWithTotal<any>> {
        return this.getDataFromAPI(ComplexConstructor, null, remoteOptions);
    }

    private filterModelAssociations(modelAssociations, associatedValuesKey): Record<string, any>[] {
        return modelAssociations.filter(associationItem => {
            const modelName = associationItem.model;
            return (modelName.toLowerCase() === associatedValuesKey.toLowerCase());
        });
    }

    private static getSourceAndTargetKeys(association): { sourceKeyName: string, targetKeyName: string } {
        const isBelongsTo = ['belongsTo', 'belongsToMany'].includes(association.type);
        const sourceKeyName = isBelongsTo ? association.parameters.foreignKey.fieldName || 'id' : association.parameters.foreignKey.sourceKeyName || 'id';
        const targetKeyName = isBelongsTo ? association.parameters.targetKeyName || 'id' : association.parameters.foreignKey.fieldName;
        return {
            sourceKeyName,
            targetKeyName,
        };
    }

    protected async getDataFromAPI(
        ComplexConstructor: typeof ComplexData,
        id: number | null = null,
        remoteOptions: GetAssociatedOptions<Entity> = {},
    ) {
        console.log('getDataFromAPI', ComplexConstructor.getModelName(), id, remoteOptions);
        if (ComplexConstructor.modelProperties.global) {
            return ApiRequest.requestGlobalTable(ComplexConstructor.getTableName(), id, remoteOptions.filters);
        }
        const apiRequest = new ApiRequest(this.authData);
        return apiRequest.request(ComplexConstructor.getTableName(), id, remoteOptions);
    }

    public async saveComplexDataObject(complexData: ComplexData<any>) {
        const resolver = new DependencyResolver(Adapter(this.authData));
        return complexData.save(resolver);
    }
}

export const DataWrapperInstance = DataWrapper.Instance;
export const InstanceForAuth = DataWrapper.InstanceForAuth.bind(DataWrapper);
export type DataWrapperType = DataWrapper;
