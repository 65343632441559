import { useEffect, useState } from 'react';

/**
 * This hook helps to manage a component's own state. If the value
 * comes through prop from a parent component the state can be
 * modified through the parent component.
 * @param value
 */
export default function useStateControl(value) {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return [currentValue, setCurrentValue];
}
