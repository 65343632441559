import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ColorTypes } from './ColoredButton';
import Button from './Button';

type ActionButtonProps = {
    children: React.ReactNode,
    color?: ColorTypes,
    onClick?: React.MouseEventHandler,
    enabled?: boolean,
    href?: string,
    fullWidth?: boolean,
}

const useStyles = makeStyles<Theme>(() => createStyles({
    actionButton: {
        padding: '1rem 1rem',
    },
}));

export default function ActionButton({
    children,
    href,
    onClick,
    enabled = true,
    color,
    fullWidth,
}: ActionButtonProps) {
    const classes = useStyles();

    return (
        <Button
            className={classes.actionButton}
            size="large"
            onClick={onClick}
            buttonType={color || 'secondary'}
            href={href}
            disabled={!enabled}
            fullWidth={fullWidth}
        >
            {children}
        </Button>
    );
}
