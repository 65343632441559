import padWithLeadingCharacters from './padWithLeadingCharacters';

export default function generateDocumentUrlUuidString(sum, companyId) {
    //
    // if empty then nothing to do here
    //
    if (!sum) {
        return '';
    }
    const urlFormattedCompanyId = padWithLeadingCharacters(companyId, 5);
    //
    // Split to 5 digits
    //
    const parts = String(sum).match(/\d{5}/g);
    parts.unshift(urlFormattedCompanyId);
    //
    // Join with dashes
    //
    return parts.join('-');
}
