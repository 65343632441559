import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { getRedirectPath } from '@pages/customerPortal/customerPortalRoutes';
import TokenStorage from '@data/tokenStorage';
import apiRequest from '@app/connection/apiRequest';
import {
    authState, availableAccounts, isValidLogin, loggedInUser,
} from '@data/state/auth';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useAsyncEffect from 'use-async-effect';

import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import Alert from '@material-ui/lab/Alert/Alert';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import { TokenDataValues } from '@powerednow/shared/modules/complexData/tokenData/entity';

const useTokenLogin = portalId => {
    const [status, setStatus] = useState<'loading' | 'loggedIn' | 'NetworkError' | 'TokenError'>('loading');
    const setLoggedInUser = useSetRecoilState(loggedInUser);
    const [storedAccounts, setStoredAccounts] = useRecoilState(availableAccounts);
    const isValidPortalId = useRecoilValue(isValidLogin(portalId));
    const authData = useRecoilValue(authState);

    const doTokenLogin = async (actualPortalId, isMounted) => {
        const authTokenResponse: TokenDataValues = await apiRequest.getAuthToken(actualPortalId) as TokenDataValues;
        let tokenFoundInLocalStorage = false;
        // If we already have the account data stored in auth then dont display "401 Link already used"
        Object.entries(storedAccounts).forEach(([key, value]) => {
            if (value.companyId === authTokenResponse.companyId && value.contactId === authTokenResponse.contactId) {
                tokenFoundInLocalStorage = true;
            }
        }); 

        if (authTokenResponse.used && tokenFoundInLocalStorage) {
            delete authTokenResponse.used;
        }
    
        TokenStorage.setToken(actualPortalId, authTokenResponse);
        if (!isMounted()) return;
        setStoredAccounts({
            ...storedAccounts,
            [actualPortalId]: authTokenResponse,
        });
        setLoggedInUser(actualPortalId);
        setStatus('loggedIn');
    };

    useEffect(() => {
        if (isValidPortalId) {
            setLoggedInUser(portalId);
            setStatus('loggedIn');
        }
    }, [portalId, isValidPortalId]);

    useAsyncEffect(async isMounted => {
        if (isValidPortalId) {
            return;
        }
        try {
            await doTokenLogin(portalId, isMounted);
        } catch (error) {
            if (error instanceof Error) {
                if (error.name === 'NetworkError') {
                    setStatus('NetworkError');
                } else {
                    setStatus('TokenError');
                }
            }
        }
    }, [portalId, isValidPortalId]);
    return {
        status,
        authData,
        isValidPortalId,
    };
};

export default function RemoteLogin() {
    const routeParams = useParams();
    const navigate = useNavigate();
    const { status, authData, isValidPortalId } = useTokenLogin(routeParams.portalId);
    /**
     * This is kinda strange, so here is some reasoning.
     * We have an accountSelect button, which can switch between portal users. As this is
     * same we use for logins, and it is possible the token expires at any given time, we always
     * go through the this component, which can do a new remote login, show an error or
     * immediately navigates us the the newly selected portal user.
     * But this redirection go through an additional route, the `login/:portalId`. Previously
     * if we were already logged in and switched to another portal user,
     * it redirected us to `login/newToken`, which ended up here, rendering a <Navigate .../>
     * component. Which first render nothing, then redirects the app to a new portal user,
     * having a nice white 'nothing' during the page load. So instead of declarative routing I
     * went with imperative one, where we render a progress bar first, then we give react a bit
     * of time to _actually_ render it and redirect imperatively after. Doing this without setTimeout
     * simply locks the dom before SVG could render, so we see a nice gray page.
     *
     */
    useEffect(() => {
        setTimeout(() => {
            if (status === 'loggedIn' && isValidPortalId && authData.portalId === routeParams.portalId) {
                const redirectRoute = getRedirectPath(authData);
                if (redirectRoute) {
                    navigate(redirectRoute);
                } else {
                    navigate(`/portal/${routeParams.portalId}`);
                }
            }
        }, 1000);
    }, [status, isValidPortalId, routeParams.portalId, authData.portalId]);

    if (status === 'TokenError') {
        return (
            <Navigate
                to="/error/auth"
                state={{
                    error: 'reusedToken',
                    portalId: routeParams.portalId,
                }}
            />
        );
    }

    return (
        <>
            <Mask content={<CircularProgress />} fullScreen show />
            <Snackbar open={status === 'NetworkError'} autoHideDuration={6000}>
                <Alert severity="error" elevation={6} variant="filled">
                    There was a network error trying to log you in. Refresh when network is back.
                </Alert>
            </Snackbar>
        </>
    );
}
