import FAQContent from '@data/state/FAQ';
import { useRecoilState } from 'recoil';
import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

export type FAQ = [string, string];

export default function useSetFAQS(faq: FAQ) {
    const [currentFAQ, setCurrentFAQS] = useRecoilState(FAQContent);
    const sortRef = useRef(currentFAQ);

    if (!isEqual(sortRef.current, faq)) {
        sortRef.current = faq;
    }

    useEffect(() => {
        setCurrentFAQS(faq);
    }, [sortRef.current]);
}
