const defaultConfig = {
    API_URL: 'https://api-test.aws.powerednow.org',
    retryOptions: {
        retries: 3,
        retryDelay: retryCount => retryCount * 1000,
    },
    PAYPAL_STANDARD: {
        sandbox: true,
        notify_url: '/api/interfaces/ipn',
        bn: 'PoweredNow_ECM',
    },
    SUMUP_ONLINE: {},
};

export default Object.keys(defaultConfig)
    .reduce((configObject, key) => Object.assign(configObject, {
        [key]: window[key] || defaultConfig[key],
    }), {}) as typeof defaultConfig;
