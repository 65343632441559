import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { omit } from 'lodash';
import clsx from 'clsx';
import Button from '../button/Button';

const getSizes = (theme, baseSize, margin) => ({
    width: theme.spacing(baseSize),
    height: theme.spacing(baseSize),
    minWidth: theme.spacing(baseSize),
    minHeight: theme.spacing(baseSize),
    margin: `0 ${theme.spacing(0.5 * margin)}`,
});

const useStyles = makeStyles<Theme>(theme => createStyles({
    container: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },

    small: getSizes(theme, 8.5, 1),
    medium: getSizes(theme, 9, 1.5),
    large: getSizes(theme, 10.5, 2),

    rounded: {
        borderRadius: '20%',
    },

    grayed: {
        border: `solid 1px ${theme.palette.type === 'dark' ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.15)'}`,
        color: theme.palette.type === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
    },

    noBackground: {
        backgroundColor: `${theme.palette.background.paper} !important`,
    },

    noBorder: {
        border: 'none',
    },
}));

export default function Pagination(props) {
    const classes = useStyles();
    const { items } = usePagination(props);

    const { size, emphasis, color } = props;

    const sizeValue = size || 'medium';

    const getSeparator = item => {
        const sizeClass = classes?.[sizeValue];
        const shapeClass = classes?.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;
        const classNames = clsx([sizeClass, shapeClass, classes.grayed, classes.noBackground, borderClass]);

        return (
            <Button endIcon={null} emphasis={emphasis} {...item} className={classNames} disabled>
                ...
            </Button>
        );
    };

    const getNavigationButtons = (type, item) => {
        const sizeClass = classes?.[sizeValue];
        const shapeClass = classes.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;
        const classNames = clsx([sizeClass, shapeClass, classes.grayed, classes.noBackground, borderClass]);

        return (
            <Button
                buttonType={color}
                emphasis={emphasis}
                className={classNames}
                {...item}
                startIcon={type === 'next' ? <ChevronRight /> : <ChevronLeft />}
            />
        );
    };

    const getButton = (item, page, selected) => {
        const sizeClass = classes?.[sizeValue];
        const shapeClass = classes?.rounded;
        const borderClass = emphasis === 'low' ? classes.noBorder : null;

        let classNames = clsx([sizeClass, shapeClass]);

        if (!selected) {
            classNames = clsx([classNames, classes.grayed, classes.noBackground, borderClass]);
        }

        return (
            <Button buttonType={color} endIcon={null} emphasis={emphasis} className={classNames} {...item}>{page}</Button>
        );
    };

    return (
        <Box display="flex" flexDirection="row" {...omit(props, ['siblingCount'])}>
            <Box display="flex" className={classes.container} flexDirection="row">
                {
                    items.map(({
                        page, type, selected, ...item
                    }, index) => {
                        let children: any;

                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = getSeparator(item);
                        } else if (type === 'page') {
                            children = getButton(item, page, selected);
                        } else {
                            children = getNavigationButtons(type, item);
                        }

                        // eslint-disable-next-line react/no-array-index-key
                        return <div key={index}>{children}</div>;
                    })
                }
            </Box>
        </Box>
    );
}
