import React, { useState } from 'react';
import {
    createStyles, Theme, makeStyles, withStyles,
} from '@material-ui/core/styles';
import MUIMenuItem, { MenuItemProps as MUIMenuItemProps } from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { omit, camelCase } from 'lodash';
import clsx from 'clsx';

const StyledMenuToggle = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '& *.MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
}))(MUIMenuItem);

const useStyles = makeStyles<Theme>(theme => createStyles({
    iconSmall: {
        minWidth: theme.spacing(7),
        '& svg': { fontSize: theme.spacing(4) },
    },
    iconMedium: {
        minWidth: theme.spacing(8),
        '& svg': { fontSize: theme.spacing(4.5) },
    },
    iconLarge: {
        minWidth: theme.spacing(9),
        '& svg': { fontSize: theme.spacing(5.5) },
    },

    textSmall: { '& .MuiTypography-root': { fontSize: theme.spacing(3) } },
    textMedium: { '& .MuiTypography-root': { fontSize: theme.spacing(3.5) } },
    textLarge: { '& .MuiTypography-root': { fontSize: theme.spacing(4) } },
}));

interface MenuToggleProps extends MUIMenuItemProps {
    className?: string,
    enabledTitle: string,
    disabledTitle: string,
    enabledIcon?: React.ReactNode,
    disabledIcon?: React.ReactNode,
    button?: true,
    defaultToggleButtonState: boolean,
    size?: 'small'| 'medium'| 'large',
    onToggled?: Function,
    onClose?: Function,
    icon?: React.ReactNode,
}

const MenuToggle = React.forwardRef<HTMLLIElement, MenuToggleProps>((props, ref) => {
    const {
        onToggled,
        enabledTitle,
        disabledTitle,
        enabledIcon,
        disabledIcon,
        defaultToggleButtonState,
        icon,
    } = props;
    const classes = useStyles();
    const itemProps = {
        ...omit(
            props, 
            'children', 
            'className',
            'icon', 
            'subTitle', 
            'defaultToggleButtonState', 
            'enabledTitle',
            'disabledTitle',
            'enabledIcon',
            'disabledIcon',
            'onToggled', 
            'onClick', 
            'className',
        ), 
    };
    const size = { small: 'small', medium: 'medium', large: 'large' }[props.size || 'medium'];
    const sizeClass = classes[size];
    const [toggleButtonState, setToggleButtonState] = useState(defaultToggleButtonState);
    const toggleButtonTitle = toggleButtonState ? enabledTitle : disabledTitle;
    const toggleIcon = (toggleButtonState ? enabledIcon : disabledIcon) || icon;

    const iconSizeClass = classes[camelCase(['icon', size].join('_'))];
    const textSizeClass = classes[camelCase(['text', size].join('_'))];
    
    const handleOnClick = () => {
        setToggleButtonState(!toggleButtonState);
        if (typeof onToggled !== 'undefined') {
            onToggled(toggleButtonState);
        }
    };

    return (
        <StyledMenuToggle
            {...itemProps}
            className={clsx(props.className, sizeClass)}
            onClick={handleOnClick}
        >
            {toggleIcon && <ListItemIcon className={iconSizeClass}>{toggleIcon}</ListItemIcon>}
            <ListItemText
                className={textSizeClass}
                primary={toggleButtonTitle}
            />
        </StyledMenuToggle>
    );
});

export default MenuToggle;
