import React, { useState } from 'react';
import PageBlock from '@components/layout/PageBlock';
import Mask from '@components/mask/Mask';
import { CircularProgress } from '@components/progress/Progress';
import SetNewPassword from '@features/setNewPassword/SetNewPassword';
import apiRequest from '@app/connection/apiRequest';
import { Box, Typography } from '@material-ui/core';
import { toast } from '@data/state/toast';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useComplexData from '@data/hooks/complexDataHook';
import Contact from '@powerednow/shared/modules/complexData/contact';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import ContactMethodEntity from '@powerednow/shared/modules/complexData/contactMethod/entity';
import { useNavigate } from 'react-router-dom';
import { authState, dataWrapper } from '@data/state/auth';
import { ifInstanceOf } from '@powerednow/shared/modules/typedCatch';
import { NetworkError, ServiceError } from '@powerednow/shared/error';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';

const useContactDetails = (contactId: number | null) => {
    const { details, optionalResult } = useComplexData<ContactEntity, {
        email: ModelFields<ContactMethodEntity>,
    }, Contact>(
        Contact,
        contactId,
        async (complexContact, resultSetter) => {
            const email = await complexContact.getEmail();
            resultSetter([complexContact, email], {
                email: email?.data.getPureDataValues(),
            });
        },
    );
    return {
        contactDetails: details,
        email: optionalResult?.email,
    };
};

const setUserToContact = async function (dataWrapperInstance, contactDetailsId, userToContact) {
    const contact = await dataWrapperInstance.getComplexDataObject(Contact, contactDetailsId);
    return contact.setUserToContact(userToContact);
};

export default function SetAccountPassword() {
    const authData = useRecoilValue(authState);
    const setToast = useSetRecoilState(toast);
    const dataWrapperInstance = useRecoilValue(dataWrapper);
    const navigate = useNavigate();
    const { contactDetails, email } = useContactDetails(authData?.data.contactId);
    const [saveInProgress, setSaveInProgress] = useState(false);

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: '',
    });

    if (!contactDetails) {
        return <CircularProgress color="primary" />;
    }

    const handleNewPassword = async () => {
        if (!email?.value) {
            setToast({ message: 'Email must be specified.', severity: 'error' });
            return;
        }
        setSaveInProgress(true);
        const hashedPassword = textUtils.hash(password.password);

        await apiRequest.register(authData.data, {
            password: hashedPassword,
            email: email.value,
            firstname: contactDetails.firstname,
            lastname: contactDetails.lastname,
        }).then(async (result: any) => {
            //
            // Make sure Contact knows the new user association
            //
            await setUserToContact(
                dataWrapperInstance,
                contactDetails.id,
                result.userToContact,
            );
            setToast({ message: 'Your account has been created' });
            navigate(`/portal/${authData.portalId}`);
        }).catch(ifInstanceOf(ServiceError, () => {
            setSaveInProgress(false);
            setToast({ message: 'It looks like you already have an account with us.', severity: 'error' });
        })).catch(ifInstanceOf(NetworkError, () => {
            setSaveInProgress(false);
            setToast({
                message: 'Your network seems to be down or Amazon did blow up and our servers are in the heaven not in the cloud anymore.',
                severity: 'error',
            });
        }));
    };

    const handleChange = event => {
        const { name, value } = event.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    return (
        <PageBlock alignItems="center" neutral title="Please set a password">
            <Mask show={saveInProgress} overflow={10} position="relative" maxWidth={600} p={2} content={<CircularProgress />}>
                To secure your account, please set a password
                <br />
                <br />
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flex={1} flexDirection="column">
                        <Box>
                            <Typography variant="h5">
                                {email?.value}
                            </Typography>
                        </Box>
                    </Box>
                    <SetNewPassword
                        onSetNewPasswordButtonClicked={handleNewPassword}
                        onPasswordFieldChange={handleChange}
                        password={password.password}
                        confirmPassword={password.confirmPassword}
                        savePasswordButtonText="Create account"
                    />
                </Box>
            </Mask>
        </PageBlock>
    );
}
