import React from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import ForgottenPassword from '@pages/password/ForgottenPassword';
import ResetPassword from '@pages/password/ResetPassword';

export const baseRoute = 'resetpassword';

export default function PasswordRoutes() {
    return (
        <Routes>
            <Route
                path=":passwordReminderHash/:redirectUrl"
                element={<ForgottenPassword />}
            />
            <Route
                path="/"
                element={<ResetPassword />}
            />
        </Routes>
    );
}
