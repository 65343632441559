import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewAccountDetails from '@pages/customerPortal/account/viewAccountDetails/viewAccountDetails';
import SetAccountPassword from './setAccountPassword/setAccountPassword';

export const baseRoute = '/account';

export default function AccountRoutes() {
    return (
        <Routes>
            <Route path="viewDetails" element={<ViewAccountDetails />} />
            <Route path="setPassword" element={<SetAccountPassword />} />
        </Routes>
    );
}
