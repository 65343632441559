import React from 'react';
import TextField, { TextFieldProps } from '@components/fields/TextField';
import { useController, useFormContext } from 'react-hook-form';
import { omit } from 'lodash';

interface ControlledTextFieldProps extends TextFieldProps{
    name: string
    type?: string
    rules: {}
}

function ControlledTextField({
    name, type = 'text', rules, ...rest
}: ControlledTextFieldProps) {
    const { control } = useFormContext();
    const {
        field: {
            onChange,
            onBlur,
            value,
            ref,
            name: controlledName,
        },
        formState: {
            errors,
        },
    } = useController({
        name,
        control,
        rules,
        defaultValue: '',
    });

    return (
        <TextField
            type={type}
            onBlur={onBlur}
            name={name}
            onChange={onChange}
            value={value}
            inputRef={ref}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            {...omit(rest, ['onChange', 'onBlur', 'value', 'ref', 'name'])}
        />
    );
}

export default ControlledTextField;
