import React from 'react';
import AccountDetails from '@features/contact/accountDetails/accountDetails';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ViewAccountDetails() {
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <AccountDetails />
    );
}
