import { atomFamily } from 'recoil';

export type PagerIdentifier = {
    name: string,
    id: number,
    default?: number
}

export const pagerIndex = atomFamily<number, PagerIdentifier>({
    key: 'pagerIndex',
    default: param => (!Number.isNaN(Number(param.default)) ? param.default! : 1),
});
