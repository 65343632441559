import React from 'react';
import DownloadButton from '@features/buttons/DownloadButton';
import { useNavigateFormMessage } from '@data/hooks/navigationHook';
import PortalSendMessageButton from '@features/buttons/PortalSendMessageButton';
import ColumnComponent from '@components/layout/ColumnComponent';
import ItemActionSection from '@components/ItemInformations/itemActionSection';

export type FormActionsProps = {
    downloadUrl: string,
    certificateName: string,
    formId: number,
}

function FormActions({
    downloadUrl, 
    certificateName, 
    formId,
}: FormActionsProps) {
    const { navigateHandler, loaded } = useNavigateFormMessage(formId);
    return (
        <ItemActionSection>
            <ColumnComponent>
                <DownloadButton url={downloadUrl} fileName={certificateName} />
                <PortalSendMessageButton onClick={navigateHandler} loaded={loaded} />
            </ColumnComponent>
        </ItemActionSection>
    );
}

export default FormActions;
