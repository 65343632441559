import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { authState } from '@data/state/auth';
import { baseRoute as messageBase, viewRoute, composeRoute } from '@pages/customerPortal/message/messageRoutes';
import { mainRoute } from '@pages/customerPortal/customerPortalRoutes';
import {
    useMessageLoader, useDocumentMessage, useAppointmentMessage, useFormMessage,
} from '@data/hooks/messageHook';

type RouteLoaderResult = {
    loaded: boolean,
    subRouteGenerator: () => string,
};
type NavigationResult = {
    loaded: boolean,
    navigateHandler: React.MouseEventHandler,
};

type NavigationHandler = (complexInstanceId?: number, dataLoader?, authData?) => NavigationResult;
type SubRouteLoader = (complexInstanceId?: number, dataLoader?, authData?) => RouteLoaderResult;
type PortalNavigator = (customerPortalPage: string, subRouteLoader: RouteLoaderResult) => NavigationResult;

export const usePortalNavigator: PortalNavigator = (customerPortalPage, subRouteLoader) => {
    const authData = useRecoilValue(authState);
    const { loaded, subRouteGenerator } = subRouteLoader;
    const subRoute = subRouteGenerator();
    const navigate = useNavigate();
    const baseRoute = `${mainRoute}/${authData.portalId}/`;
    return {
        loaded,
        navigateHandler: useCallback(() => {
            navigate(`${baseRoute}${customerPortalPage}/${subRoute}`);
        }, [customerPortalPage, baseRoute]),
    };
};

const useMessageSubRouteGenerator: SubRouteLoader = (complexInstanceId, messageLoader): RouteLoaderResult => {
    const { messageId, loaded } = useMessageLoader(complexInstanceId, messageLoader);
    const subRouteGenerator = useCallback(() => {
        if (messageId) {
            return `${messageId}/${viewRoute}`;
        }
        return composeRoute;
    }, [messageId]);
    return {
        loaded,
        subRouteGenerator,
    };
};

const useNavigateGenericMessage: NavigationHandler = (complexInstanceId, messageLoader) => {
    const subRoute = useMessageSubRouteGenerator(complexInstanceId, messageLoader);
    return usePortalNavigator(messageBase, subRoute);
};

export const useNavigateAppointmentMessage: NavigationHandler = actionId => useNavigateGenericMessage(actionId, useAppointmentMessage);
export const useNavigateDocumentMessage: NavigationHandler = documentId => useNavigateGenericMessage(documentId, useDocumentMessage);
export const useNavigateFormMessage: NavigationHandler = formId => useNavigateGenericMessage(formId, useFormMessage);
