import React from 'react';
import {
    Typography,
    Box,
    BoxProps,
} from '@material-ui/core';
import { RequireAtLeastOne } from '@powerednow/type-definitions';
import { useResponsive, BreakpointMap } from '../hooks/responsive';

export interface LabelledBoxProps extends BoxProps {
    children?: React.ReactNode,
    styles?: any,
    title: string,
    hide?: boolean,
    labelPosition?: 'top'| 'left'| 'bottom'| 'right',
    labelAlign?: 'left' | 'center' | 'right' | 'justify' | 'inherit',
    responsiveProps?: RequireAtLeastOne<BreakpointMap, keyof BreakpointMap>,
}

export default function LabelledBox(props: LabelledBoxProps) {
    const responsive = useResponsive();
    const {
        title,
        children,
        hide,
        labelPosition = 'top',
        labelAlign = 'inherit',
        ...boxProps
    } = props;
    const alignment = { flexDirection: 'row' };

    if (labelPosition === 'top') {
        alignment.flexDirection = 'column';
    } else if (labelPosition === 'bottom') {
        alignment.flexDirection = 'column-reverse';
    } else if (labelPosition === 'left') {
        alignment.flexDirection = 'row';
    } else if (labelPosition === 'right') {
        alignment.flexDirection = 'row-reverse';
    }

    return (
        <Box
            display={hide ? 'none' : 'flex'}
            mt={responsive({ xs: 2, md: 6 })}
            flexDirection={alignment.flexDirection}
        >
            {title && (
                <Typography align={labelAlign} variant="h6">
                    {title}
                </Typography>
            )}
                
            <Box
                pl={title && responsive({ xs: 0, md: 10 })}
                flex={1}
                {...boxProps}
            >
                {children}
            </Box>
        </Box>
    );
}

export function ResponsiveLabelledBox({
    title,
    styles,
    children,
    labelAlign,
    responsiveProps = { xs: 'top', sm: 'left' },
    ...props
}: LabelledBoxProps) {
    const responsive = useResponsive();

    return (
        <LabelledBox
            title={title}
            labelPosition={responsive(responsiveProps)}
            labelAlign={labelAlign}
            styles={styles}
            {...props}
        >
            {children}
        </LabelledBox>
    );
}
