import LabelledBox from '@components/LabelledBox/LabelledBox';
import React, { useEffect } from 'react';
import Select from '@components/fields/Select';
import { useResponsive } from '@data/hooks/responsive';
import useComplexData from '@data/hooks/complexDataHook';
import CustomerEntity from '@powerednow/shared/modules/complexData/customer/entity';
import JobEntity from '@powerednow/shared/modules/complexData/job/entity';
import Customer from '@powerednow/shared/modules/complexData/customer';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import Job from '@powerednow/shared/modules/complexData/job';
import { CircularProgress } from '@components/progress/Progress';
import { displayProjectsOnPortal } from '@data/state/companySettings';
import { Typography } from '@material-ui/core';
import htmlDecode from '@components/helper/htmlDecode';
import parse from 'html-react-parser';

type ProjectSelectorProps = {
    selectedProjectId: number,
    onChange: (_projectId: number) => void,
    filter?: (_job: Job) => boolean,
}

type JobsData = Partial<Pick<JobEntity, 'id' | 'status' | 'description'>>;

type ProjectsResult = {
    loaded: boolean,
    list: JobsData[] | [],
    key: string,
};

const NONE_OF_ABOVE = -2;
const NOT_SELECTED = -1;

export const useProjects = (customerId, filter) => {
    const { optionalResult } = useComplexData<CustomerEntity, ProjectsResult, Customer>(
        Customer,
        customerId,
        async (complexCustomer, resultSetter) => {
            const jobInstances = await complexCustomer.getAllJob();
            const openJobsData = jobInstances
                .filter(filter)
                .map(job => job.data.getPureDataValues());
            const openJobsRequiredData = openJobsData.map(jobData => {
                const { id, status, description } = jobData;
                return {
                    id,
                    status,
                    description,
                };
            });

            const key = openJobsRequiredData.map(openJobRequiredData => String(openJobRequiredData.id)).join('-');

            resultSetter(complexCustomer, {
                loaded: true,
                list: openJobsRequiredData,
                key,
            });
        },
    );

    return optionalResult;
};

export default function ProjectSelector({
    selectedProjectId,
    onChange,
    filter,
}: ProjectSelectorProps) {
    const authData = useRecoilValue(authState);
    const { data: { customerId } } = authData;
    const displayProjects = useRecoilValue(displayProjectsOnPortal);

    const projects = useProjects(customerId, filter);
    const filteredProjectItems = projects?.list || [];

    const responsive = useResponsive();

    useEffect(() => {
        if (filteredProjectItems.length === 0 && projects?.loaded) {
            onChange(NONE_OF_ABOVE);
        }

        if (filteredProjectItems.length === 1 && filteredProjectItems[0].id && selectedProjectId === NOT_SELECTED && projects?.loaded) {
            onChange(filteredProjectItems[0].id);
        }
    }, [filteredProjectItems, selectedProjectId]);

    if (!displayProjects) {
        return null;
    }

    if (!projects?.loaded) {
        return <CircularProgress />;
    }

    const selector = (
        <Select
            items={[
                ...(selectedProjectId === NOT_SELECTED ? [{ description: '- Please choose associated project -', id: NOT_SELECTED }] : []),
                ...filteredProjectItems.map(filteredProjectItem => ({
                    id: filteredProjectItem.id,
                    description: <Typography noWrap>{parse(filteredProjectItem.description ?? '')}</Typography>,
                })),
                { description: '- None of the above -', id: NONE_OF_ABOVE },
            ]}
            displayProperty="description"
            value={selectedProjectId}
            fullWidth
            onChange={e => {
                const value: string = e.target.value as string;
                onChange(parseInt(value, 10));
            }}
        />
    );

    return filteredProjectItems.length === 0 ? null : (
        <LabelledBox
            labelPosition={responsive({ xs: 'top', md: 'left' })}
            title="What project is this associated with?"
        >
            {selector}
        </LabelledBox>
    );
}

ProjectSelector.defaultProps = {
    filter: () => true,
};
