import React, { RefObject } from 'react';
import MUIMenuItem, { MenuItemProps as MUIMenuItemProps } from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
    createStyles, withStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import { omit, camelCase } from 'lodash';
import { emitCustomEvent } from 'react-custom-events';

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '& *.MuiButton-label': {
            justifyContent: 'flex-start',
        },
    },
}))(MUIMenuItem);

const useStyles = makeStyles<Theme>(theme => createStyles({
    iconSmall: {
        minWidth: theme.spacing(7),
        '& svg': { fontSize: theme.spacing(4) },
    },
    iconMedium: {
        minWidth: theme.spacing(8),
        '& svg': { fontSize: theme.spacing(4.5) },
    },
    iconLarge: {
        minWidth: theme.spacing(9),
        '& svg': { fontSize: theme.spacing(5.5) },
    },

    textSmall: { '& .MuiTypography-root': { fontSize: theme.spacing(3) } },
    textMedium: { '& .MuiTypography-root': { fontSize: theme.spacing(3.5) } },
    textLarge: { '& .MuiTypography-root': { fontSize: theme.spacing(4) } },
}));

interface MenuItemProps extends MUIMenuItemProps {
    size?: 'small'| 'medium'| 'large',
    onClose?: Function,
    icon?: any,
    subTitle?: string,
    button?: true
}

const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>((props, ref) => {
    const classes = useStyles();
    const itemProps = { ...omit(props, 'children', 'icon', 'subTitle', 'onClick', 'className') };
    const size = { small: 'small', medium: 'medium', large: 'large' }[props.size || 'medium'];
    const sizeClass = classes[size];
    const iconSizeClass = classes[camelCase(['icon', size].join('_'))];
    const textSizeClass = classes[camelCase(['text', size].join('_'))];

    const extendedChildren = React.Children.map(props.children, (child, ind) => {
        if (React.isValidElement(child)) {
            const childProps = {
                color: props.color, size, onClose: props.onClose, key: `${props.key}_${{ ind }}`,
            };
            return React.cloneElement(child, { ...childProps });
        }
        return child;
    });

    return (
        <StyledMenuItem
            {...itemProps}
            className={clsx(props.className, sizeClass)}
            tabIndex={0}
            ref={ref}
            onClick={e => {
                if (props.onClick) props.onClick(e);
                emitCustomEvent('closeMenu');
            }}
        >
            {props.icon && <ListItemIcon className={iconSizeClass}>{props.icon}</ListItemIcon>}
            <ListItemText
                className={textSizeClass}
                primary={extendedChildren}
                secondary={props.subTitle}
            />
        </StyledMenuItem>
    );
});

export default MenuItem;
