import React from 'react';
import { useRecoilValue } from 'recoil';
import useComplexData from '@data/hooks/complexDataHook';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import type ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import Contact from '@powerednow/shared/modules/complexData/contact';
import PaymentsForCustomerEntity from '@powerednow/shared/modules/complexData/paymentsForCustomer/entity';
import PaymentsForCustomer from '@powerednow/shared/modules/complexData/paymentsForCustomer';
import paymentListColumnConfig from '@features/payment/list/paymentListColumnConfig';
import DataGridTabList, { ComplexTabListItems, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import type PaymentEntity from '@powerednow/shared/modules/complexData/payment/entity';
import { displayProjectsOnPortal } from '@data/state/companySettings';

export type PaymentDataType = ModelFields<PaymentsForCustomerEntity>;

const usePayments = (params: TabListItemsParams<PaymentEntity>): ComplexTabListItems<PaymentEntity> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
    } = params;
    const { optionalResult, error } = useComplexData<ContactEntity, {
        listItems: PaymentDataType[],
        loaded: boolean,
        total: number,
        error: any,
    }, Contact>(
        Contact,
        [contactId, pageSize, skip, sorters],
        async (complexContact, resultSetter) => {
            const complexCustomer = await complexContact.getCustomer();
            const total = await complexCustomer.getPaymentCount();
            const complexPayments: PaymentsForCustomer[] = await complexCustomer.getAllPayment({
                skip,
                limit: pageSize,
                sorters,
            });
            resultSetter(complexContact, {
                total,
                loaded: true,
                listItems: complexPayments.map(payment => payment.data.getPureDataValues()),
            });
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

export default function PaymentList() {
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_created', sort: 'desc' } as const,
    ];

    const displayProjects = useRecoilValue(displayProjectsOnPortal);
    const columns = paymentListColumnConfig(displayProjects);

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={usePayments}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText="Sorry, there are no payments to display"
            tabToNavigate="payment"
        />
    );
}
