import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import Container, { ContainerProps } from '@material-ui/core/Container';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface BasePageProps {
    pt?: BoxProps['pt']
    pr?: BoxProps['pr']
    pb?: BoxProps['pb']
    pl?: BoxProps['pl']
    paperElevation?: PaperProps['elevation']
    contentBackground?: BoxProps['bgcolor']
    containerMaxWidth?: ContainerProps['maxWidth'],
    children: React.ReactNode
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        backgroundColor: theme.palette.backgroundColor,
    },
}));

function BasePage({
    containerMaxWidth, pt, pr, pb, pl, children, paperElevation, contentBackground,
} : BasePageProps) {
    const classes = useStyles();

    return (
        <Box
            pt={pt}
            pr={pr}
            pb={pb}
            pl={pl}
            className={classes.root}
        >
            <Container maxWidth={containerMaxWidth}>
                <Paper elevation={paperElevation}>
                    <Box bgcolor={contentBackground}>
                        {children}
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}

export default BasePage;
