import React, { useState, useEffect } from 'react';
//
// Extension of the default useState function to allow for a dependency array
// to be passed in. This will cause the state to be reset to the default value
// when any of the dependencies change.
//
export default function useDependantState<T>(defaultValue: T, dependencies: any[]): [T, (_a: T) => void] {
    const [state, setState] = useState<T>(defaultValue);
    useEffect(() => {
        setState(defaultValue);
    }, dependencies);
    return [state, setState];
}
