import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { format, patterns } from '@powerednow/shared/modules/utilities/date';
import CustomerMessage from '@powerednow/shared/modules/complexData/customerMessage';
import { ModelCreationFields } from '@powerednow/shared/modules/complexData/entity';
import CustomerMessageEntity from '@powerednow/shared/modules/complexData/customerMessage/entity';
import useComplexData from '@data/hooks/complexDataHook';
import usePrompt from '@data/hooks/promptHook';
import xTemplateFunc from '@powerednow/shared/modules/utilities/xTemplate';
import MessageRecipient, { MessageTemplateValues } from '@powerednow/shared/modules/complexData/messageRecipient';
import { ChannelTypes } from '@powerednow/shared/constants/customerEmailTemplateValues';
import ChannelIndicator from '@components/channelIndicator/ChannelIndicator';
import htmlDecode from '@components/helper/htmlDecode';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import parse from 'html-react-parser';

interface Props {
    messageData: ModelCreationFields<CustomerMessageEntity>
}

interface MessageViewItemStyles {
    isSender: boolean,
}

const useStyles = makeStyles<Theme, MessageViewItemStyles>(theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: ({ isSender }) => (isSender ? 'flex-start' : 'flex-end'),
    },
    dateOfCreationContainer: {
        display: 'flex',
        fontSize: 12,
        fontStyle: 'italic',
    },
    dateOfCreationText: {
        alignSelf: 'center',
    },
    messageBox: ({ isSender }) => ({
        ...(isSender ? {
            backgroundColor: theme.palette.senderColors.main,
            color: theme.palette.senderColors.light,
        } : {
            backgroundColor: theme.palette.receiverColors.main,
            color: theme.palette.receiverColors.light,
        }),
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        boxShadow: '1px 1px 2px #999',
        maxWidth: '75%',
        borderRadius: 8,
    }),
}));

interface CustomerMessageResults {
    recipients: MessageRecipient[],
    displayMessage: MessageTemplateValues | null,
    loaded: boolean
    error?: Error | undefined
    channels: ChannelTypes[]
}

const useCustomerMessage = (messageId: number | undefined, currentChannel: ChannelTypes | undefined): CustomerMessageResults => {
    const L = usePrompt();
    const xTemplate = xTemplateFunc({ LSync: L });
    const {
        optionalResult,
        error,
    } = useComplexData<CustomerMessageEntity, CustomerMessageResults, CustomerMessage>(
        CustomerMessage,
        [messageId, currentChannel],
        async (complexMessage, resultSetter) => {
            const recipients = await complexMessage.getAllMessageRecipient();
            const displayRecipient = await complexMessage.getRecipientForChannel(currentChannel || 'EMAIL');
            const displayMessage = await displayRecipient?.getRenderedContent(xTemplate.renderBySource);
            const channels = recipients.map(recipient => recipient.getChannelType());
            resultSetter(
                recipients,
                {
                    loaded: true,
                    recipients,
                    displayMessage,
                    channels,
                },
            );
        },
    );

    return {
        channels: optionalResult?.channels || [],
        recipients: optionalResult?.recipients || [],
        displayMessage: optionalResult?.displayMessage || null,
        loaded: optionalResult?.loaded || false,
        error,
    };
};

function MessageDateOfCreationText({
    dt_created, channels, classes, isSender, handleChannelChange, 
}) {
    const timeZoneShift = new Date().getTimezoneOffset() / -60;
    const formattedDate = format(dt_created, patterns.messageDateTime);
    const createdAt = new Date(formattedDate);

    createdAt.setHours(createdAt.getHours() + timeZoneShift);
    const createdAtStr = `${format(createdAt, patterns.messageDateTime)}`;
    return (
        <Box pr={2} className={classes.dateOfCreationContainer}>
            <ChannelIndicator channels={channels} onChannelIconClick={handleChannelChange} />
            <small className={classes.dateOfCreationText}>
                {isSender ? 'received' : 'sent'}
                {' at '}
                {createdAtStr}
            </small>
        </Box>
    );
}

export default function MessageViewItem(props: Props) {
    const [currentChannel, setCurrentState] = useState<ChannelTypes>();

    const { messageData } = props;
    const { direction, dt_created } = messageData;
    const isSender = (direction === 1);
    const classes = useStyles({ isSender });
    const { channels, displayMessage } = useCustomerMessage(messageData.id, currentChannel);

    if (!messageData || !displayMessage?.message) {
        return (
            <Box className={classes.root}>
                <Skeleton width={168.48} height={36.02} />
                <Skeleton width={128.48} height={14.3} />
            </Box>
        );
    }

    const processSubjectAndMessage = (subjectAndMessage: MessageTemplateValues | null) => {
        return {
            subjectText: subjectAndMessage?.subject && (
                <>
                    <b>{parse(subjectAndMessage.subject)}</b>
                    <br />
                </>
            ),
            messageText: subjectAndMessage?.message && parse(subjectAndMessage.message),
        };
    };

    const handleChannelChange = channel => setCurrentState(channel);  
    const { subjectText, messageText } = processSubjectAndMessage(displayMessage);

    return (
        <Box mt={1} className={classes.root}>
            <Box p={2} className={classes.messageBox}>
                {subjectText}
                {messageText}
            </Box>
            <MessageDateOfCreationText
                dt_created={dt_created}
                channels={channels}
                classes={classes}
                isSender={isSender}
                handleChannelChange={handleChannelChange}
            />
        </Box>
    );
}
