import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSimpleComplexData } from '@data/hooks/complexDataHook';
import FormDocument from '@powerednow/shared/modules/complexData/formDocument';
import { authState } from '@data/state/auth';
import { useRecoilValue } from 'recoil';
import config from 'src/config';
import generateDocumentUrlUuidString from '@powerednow/shared/modules/utilities/generateDocumentUrlUuidString';
import DocumentContainer from '@features/document/DocumentContainer';
import DocumentBlock from '@features/document/DocumentBlock';
import DocumentWithPreview from '@features/document/DocumentWithPreview';
import { getFormattedDate } from '@powerednow/portal/src/helper/date';
import FormActions from '@features/form/FormActions';

type FormDetailsProps = {
  documentId: number;
};

export default function FormDetails(props: FormDetailsProps) {
    const { documentId } = props;
    const formDocumentData = useSimpleComplexData(FormDocument, documentId);
    const {
        display_number: documentDisplayNumber,
        uuid,
        form_data,
    } = formDocumentData || {};
    const { form } = form_data || {};
    const formDocumentPrefix = 'You have received a Certificate';
    const { certificateName, certConfig, issueDate } = form || {};
    const certName = certificateName || certConfig?.certificateName;
    const formattedIssueDate = getFormattedDate(issueDate);
    const dateOfIssueText = formattedIssueDate && `Date of Issue - ${formattedIssueDate}`;

    const formTitle = `${formDocumentPrefix}, ${certName}`;
    const authData = useRecoilValue(authState);
    const {
        data: { companyId },
    } = authData;

    const formattedFormDocumentId = generateDocumentUrlUuidString(
        uuid,
        companyId,
    );
    const mediaUrl = `${config.API_URL}/formDocument/view/${formattedFormDocumentId}/`;
    const downloadUrl = `${config.API_URL}/document/pdf/attachment/${formattedFormDocumentId}/formDocument`;

    if (!formDocumentData) {
        return <CircularProgress />;
    }

    return (
        <DocumentContainer>
            <DocumentBlock status="" title={formTitle} dateOfIssue={dateOfIssueText}>
                <FormActions
                    downloadUrl={downloadUrl}
                    certificateName={certName}
                    formId={documentId}
                />
            </DocumentBlock>

            <DocumentWithPreview
                description={`${certName}: ${documentDisplayNumber}`}
                mediaUrl={mediaUrl}
                previewUrl={mediaUrl}
            />
        </DocumentContainer>
    );
}
