import {
    atom, atomFamily, selector, selectorFamily, 
} from 'recoil';
import ApiRequest from '@app/connection/apiRequest';
import ComplexData from '@powerednow/shared/modules/complexData/complexData';
import { EntityWithId, ModelFields } from '@powerednow/shared/modules/complexData/entity';
import { Newable, PropType } from '@powerednow/type-definitions';
import PaymentType from '@powerednow/shared/modules/complexData/paymentType';

type ComplexDataConstructor = Newable<ComplexData<any>> & Omit<typeof ComplexData, 'prototype'>;

type GlobalCacheMap<T extends ComplexDataConstructor> = Map<T, ModelFields<PropType<InstanceType<T>, '_entity'>>[]>

const globalData = selectorFamily({
    key: 'globalData',
    get: (tableName: string) => async () => {
        try {
            return ApiRequest.requestGlobalTable(tableName);
        } catch {
            return [];
        }
    },
});

const paymentTypes = atom<ModelFields<PropType<PaymentType, '_entity'>>[]>({
    key: 'globalData/paymentTypes',
    default: selector({
        key: 'globalData/paymentTypes/Default',
        get: async ({ get }) => get(globalData('PaymentType')),
    }),
});

const paymentType = selectorFamily<ModelFields<PropType<PaymentType, '_entity'> > | undefined, number>({
    key: 'globalData/paymentTypes',
    get: (id: number) => async ({ get }) => {
        const types = get(paymentTypes);
        return types.find(item => item.id === id);
    },
});

export { globalData, paymentTypes, paymentType };
