import React from 'react';
import {
    withStyles, createStyles, makeStyles, Theme, 
} from '@material-ui/core/styles';
import MUIMenu, { MenuProps } from '@material-ui/core/Menu';
import { omit } from 'lodash';
import { useCustomEventListener } from 'react-custom-events';
import Button, { ButtonProps } from '../button/Button';

const useStyles = makeStyles<Theme>(theme => createStyles({
    accountButton: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'initial',

        [theme.breakpoints.up('xs')]: {
            height: '50.25px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '56px',
        },
    },
}));

const StyledMenu = withStyles(theme => ({
    paper: {
        border: `1px solid ${theme.palette.action.active}`,
        marginTop: 4,
        '&>ul': {
            paddingBottom: '0',
            paddingTop: '0',
        },
    },

}))((props: MenuProps) => (
    <MUIMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export interface MenuButtonProps extends ButtonProps {
    text?: string;
}

const MenuButton = React.forwardRef((props : MenuButtonProps, ref) => {
    const text = props.text || 'Open Menu';
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useCustomEventListener('closeMenu', handleClose);

    return (
        <div>
            <Button
                {...omit(props, 'text')}
                onClick={handleClick}
                className={classes.accountButton}
            >
                {text}
            </Button>
            <StyledMenu
                ref={ref}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.children}
            </StyledMenu>
        </div>
    );
});

export default MenuButton;
