import * as Bluebird from 'bluebird';

import DependencyOrderMap from './dependencyOrderMap';
import { DependencyResolver as DependencyResolverTypes } from './types';

export default class DependencyResolver<T> implements DependencyResolverTypes.Resolver<T> {
    private idMap: object = {};

    private originalItem: symbol = Symbol('originalItem');

    private adapter: DependencyResolverTypes.ORMAdapter<T>;

    constructor(adapter: DependencyResolverTypes.ORMAdapter<T>) {
        this.adapter = adapter;
    }

    public resolve({ items, rootNode = '', sourceEntryFilter }: {
        items: DependencyResolverTypes.Item<T>[],
        rootNode: string,
        sourceEntryFilter: DependencyResolverTypes.EntryFilter,
    }) {
        const itemsToProccess = items.map(item => {
            const itemToProcess = rootNode === '' ? item : item[rootNode];
            itemToProcess[this.originalItem] = item;
            return itemToProcess;
        });

        const dependencyOrderMap = new DependencyOrderMap<T>(itemsToProccess, {
            sourceEntryFilter,
        });
        const overallOrderGroups: DependencyResolverTypes.Item<T>[][] = dependencyOrderMap.generate();

        return Bluebird.reduce(overallOrderGroups, async (result, group) => {
            const savedItems = await this.saveItems(group);
            return [...result, ...savedItems];
        }, []);
    }

    private saveItems(items: DependencyResolverTypes.Item<T>[]) {
        return this.adapter.save(...items.map((item): { item: DependencyResolverTypes.Item<T>, postProcess: Function } => ({
            item: this.replaceItemKeys(item)[this.originalItem],
            postProcess: this.createSavedItemHandler(item.id, item),
        })));
    }

    private createSavedItemHandler(oldId: DependencyResolverTypes.NodeID, item): (savedItem: DependencyResolverTypes.Item<T>) => DependencyResolverTypes.Item<T> {
        return savedItem => {
            if (oldId !== savedItem.id && 'id' in savedItem) {
                this.idMap[oldId] = savedItem.id;
                item.id = savedItem.id;
            }
            //
            // copy values from savedItem to item but only the ones existing in item
            // this is needed because savedItem might have props that are not in item (e.g. ext model related ones)
            //
            return Object.keys(item).reduce((replacedItem, key) => {
                replacedItem[key] = savedItem[key];
                return replacedItem;
            }, {} as DependencyResolverTypes.Item<T>);
        };
    }

    private replaceItemKeys(item): DependencyResolverTypes.Item<T> {
        return <DependencyResolverTypes.Item<T>>Object.entries(item)
            .reduce((replacedItem, [key, value]) => {
                const newValue = (typeof value === 'symbol' && this.idMap[<any>value]) || value;
                return Object.assign(replacedItem, { [key]: newValue });
            }, item);
    }
}
