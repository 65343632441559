import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useSetSelectedTab from '@data/hooks/useSetSelectedTab';
import { TABS } from '@data/state/selectedTab';
import ViewForm from './viewForm/viewForm';

export const baseRoute = 'form';

export default function FormRoutes() {
    useSetSelectedTab(TABS.Form);
    return (
        <Routes>
            <Route path=":id/view" element={<ViewForm />} />
        </Routes>
    );
}
