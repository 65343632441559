import React from 'react';
import DocumentDetails from '@features/document/documentDetails';
import { useParams } from 'react-router-dom';
import useSetFAQS from '@data/hooks/useSetFAQS';

export default function ViewDocument() {
    const { id } = useParams<'id'>();
    useSetFAQS(['findDocument', 'contactYou']);

    return (
        <DocumentDetails documentId={parseInt(id!, 10)} />
    );
}
