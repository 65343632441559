import React from 'react';
import Badge from '@components/Badge/Badge';
import type { BadgeEntity } from '@features/appointment/badge/AppointmentBadge';

const upcomingBadgeData : BadgeEntity = {
    badgeContent: 'UPCOMING',
    badgeColor: 'error',
};

export default function UpcomingBadge() {
    const { badgeContent, badgeColor } = upcomingBadgeData;
    return (
        <Badge
            size="large"
            content={badgeContent}
            color={badgeColor}
        />
    );
}
