import React from 'react';
import { Box } from '@material-ui/core';
import { useResponsive } from '../hooks/responsive';
import ComponentMatrix from '../layout/ComponentMatrix';

export type LabelledTextProps = {
    label: string | undefined
    text: string | undefined
}
export default function LabelledText({ label, text }: LabelledTextProps) {
    const responsive = useResponsive();
    return (
        <ComponentMatrix columns={2} spacing={5}>
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
            >
                {label}
                :
            </Box>
            <Box
                ml={responsive({ xs: '-50px', sm: '0' })}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {text}
            </Box>
        </ComponentMatrix>
    );
}
