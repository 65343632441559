import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ColorTypes } from '../button/ColoredButton';
import Mask from './Mask';
import Progress from '../progress/Progress';

type ComponentMaskProps = {
    children: React.ReactNode,
    showMask?: boolean,
    color?: ColorTypes
    fullWidth?: boolean,
};

const useStyles = makeStyles(theme => ({
    root(props: { fullWidth?: boolean }) {
        const { fullWidth } = props;
        return {
            ...(fullWidth ? {
                flex: 1,
            } : {
                display: 'inline-block',
            }),
            position: 'relative',
        };
    },
    maskStyle: {
        width: '100%',
    },
}));

export default function ComponentMask({
    children, showMask, color, fullWidth,
}: ComponentMaskProps) {
    const classes = useStyles({
        fullWidth,
    });
    return (
        <Box className={classes.root}>
            <Mask
                className={classes.maskStyle}
                blur={2}
                backgroundColor="white"
                opacity={0.5}
                show={showMask}
                content={(
                    <Progress
                        type="circular"
                        size="large"
                        color={color}
                    />
                )}
            >
                <Box className={classes.root}>
                    { children }
                </Box>
            </Mask>
        </Box>

    );
}
