import React from 'react';
import { Box, Typography, Container } from '@material-ui/core';

interface Props {
    errorCode?: number | string
    children?: React.ReactElement | string
}

export default function ErrorPage(props: Props) {
    let content:null | string | React.ReactElement = null;
    const { errorCode, children } = props;

    if (typeof children === 'string') {
        content = (
            <Typography variant="body1" align="center">
                {children}
            </Typography>
        );
    }

    if (children && typeof children === 'object') {
        content = children;
    }

    return (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center" flexDirection="column" p={12}>
            <Container maxWidth="md">
                <Typography variant="body1" align="center">error:</Typography>

                <Typography variant="h1" align="center">
                    {errorCode === undefined ? 404 : errorCode}
                </Typography>

                { content }
            </Container>
        </Box>
    );
}
