import React from 'react';
import { Box } from '@material-ui/core';

import {
    isOnSameDay, isSameMonth,
} from '@powerednow/shared/modules/utilities/date';
import Button from '../button/Button';
import { ColorTypes } from '../button/ColoredButton';

interface DayProps {
    selected?: boolean
    day: Date | null
    calendarDate: Date
    onClick?: Function
    color?: ColorTypes
    disabled?: boolean
}

export default function CalendarDay(props: DayProps) {
    const {
        selected, day, calendarDate, onClick, color, disabled,
    } = props;
    const isToday = isOnSameDay(new Date(), day);

    return (
        <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
        >
            <Button
                emphasis={selected ? 'high' : 'low'}
                buttonType={selected ? color : 'none'}
                onClick={onClick ? () => onClick(day) : undefined}
                endIcon={null}
                disabled={!isSameMonth(day, calendarDate) || disabled}
                style={{
                    // borderRadius: '50%',
                    width: '35px',
                    height: '35px',
                    padding: 0,
                    fontWeight: isToday ? 'bold' : 'normal',
                    border: isToday ? 'solid 1px silver' : 'initial',
                }}
            >
                {day?.getDate()}
            </Button>
        </Box>
    );
}
