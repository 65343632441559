import useComplexData from '@data/hooks/complexDataHook';
import Company from '@powerednow/shared/modules/complexData/company';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';

type LocalDateFormatResult = {
    loaded: boolean,
    localDateFormat: string,
}

const useLocalDateFormat = (companyId: number | null) => {
    const { optionalResult } = useComplexData<CompanyEntity, LocalDateFormatResult, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const companyAddress = await complexCompany.getCompanyAddress();
            const country = await companyAddress.getCountry();
            const localDateFormat = country.data.date_format;

            resultSetter(complexCompany, {
                loaded: true,
                localDateFormat,
            });
        },
    );

    return optionalResult;
};

export default useLocalDateFormat;
