import React from 'react';
import Badge from '@components/Badge/Badge';
import type { BadgeEntity } from '@features/appointment/badge/AppointmentBadge';

const pastBadgeData: BadgeEntity = {
    badgeContent: 'PAST',
    badgeColor: 'primary',
};

export default function PastBadge() {
    const { badgeContent, badgeColor } = pastBadgeData;
    return (
        <Badge
            size="large"
            content={badgeContent}
            color={badgeColor}
        />
    );
}
