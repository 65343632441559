import { selectorFamily, selector } from 'recoil';
import ApiRequest from '@app/connection/apiRequest';
import UserProfileEntity from '@powerednow/shared/modules/complexData/userProfile/entity';
import { authState } from '@data/state/auth';
import { USER_PROFILE } from '@powerednow/shared/constants';
//
// We don't want to download the permissions from the server all the time so lets
// cache it here once downloaded
//
const userProfileCache: Record<number, UserProfileEntity> = {};

export const userProfile = selectorFamily<UserProfileEntity, number>({
    key: 'userProfile',
    get: userProfileId => async () => {
        if (!userProfileCache[userProfileId]) {
            const profiles: UserProfileEntity[] = await ApiRequest.requestGlobalTable('Userprofile');
            profiles.forEach(profile => { userProfileCache[profile.id] = profile; });
        }
        return userProfileCache[userProfileId];
    },
});

export const hasRole = selectorFamily<boolean, number>({
    key: 'hasRole',
    get: roleId => ({ get }) => {
        const authData = get(authState);
        const { data: { userProfiles } } = authData;
        return (userProfiles || [USER_PROFILE.PORTAL_USER]).reduce((hasThisRole: boolean, profileId) => {
            const profile = get(userProfile(profileId));
            return hasThisRole || (profile?.roles.includes(roleId) || false);
        }, false);
    },
});

export const isPortalUser = selector<boolean>({
    key: 'isPortalUser',
    get: ({ get }) => {
        const authData = get(authState);
        const { data: { userProfiles } } = authData;
        return (userProfiles || []).includes(USER_PROFILE.PORTAL_USER);
    },
});
