import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
    getShortDayNames, getShortDayNamesSundayFirst, getFirstDayOfTheMonth, getLastDayOfTheMonth,
} from '@powerednow/shared/modules/utilities/date';
import Button from '../button/Button';
import { ColorTypes } from '../button/ColoredButton';

export interface CalendarHeaderProps extends BoxProps{
    color?: ColorTypes
    date:Date,
    onNextMonth?:() => void
    onPreviousMonth?:() => void
    firstDayOfTheWeek?: 'monday' | 'sunday'
    firstDay?: Date
    lastDay?: Date
}

export default function CalendarHeader(props:CalendarHeaderProps) {
    const theme = useTheme();
    const {
        onNextMonth,
        onPreviousMonth,
        date, firstDay, lastDay,
        firstDayOfTheWeek,
        color,
    } = props;
    const weekendColor = color ? {
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        neutral: theme.palette.neutral.dark,
        none: 'initial',
    }[color] : 'initial';

    const weekdayColor = color ? {
        primary: theme.palette.primary.contrastText,
        secondary: theme.palette.secondary.contrastText,
        warning: theme.palette.warning.contrastText,
        error: theme.palette.error.contrastText,
        neutral: theme.palette.neutral.dark,
        none: 'initial',
    }[color] : 'initial';

    const dayNames = firstDayOfTheWeek === 'sunday' ? getShortDayNamesSundayFirst() : getShortDayNames();

    const isPrevMonthInRange = () => {
        if (!firstDay) return true;

        const startOfMonth = getFirstDayOfTheMonth(date);
        return startOfMonth >= firstDay;
    };

    const isNextMonthInRange = () => {
        if (!lastDay) return true;

        const endOfTheMonth = getLastDayOfTheMonth(date);
        return endOfTheMonth.getTime() < lastDay.getTime();
    };

    return (
        <Box>
            <Box pb={1} display="flex" height={40} borderBottom="solid 1px #AAA" justifyContent="space-between" alignItems="center">
                <Button disabled={!isPrevMonthInRange()} buttonType={color || 'none'} emphasis="low" size="large" startIcon={<LeftIcon />} onClick={onPreviousMonth} style={{ borderRadius: '20px' }} />
                <Box>
                    {date.getFullYear()}
                    {' '}
                    {date.toLocaleString('MM', { month: 'long' })}
                </Box>
                <Button disabled={!isNextMonthInRange()} buttonType={color || 'none'} emphasis="low" size="large" startIcon={<RightIcon />} onClick={onNextMonth} style={{ borderRadius: '20px' }} />
            </Box>
            <Box display="flex" height={40} borderBottom="solid 1px #AAA">
                {dayNames.map(
                    (day, ind) => (
                        <Box
                            key={`weekday_${day}`}
                            display="flex"
                            flex="1"
                            justifyContent="center"
                            alignItems="center"
                            style={{ color: ind > 4 ? weekendColor : weekdayColor }}
                        >
                            {day}
                        </Box>
                    ),
                )}
            </Box>
        </Box>
    );
}
