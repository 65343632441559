import {
    atom, selector, selectorFamily,
} from 'recoil';
import SETTINGS_KEYS from '@powerednow/shared/constants/settingsKeys';
import { ValueOf } from '@powerednow/type-definitions';
import { authState, dataWrapper } from '@data/state/auth';
import Company from '@powerednow/shared/modules/complexData/company';
import converterUtils from '@powerednow/shared/modules/utilities/converter';

const companySettings = selectorFamily({
    key: 'companySettings',
    get: (settingsKey: ValueOf<typeof SETTINGS_KEYS>) => async ({ get }) => {
        const authData = get(authState);
        const { data: { companyId } } = authData;
        const dataWrapperInstance = get(dataWrapper);
        const complexCompany = await dataWrapperInstance.getComplexDataObject(Company, companyId!);
        return complexCompany.getSettingValue(settingsKey);
    },
});

const displayProjectsOnPortal = atom<boolean>({
    key: 'companySettings/displayProjectsOnPortal',
    default: selector({
        key: 'companySettings/displayProjectsOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_PROJECTS_ON_PORTAL))),
    }),
});

const canSiteContactsAccessFullPortal = atom<boolean>({
    key: 'companySettings/canSiteContactsAccessFullPortal',
    default: selector({
        key: 'companySettings/canSiteContactsAccessFullPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.CAN_SITE_CONTACTS_ACCESS_FULL_PORTAL))),
    }),
});

const displayAssigneePhoneNumberOnPortal = atom<boolean>({
    key: 'companySettings/displayAssigneePhoneNumberOnPortal',
    default: selector({
        key: 'companySettings/displayAssigneePhoneNumberOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_ASSIGNEE_PHONE_NUMBER_ON_PORTAL))),
    }),
});

const displayCompanyPhoneNumberOnPortal = atom<boolean>({
    key: 'companySettings/displayCompanyPhoneNumberOnPortal',
    default: selector({
        key: 'companySettings/displayCompanyPhoneNumberOnPortal/Default',
        get: async ({ get }) => converterUtils.stringToBoolean(get(companySettings(SETTINGS_KEYS.DISPLAY_COMPANY_PHONE_NUMBER_ON_PORTAL))),
    }),
});

export {
    companySettings,
    displayProjectsOnPortal,
    canSiteContactsAccessFullPortal,
    displayAssigneePhoneNumberOnPortal,
    displayCompanyPhoneNumberOnPortal,
};
