import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import useComplexData from '@data/hooks/complexDataHook';
import CompanyEntity from '@powerednow/shared/modules/complexData/company/entity';
import Company from '@powerednow/shared/modules/complexData/company';

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        display: 'flex',
        height: 'fit-content',
        maxWidth: '200px',
        maxHeight: '60px',
        borderRadius: '4px',
        [theme.breakpoints.up('xs')]: {
            width: '80px',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
        },
    },
}));

interface CompanyLogoProps {
    companyId: number,
}

const useCompanyLogo = companyId => {
    const { optionalResult } = useComplexData<CompanyEntity, { logoUrl: string }, Company>(
        Company,
        companyId,
        async (complexCompany, resultSetter) => {
            const complexLogo = await (complexCompany as any).getCompanyLogo();
            resultSetter(complexLogo, { logoUrl: complexLogo?.data?.getPureDataValues().url });
        },
    );
    return optionalResult?.logoUrl;
};

function CompanyLogo(props: CompanyLogoProps) {
    const { companyId } = props;
    const logoUrl = useCompanyLogo(companyId);
    const classes = useStyles();
    if (!logoUrl) {
        return null;
    }

    return (
        <img
            alt="logo"
            className={classes.root}
            src={logoUrl}
        />
    );
}

export default CompanyLogo;
