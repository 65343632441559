import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import htmlDecode from '@components/helper/htmlDecode';
import { Box } from '@material-ui/core';
import Optional from '@components/fields/Optional';

type TextWithIconProps = {
    startIcon: React.ReactElement<typeof SvgIcon>,
    children?: React.ReactNode,
    className?: string,
};

function TextWithIcon(props: TextWithIconProps) {
    const {
        startIcon,
        children,
        className,
    } = props;

    return (
        <Box className={className}>
            {startIcon}
            <span>
                {htmlDecode(children)}
            </span>
        </Box>
    );
}
const OptionalTextWithIcon = Optional(TextWithIcon);

export { TextWithIcon, OptionalTextWithIcon };
