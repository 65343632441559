import { atom } from 'recoil';

export type ToastMessage = {
    message: string,
    severity?: 'warning' | 'error' | 'success' | 'info'
}

export const toast = atom<ToastMessage>({
    key: 'toast',
    default: {
        message: '',
    },
});
