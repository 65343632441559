import React from 'react';
import { Columns } from '@components/dataGrid/DataGrid';
import useComplexData from '@data/hooks/complexDataHook';
import ContactEntity from '@powerednow/shared/modules/complexData/contact/entity';
import Contact from '@powerednow/shared/modules/complexData/contact';
import { ModelFields } from '@powerednow/shared/modules/complexData/entity';
import Bluebird from 'bluebird';
import LastMessageInThread from '@powerednow/shared/modules/complexData/lastMessageInThread';
import xTemplateFunc from '@powerednow/shared/modules/utilities/xTemplate';
import usePrompt from '@data/hooks/promptHook';
import CustomerMessageEntity from '@powerednow/shared/modules/complexData/customerMessage/entity';
import DataGridTabList, { ComplexTabListItems, TabListItemsParams } from '@features/dataGridTabList/DataGridTabList';
import messagesListColumns from './messagesListColumns';

type MessageDataType = Partial<ModelFields<CustomerMessageEntity>>;

const useMessages = (params: TabListItemsParams<CustomerMessageEntity>): ComplexTabListItems<CustomerMessageEntity> => {
    const {
        contactId,
        pageSize,
        skip,
        sorters,
    } = params;
    const L = usePrompt();
    const xTemplate = xTemplateFunc({ LSync: L });
    const { optionalResult, error } = useComplexData<ContactEntity, { listItems: MessageDataType[], total: number, loaded: boolean, error: any }, Contact>(
        Contact,
        [contactId, pageSize, skip, sorters],
        async (complexContact, resultSetter, watch) => {
            try {
                const complexCustomer = await watch(complexContact.getCustomer());
                const total = await complexCustomer.getLastMessageInThreadCount();
                const complexMessages = await Bluebird.map(complexCustomer.getAllLastMessageInThread({
                    skip,
                    limit: pageSize,
                    sorters,
                }), (messageInThreadInstance: LastMessageInThread) => messageInThreadInstance.getCustomerMessage());

                const complexMessagesArray = await Bluebird.map(complexMessages.filter(Boolean), async messageInstance => {
                    const recipients = await messageInstance.getAllMessageRecipient();
                    const channels = recipients.map(recipient => recipient.getChannelType());
                    const displayRecipient = await messageInstance.getDisplayRecipient();
                    const displayMessage = await displayRecipient?.getRenderedContent(xTemplate.renderBySource);
                    const {
                        id, dt_created, type, direction,
                    } = messageInstance.data;
                    return {
                        id,
                        dt_created,
                        type,
                        direction,
                        channels,
                        message: displayMessage?.subject || displayMessage?.message,
                    };
                });

                resultSetter(complexMessages, {
                    total,
                    loaded: true,
                    listItems: complexMessagesArray,
                });
            } catch (err) {
                console.error(err);
                resultSetter(complexContact, {
                    error: err,
                    loaded: false,
                    listItems: [],
                });
            }
        },
    );

    return {
        listItems: optionalResult?.listItems || [],
        loaded: optionalResult?.loaded || false,
        total: optionalResult?.total || 0,
        error,
    };
};

const columns: Columns = messagesListColumns();

export default function MessagesList() {
    const rowHeight = 80;
    const height = 510;
    const sortModel = [
        { field: 'dt_created', sort: 'desc' } as const,
    ];

    return (
        <DataGridTabList
            columns={columns}
            useGetListItems={useMessages}
            sortModel={sortModel}
            rowHeight={rowHeight}
            height={height}
            noRowsText="Sorry, there are no messages to display"
            tabToNavigate="message"
        />
    );
}
