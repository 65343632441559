import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';
import chroma from 'chroma-js';

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0, 0.1)',
    },
    fullScreen: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    noSelect: {
        'user-select': 'none',
        '-moz-user-select': 'none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none',
        '-o-user-select': 'none',
    },
}));

interface MaskProps extends BoxProps {
    backgroundColor?: string
    blur?: number
    opacity?: number
    show?: boolean
    children?: any
    zIndex?: number
    fullScreen?: boolean
    overflow?: number | string
    borderRadius?: number | string
    style?: {},
    content?: any
}

export default function Mask({
    backgroundColor,
    blur,
    opacity,
    show,
    children,
    zIndex,
    fullScreen,
    overflow,
    borderRadius,
    style,
    content,
    className,
    ...boxProps
}: MaskProps) {
    const classes = useStyles();

    const baseClass = fullScreen ? classes.fullScreen : classes.root;
    const overflowValue = fullScreen ? 0 : (overflow || 0);

    const position = {
        left: -overflowValue,
        top: -overflowValue,
        right: -overflowValue,
        bottom: -overflowValue,
    };

    const opacityValue = opacity === undefined ? 0.1 : opacity;
    let bakgroundColorValue = backgroundColor || 'black';
    if (chroma.valid(bakgroundColorValue)) {
        bakgroundColorValue = chroma(bakgroundColorValue || 'black').alpha(opacityValue).hex();
    }

    return (
        <Box
            position="relative"
            {...boxProps}
        >
            {children}
            <Box
                display={show ? 'flex' : 'none'}
                justifyContent="center"
                alignItems="center"
                className={clsx(className, baseClass, classes.blur, classes.noSelect)}
                flexDirection="column"
                style={{
                    ...position,
                    borderRadius: (borderRadius || 0),
                    zIndex: zIndex || 100,
                    backdropFilter: blur && blur >= 0 ? `blur(${blur}px)` : 'none',
                    backgroundColor: bakgroundColorValue,
                    ...style,
                }}
            >
                {content}
            </Box>
        </Box>
    );
}
