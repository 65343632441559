import React, { createContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { TryAgainFallbackModal } from '@components/errorBoundary/TryAgainFallback';

const defaultContext = {
    redirectPath: '/',
};
export const ErrorContext = createContext(defaultContext);
export default function ErrorBoundaryWithTryAgainModal({ children, ...args }) {
    return (
        <ErrorBoundary FallbackComponent={TryAgainFallbackModal} {...args}>
            {children}
        </ErrorBoundary>
    );
}

export function ErrorBoundaryRedirect({ children, ...args }) {
    return (
        <ErrorContext.Provider value={defaultContext}>
            <ErrorBoundaryWithTryAgainModal {...args}>
                {children}
            </ErrorBoundaryWithTryAgainModal>
        </ErrorContext.Provider>

    );
}
