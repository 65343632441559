import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { omit } from 'lodash';
import { Box, BoxProps } from '@material-ui/core';

export interface MatrixProps extends BoxProps {
    columns: number,
    spacing?: number,
    children: React.ReactNode | string,
}

const useStyles = makeStyles<Theme>(() => createStyles({
    gridMatrix: {
        display: 'grid',
    },
}));

export default function ComponentMatrix({
    className, spacing, columns, children, ...props
}: MatrixProps = {
    children: '', columns: 1,
}) {
    const classes = useStyles();
    const flexClass = classes.gridMatrix;

    return (
        <Box
            className={clsx(className, flexClass)}
            style={{
                columnGap: `${spacing}px`,
                rowGap: `${spacing}px`,
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            }}
            {...omit(props, 'className', 'cellClassName', 'cellStyle', 'cellAlign', 'cellJustify')}
        >
            {children}
        </Box>
    );
}
