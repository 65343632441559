import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useSetSelectedTab from '@data/hooks/useSetSelectedTab';
import { TABS } from '@data/state/selectedTab';
import ComposeMessage from './composeMessage';
import ViewMessage from './viewMessage/viewMessage';

export const baseRoute = 'message';
export const composeRoute = 'compose';
export const viewRoute = 'view';

export default function MessageRoutes() {
    const viewPath = `:id/${viewRoute}`;
    useSetSelectedTab(TABS.Message);
    return (
        <Routes>
            <Route path={composeRoute} element={<ComposeMessage />} />
            <Route path={viewPath} element={<ViewMessage />} />
        </Routes>
    );
}
