import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Button from '@components/button/Button';
import ColoredButton from '@components/button/ColoredButton';
import Box from '@material-ui/core/Box';

const errorMessages = {
    NetworkError: 'There was an error while communicating with the server. \nPlease try again.',
};

export default function TryAgainFallback({ error, resetErrorBoundary }) {
    return (
        <Box role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <Button onClick={resetErrorBoundary}>Try again</Button>
        </Box>
    );
}

export function TryAgainFallbackModal({ error, resetErrorBoundary }) {
    return (
        <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Something went wrong</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {errorMessages[error.name] || error.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ColoredButton
                    onClick={resetErrorBoundary}
                    color="primary"
                    autoFocus
                >
                    Close error
                </ColoredButton>
            </DialogActions>
        </Dialog>
    );
}
