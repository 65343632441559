import React, { useEffect } from 'react';
import DataGrid, { Columns } from '@components/dataGrid/DataGrid';

import { useRecoilState, useRecoilValue } from 'recoil';
import { authState } from '@data/state/auth';
import { useNavigate } from 'react-router-dom';
import { pagerIndex } from '@data/state/pagers';
import useSorter from '@data/hooks/sorter';
import { type Sorter } from '@powerednow/shared/modules/complexData/connectedData';
import { type EntityWithCompanyId, type ModelFields } from '@powerednow/shared/modules/complexData/entity';
import { type DataGridProps, type GridSortModel } from '@material-ui/data-grid';

export interface TabListItemsParams<T extends EntityWithCompanyId> {
    contactId: number | null;
    pageSize: number;
    skip: number;
    selectedFilter?: number;
    sorters: Sorter<T>[]
}

export type ComplexTabListItems<T extends EntityWithCompanyId> = {
    listItems: Partial<ModelFields<T>>[],
    error: any,
    loaded: boolean,
    total: number,
};

export type GetListItemsHook<T extends EntityWithCompanyId> = (_params: TabListItemsParams<T>) => ComplexTabListItems<T>

type DataGridTabListProps<T extends EntityWithCompanyId> = {
    columns: Columns,
    rowHeight: DataGridProps['rowHeight'],
    height: number,
    pageSize?: number,
    noRowsText: string,
    sortModel: Sorter<T>[],
    useGetListItems: GetListItemsHook<T>,
    selectedFilter?: number,
    tabToNavigate: string,
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export default function DataGridTabList<T extends EntityWithCompanyId>(props: DataGridTabListProps<T>) {
    const {
        columns,
        rowHeight,
        height,
        noRowsText,
        pageSize,
        sortModel,
        useGetListItems,
        selectedFilter,
        tabToNavigate,
    } = props;
    const [page, setPage] = useRecoilState(pagerIndex({ name: 'listPager', id: 0, default: 0 }));
    const [sorters, setSorters] = useSorter<Sorter<T>[]>(sortModel);
    const authData = useRecoilValue(authState);
    const navigate = useNavigate();
    const { data: { contactId }, portalId } = authData || {};
    const customPageSize = pageSize || 5;
    const skip = customPageSize * page;

    const {
        listItems,
        loaded,
        total,
        error,
    } = useGetListItems({
        contactId,
        pageSize: customPageSize,
        skip,
        sorters,
        selectedFilter,
    });

    const rows = loaded ? listItems : [];
    const isPageLoaded = loaded ? page : 0;

    const handlePageChange = pageData => {
        if (loaded) {
            setPage(pageData.page || pageData);
        }
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        setSorters(newModel as Sorter<T>[]);
    };

    const handleRowClick = ({ row }) => {
        navigate(`/portal/${portalId}/${tabToNavigate}/${row.id}/view`);
        scrollToTop();
    };

    useEffect(() => {
        setPage(0);
    }, [selectedFilter]);

    return (
        <DataGrid
            error={error}
            columns={columns}
            rows={rows}
            rowCount={total}
            disableColumnMenu
            rowCursor="pointer"
            noRowBorder
            noHeaderSeparator
            rowHeight={rowHeight}
            height={height}
            pageSize={customPageSize}
            loading={!loaded}
            paginationMode="server"
            sortingMode="server"
            noRowsText={noRowsText}
            onRowClick={handleRowClick}
            page={isPageLoaded}
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
        />
    );
}
