import {
    formatToLocalDate,
    parse,
    isValid,
    patterns,
} from '@powerednow/shared/modules/utilities/date';
import { ValueOf } from '@powerednow/type-definitions';

// eslint-disable-next-line import/prefer-default-export
export function getFormattedDate(date: unknown, pattern: ValueOf<typeof patterns> = patterns.LongDateOnly) {
    if (!isValid(date, patterns.ISO8601Long)) {
        return '';
    }

    const parsedDate = parse(date, patterns.ISO8601Long);

    return formatToLocalDate(parsedDate, pattern);
}
