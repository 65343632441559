import React from 'react';
import { Badge as MUIBadge, BadgeProps as MUIBadgeProps } from '@material-ui/core';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { omit } from 'lodash';
import { ColorTypes } from '../button/ColoredButton';

const getColor = (theme, name) => ({
    '&> .MuiBadge-badge': {
        backgroundColor: theme.palette[name].main,
        color: (name === 'warning' || name === 'neutral') ? theme.palette[name].contrastText : theme.palette[name].light,
    },

});

const useStyles = makeStyles<Theme>(theme => createStyles({
    root: {
        '&> .MuiBadge-badge': {
            border: 'solid 1px white',
        },
    },
    primary: getColor(theme, 'primary'),
    secondary: getColor(theme, 'secondary'),
    warning: getColor(theme, 'warning'),
    error: getColor(theme, 'error'),
    neutral: getColor(theme, 'neutral'),

    small: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(0.8 * 2.4),
        },
    },
    medium: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(2.4),
        },
    },
    large: {
        '&> .MuiBadge-badge': {
            fontSize: theme.spacing(1.2 * 2.4),
        },
    },
    noChildren: {
        '&> .MuiBadge-badge': {
            position: 'relative',
            top: 'initial',
            right: 'initial',
            transform: 'initial',
            transformOrigin: 'initial',
            paddingTop: '1px',
        },
    },
}));

declare type LimitedMUIBadgeProps = Omit<MUIBadgeProps, 'variant' | 'color'>

export interface BadgeProps extends LimitedMUIBadgeProps {
    color?: ColorTypes;
    size?: 'small' | 'medium' | 'large';
    content?: string | number | React.ReactNode;
}

export default function Badge(props: BadgeProps) {
    const classes = useStyles();
    let positionClasses: null | string = null;
    const {
        children, className, content, color, size,
    } = props;
    const colorClasses: null | string = clsx(classes?.[color || 'primary']);
    const sizeClasses: null | string = clsx(classes?.[size || 'medium']);
    if (!children) {
        positionClasses = classes.noChildren;
    }

    return (
        <MUIBadge
            className={clsx(className, colorClasses, sizeClasses, classes.root, positionClasses)}
            badgeContent={content}
            {...omit(props, 'className', 'size', 'content', 'badgeContent', 'color')}
        />
    );
}
