import React from 'react';
import { ColorTypes } from '@components/button/ColoredButton';
import UpcomingBadge from '@features/appointment/badge/UpcomingBadge';
import InProgressBadge from '@features/appointment/badge/InProgressBadge';
import PastBadge from '@features/appointment/badge/PastBadge';

type AppointmentBadgeProps = {
    /** The start date compared with the current date */
    startDate: Date,
    /** The end date compared with the current date */
    endDate: Date,
}
type BadgeContent = 'UPCOMING' | 'IN PROGRESS' | 'PAST';

export type BadgeEntity = {
    badgeContent: BadgeContent,
    badgeColor: ColorTypes,
}

export default function AppointmentBadge(props: AppointmentBadgeProps) {
    const { startDate, endDate } = props;
    const currentDate = new Date();

    if ((startDate > currentDate)) {
        return <UpcomingBadge />;
    }
    if (endDate > currentDate && currentDate > startDate) {
        return <InProgressBadge />;
    }
    return <PastBadge />;
}
