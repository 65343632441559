import React from 'react';
import PageBlock from '@components/layout/PageBlock';
import { useResponsive } from '@data/hooks/responsive';

export default function DocumentContainer({
    children,
}) {
    const responsive = useResponsive();
    return (
        <PageBlock flexDirection={responsive({ xs: 'column', md: 'row' })}>
            {children}
        </PageBlock>
    );
}
