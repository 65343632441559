import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import TextField from '@components/fields/TextField';
import Button from '@components/button/Button';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ErrorBoundaryWithTryAgainModal from '@components/errorBoundary/errorBoundary';
import { hasRole } from '@data/state/permission';
import { ROLES } from '@powerednow/shared/constants';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles<Theme>(createStyles({
    newPasswordTextField: {
        paddingBottom: '10px',
    },
    setNewPasswordContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    },
}));

const shortScoreWordHint = 'Try improving your password strength by using upper and lowercase letters combined by numbers and special characters';
const scoreWords = [
    shortScoreWordHint,
    shortScoreWordHint,
    'okay',
    'good',
    'strong',
];

type SetNewPasswordProps = {
    onSetNewPasswordButtonClicked: () => void,
    onPasswordFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    password: string,
    confirmPassword: string,
    showCompanyLogo?: boolean,
    savePasswordButtonText?: string,
};

export default function SetNewPassword(props: SetNewPasswordProps) {
    const {
        onSetNewPasswordButtonClicked,
        onPasswordFieldChange,
        password,
        confirmPassword,
        savePasswordButtonText = 'Set new password',
    } = props;
    const classes = useStyles();
    const [enableSave, setEnableSave] = useState(false);
    const hasAccountRole = useRecoilValue(hasRole(ROLES.PERMISSIONS.CAN_MANAGE_ACCOUNT_ON_PORTAL));

    const areGivenPasswordsMatch = password === confirmPassword;
    const helperText = !areGivenPasswordsMatch ? 'Passwords do not match' : '';

    const [passwordScore, setPasswordScore] = useState(0);
    const isPasswordAcceptable = passwordScore > 1;

    useEffect(() => {
        setEnableSave(password === confirmPassword && isPasswordAcceptable);
    }, [password, confirmPassword, passwordScore]);

    const handleChangeScore = score => setPasswordScore(score);

    return (
        <Box>
            <TextField
                className={classes.newPasswordTextField}
                placeholder="give your new password"
                name="password"
                size="medium"
                type="password"
                value={password}
                fullWidth
                onChange={onPasswordFieldChange}
            />
            <TextField
                placeholder="repeat your new password"
                helperText={helperText}
                error={!areGivenPasswordsMatch}
                name="confirmPassword"
                size="medium"
                type="password"
                value={confirmPassword}
                fullWidth
                onChange={onPasswordFieldChange}
            />
            <Box mt={4}>
                <PasswordStrengthBar
                    password={password}
                    onChangeScore={handleChangeScore}
                    scoreWords={scoreWords}
                    shortScoreWord={shortScoreWordHint}
                    minLength={7}
                    scoreWordStyle={{ textAlign: 'left' }}
                />
            </Box>
            <Box className={classes.setNewPasswordContainer} mt={8}>
                <ErrorBoundaryWithTryAgainModal>
                    <Button
                        emphasis="high"
                        buttonType="primary"
                        minWidth={80}
                        onClick={onSetNewPasswordButtonClicked}
                        disabled={!enableSave || !hasAccountRole}
                    >
                        {savePasswordButtonText}
                    </Button>
                </ErrorBoundaryWithTryAgainModal>
            </Box>
        </Box>
    );
}
