import React from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import useSetSelectedTab from '@data/hooks/useSetSelectedTab';
import { TABS } from '@data/state/selectedTab';
import ViewPayment from './viewPayment/viewPayment';

export const baseRoute = 'payment';

export default function PaymentRoutes() {
    useSetSelectedTab(TABS.Payment);
    return (
        <Routes>
            <Route path=":id/view" element={<ViewPayment />} />
        </Routes>
    );
}
