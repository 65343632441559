import { format } from '@powerednow/shared/modules/utilities/date';
import ApiRequest from './apiRequest';

const DownloadICS = async (authData, actionId) => {
    const uid = `id${(new Date()).getTime()}`;
    
    const apiRequest = new ApiRequest(authData.data);
    const fileName = `PoweredNow-Calendar-${Date.now()}`;

    const headers = {
        'x-data-collect-function-name': 'getDataForICSExport',
        'x-return-value-content-disposition': `attachment; filename="${fileName}.ics"`,
        'x-response-formatter': 'ICS',
        'x-return-value-content-type': 'text/calendar',
        'x-request-tmp-url': 'YES',
        'X-Request-UID': uid,
    };

    try {
        const response = await apiRequest.directApi('Action', actionId, headers);

        if (response.url) {
            window.open(response.url, '_');
        }
    } catch (error) {
        throw new Error(`Error downloading ICS file: ${error}`);
    }
};

export default DownloadICS;
