import React from 'react';
import ActionButton from './ActionButton';
import ComponentMask from '../mask/ComponentMask';
import { ColorTypes } from './ColoredButton';

export type SendMessageButtonProps = {
    onClick: React.MouseEventHandler,
    color?: ColorTypes,
    loaded: boolean,
    enabled?: boolean,
}

export default function SendMessageButton({
    onClick,
    color,
    loaded,
    enabled,
}: SendMessageButtonProps) {
    return (
        <ComponentMask
            showMask={!loaded}
            color={color || 'secondary'}
            fullWidth
        >
            <ActionButton
                fullWidth
                onClick={onClick}
                color={color}
                enabled={enabled}
            >
                Send a message
            </ActionButton>
        </ComponentMask>
    );
}
