import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress as MUILinearProgress, CircularProgress as MUICircularProgress, Box } from '@material-ui/core';
import { ColorTypes } from '@components/button/ColoredButton';
import clsx from 'clsx';

export type ProgressVariants = 'circular' | 'linear';

interface ProgressProps {
    type?: ProgressVariants
    size?: 'small' | 'medium' | 'large',
    color?: ColorTypes,
    value?: number,
    variant?: 'determinate',
    className?: string,
    style?: {},
}

const useCircularStyles = makeStyles<Theme>(theme => createStyles({
    primary: { color: theme.palette.primary.main },
    secondary: { color: theme.palette.secondary.main },
    warning: { color: theme.palette.warning.main },
    error: { color: theme.palette.error.main },
    neutral: { color: theme.palette.neutral.dark },
}));

const useLinearStyles = makeStyles<Theme>(theme => createStyles({
    small: { height: 2 },
    medium: { height: 4 },
    large: { height: 7 },

    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.primary.main },
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.secondary.main },
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.warning.main },
    },
    error: {
        backgroundColor: theme.palette.error.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.error.main },
    },
    neutral: {
        backgroundColor: theme.palette.neutral.light,
        '& .MuiLinearProgress-bar': { backgroundColor: theme.palette.neutral.dark },
    },
}));

function LinearProgress({ size, color, ...props }: ProgressProps) {
    const linearClasses = useLinearStyles();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <MUILinearProgress
                className={clsx(
                    'MuiLinearProgress-bar',
                    linearClasses?.[color || 'neutral'],
                    linearClasses?.[size || 'medium'],
                )}
                {...props}
            />
        </Box>
    );
}

function CircularProgress({ size, color, ...props }: ProgressProps) {
    const classes = useCircularStyles();
    const colorClass = classes?.[color || 'neutral'];
    const sizeInPixels = {
        small: 25,
        medium: 30,
        large: 45,
    }[size || 'medium'];

    const thickness = {
        small: 6,
        medium: 5,
        large: 5,
    }[size || 'medium'];

    return (
        <Box display="inline-flex" justifyContent="center" alignItems="center" p={2}>
            <MUICircularProgress
                thickness={thickness}
                className={clsx(colorClass)}
                size={sizeInPixels}
                {...props}
            />
        </Box>
    );
}

export default function Progress({ type, ...props }: ProgressProps) {
    if (type === 'linear') {
        return <LinearProgress {...props} />;
    }

    return <CircularProgress {...props} />;
}

export { CircularProgress, LinearProgress };
