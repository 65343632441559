import React from 'react';
import { Columns } from '@components/dataGrid/DataGrid';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    getNowDate,
    isOnSameDay,
    format,
    patterns,
    convertToLocal,
    parse,
} from '@powerednow/shared/modules/utilities/date';
import { ChannelTypes } from '@powerednow/shared/constants/customerEmailTemplateValues';
import ChannelIndicator from '@components/channelIndicator/ChannelIndicator';
import htmlDecode from '@components/helper/htmlDecode';

const useStyles = makeStyles<Theme>(() => createStyles({
    arrowIconContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: '5px',
    },
    spanFont: {
        fontSize: 'x-small',
    },
    messageCellRoot: {
        fontSize: '14px',
    },
}));

const dtSentCell = rowParams => {
    const today = getNowDate();
    let dateCreated = rowParams.row.dt_created;
    const datePattern = isOnSameDay(today, dateCreated) ? patterns.messageDateTimeShortDate : patterns.shortDateOnly;

    if (datePattern === patterns.messageDateTimeShortDate) {
        const parsedDate = parse(dateCreated, patterns.messageDateTime);
        dateCreated = convertToLocal(parsedDate);
    }
    return (
        <p>
            {format(dateCreated, datePattern)}
        </p>
    );
};

const messageCell = rowParams => {
    const classes = useStyles();

    // removing unnecessary html tags and footer lines as it was affecting the grid formatting
    const formattedMessage = htmlDecode((rowParams.row.message || '').replace(/( )+/g, ' '));

    return (
        <Typography className={classes.messageCellRoot} noWrap>
            {formattedMessage}
            <Box>
                <ChannelIndicator channels={rowParams.row.channels as ChannelTypes[]} />
            </Box>
        </Typography>
    );
};

export default function MessagesListColumns(): Columns {
    return ([
        {
            field: 'dt_created',
            headerName: 'Date',
            minWidth: 185,
            renderCell: dtSentCell,
        },
        {
            field: 'message',
            headerName: 'Message',
            minWidth: 260,
            renderCell: messageCell,
            sortable: false,
        },
    ]);
}
