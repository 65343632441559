import ApiError from '@app/error/types/ApiError';

class ModelNotFoundError extends ApiError {
    public model;

    public id;

    constructor({ model, id }) {
        super();
        this.model = model;
        this.id = id;
    }
}

export default ModelNotFoundError;
