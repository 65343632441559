import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { camelCase } from 'lodash';
import { useErrorHandler } from 'react-error-boundary';

import clsx from 'clsx';

export type ColorTypes =
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'error'
    | 'default'
    | 'inherit'
    | 'neutral'
    | 'none';

export type ColoredButtonProps = { color: ColorTypes } & Omit<ButtonProps, 'color'>;

export const getContainedColors = (theme: Theme, color: ColorTypes) => ({
    backgroundColor: theme.palette[color].main,
    color: (color === 'warning' || color === 'neutral') ? theme.palette[color].contrastText : theme.palette[color].light,
    '&:hover': {
        backgroundColor: theme.palette[color].light,
        color: (color === 'warning' || color === 'neutral') ? theme.palette[color].contrastText : theme.palette[color].main,
    },
});

export const getTextColors = (theme: Theme, color: ColorTypes) => ({
    color: (color === 'warning' || color === 'neutral') ? theme.palette[color].contrastText : theme.palette[color].main,
    boxShadow: 'none',
});

export const getOutlinedColors = (theme: Theme, color: ColorTypes) => ({
    borderColor: theme.palette[color].main,
    color: (color === 'warning' || color === 'neutral') ? theme.palette[color].contrastText : theme.palette[color].main,
    border: `solid 1px ${theme.palette[color].main}`,
    '&:hover': {
        backgroundColor: theme.palette[color].light,
        color: theme.palette[color].main,
    },
});

export const getThemeColors = (theme: Theme, variant: 'outlined' | 'contained' | 'text', color: ColorTypes) => {
    if (variant === 'contained') {
        return getContainedColors(theme, color);
    }

    if (variant === 'text') {
        return getTextColors(theme, color);
    }

    return getOutlinedColors(theme, color);
};

const useStyles = makeStyles<Theme>(theme => createStyles({
    base: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    outlinedPrimary: getThemeColors(theme, 'outlined', 'primary'),
    outlinedSecondary: getThemeColors(theme, 'outlined', 'secondary'),
    outlinedWarning: getThemeColors(theme, 'outlined', 'warning'),
    outlinedError: getThemeColors(theme, 'outlined', 'error'),
    outlinedNeutral: getThemeColors(theme, 'outlined', 'neutral'),

    containedPrimary: getThemeColors(theme, 'contained', 'primary'),
    containedSecondary: getThemeColors(theme, 'contained', 'secondary'),
    containedWarning: getThemeColors(theme, 'contained', 'warning'),
    containedError: getThemeColors(theme, 'contained', 'error'),
    containedNeutral: getThemeColors(theme, 'contained', 'neutral'),

    textPrimary: getThemeColors(theme, 'text', 'primary'),
    textSecondary: getThemeColors(theme, 'text', 'secondary'),
    textWarning: getThemeColors(theme, 'text', 'warning'),
    textError: getThemeColors(theme, 'text', 'error'),
    textNeutral: getThemeColors(theme, 'text', 'neutral'),

}));

function ColoredButton({
    children, color, onClick, ...props 
}: ColoredButtonProps) {
    const handleError = useErrorHandler();
    const classes = useStyles();
    const variantColorClass = classes?.[camelCase([props.variant, color].join('_'))];
    const sizeClass = classes?.[props.size || 'medium'];
    const className = clsx([classes.base, variantColorClass, sizeClass, props.className]);

    const colorProp = ['default', 'inherit', 'primary', 'secondary'].indexOf(color) > -1
        ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
        : undefined;

    const handledOnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
            if (onClick) {
                await onClick(event);
            }
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <Button {...props} color={colorProp} className={className} onClick={handledOnClick}>
            {children}
        </Button>
    );
}

ColoredButton.displayName = 'ColoredButton';

export default ColoredButton;
