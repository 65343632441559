import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ErrorPage from '@pages/error/ErrorPage';
import AuthError from './auth/authError';

export const baseRoute = '/error';

export type ErrorStateType = {
    error: string
    portalId: string
    errorCode?: number
}

export default function ErrorRoutes() {
    const location = useLocation();
    const { state } = location;
    return (
        <Routes>
            <Route path="*" element={<ErrorPage errorCode={404}>Something went wrong</ErrorPage>} />
            <Route path="auth" element={<AuthError errorCode={401} error={(state as ErrorStateType)?.error} portalId={(state as ErrorStateType)?.portalId} />} />
        </Routes>
    );
}
