import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import LabelledBox from '@components/LabelledBox/LabelledBox';
import { DocumentSummaryData } from '@powerednow/shared/modules/complexData/document';
import { List, ListItem } from '@components/List/List';
import { Box } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import htmlDecode from '@components/helper/htmlDecode';

const useStyles = makeStyles<Theme>(createStyles({
    documentDetails: {
        display: 'block',
        fontWeight: 'bold',
        '& .detailsBox': {
            maxHeight: '45vh',
            overflowX: 'auto',
            '&> .groupHeader': {
                marginBlock: 'unset',
            },
            '& .listItem p': {
                margin: 'unset',
                overflowWrap: 'break-word',
                hyphens: 'auto',
            },
        },

    },
}));

export default function DocumentSummaryBlock(documentSummaryData: DocumentSummaryData) {
    const {
        groupTitles, itemDescriptions,
    } = documentSummaryData;
    const classes = useStyles();

    if (!groupTitles) {
        return null;
    }

    return (
        <LabelledBox
            className={classes.documentDetails}
            title="Details: "
            component="div"
            labelPosition="left"
            mt={1}
        >
            {groupTitles.map((title, index) => (
                <Box className="detailsBox">
                    <h1 className="groupHeader" hidden={title === ''}>{ReactHtmlParser(title)}</h1>
                    <List dense>
                        {itemDescriptions?.at(index)?.map((description, itemIndex) => {
                            const itemKey = `group-${index}-item-${itemIndex}`;
                            return (<ListItem key={itemKey} className="listItem" startIcon={<ChevronRight fontSize="small" />}>{ReactHtmlParser(description)}</ListItem>);
                        })}
                    </List>
                    { index + 1 !== groupTitles.length ? <hr /> : ''}
                </Box>
            ))}
        </LabelledBox>

    );
}
